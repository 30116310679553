<script setup>
    const emit = defineEmits(["sorted"]);

    const props = defineProps({
        sortKey: {
            type: String,
            default: ""
        },
        sortable: {
            default: true
        },
        pageInfo: {
            default: () => {
                return { sortKey: "" }
            }
        }
    });

    function doSort() {
        if (props.sortable !== true) return;
        let sortOrder = props.pageInfo.sortOrder == "desc" ? "asc" : "desc";
        emit("sorted", { "sortKey": props.sortKey, "sortOrder": sortOrder });
    };
</script>

<template>    
    <div>
        <div class="columns is-gapless is-marginless cursor-pointer" @click="doSort" v-bind:class="{'sortable-header': sortable === true }">
            <div class="column">
                <slot></slot>
            </div>
            <div class="column is-narrow sort-icon" v-if="sortable === true">
                <div class="has-text-grey-darker" v-if="pageInfo.sortKey === sortKey">
                    <span v-if="pageInfo.sortOrder === 'desc'" class="material-icons md-18" title="descending">arrow_downward</span>
                    <span v-else class="material-icons md-18" title="ascending">arrow_upward</span>
                </div>
                <div class="has-text-grey-lighter" v-else>
                    <span class="material-icons md-18" title="click to sort">unfold_more</span>
                </div>
            </div>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

