<script setup>
    import { ref, computed, onMounted } from 'vue';
    import axios from 'axios';
    import moment from 'moment';
    import utils from '@/utils.js';
    import { useToast } from 'vue-toastification';
    import constants from '@/constants.js';

    const props = defineProps(["user"]);

    const defaultPaging = {
        sortKey: "SerialNumber",
        sortOrder: "asc",
        search: "",
        pageNumber: 1
    };

    const rows = ref([]);
    const facilities = ref([]);
    const shipperModels = ref([]);
    const states = ref([]);
    const pageInfo = ref(defaultPaging);
    const loading = ref(false);
    const processModal = ref(null);
    const confirmModal = ref(null);

    async function loadFilters() {        
        await Promise.all([
            axios.get('/api/ShipperModel/List').then(x => shipperModels.value = x.data),
            axios.get('/api/Facility/List').then(x => facilities.value = x.data),
            axios.get('/api/Shipper/ListStates').then(x => states.value = x.data)
        ]);
    }

    function reset() {
        pageInfo.value = Object.assign({}, defaultPaging);
        search();
    }

    async function search(parms) {        
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);
        loading.value = true;
        try {
            const response = await axios.post("/api/Shipper/List", pageInfo.value);        
            if (response.status !== 200) return;
            rows.value = response.data.rows;
            pageInfo.value = response.data.pageInfo;
        }
        finally {
            loading.value = false;
        }
    }

    const process = () => processModal.value.open();
            
    async function sentryExport() {
        const response = await axios.post("/api/Shipper/SentryExport", pageInfo.value, {responseType: 'blob'});
        if (response.status !== 200) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `SentryUpload_${moment().format('MMDDYYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }

    async function deleteShipper(row) {        
        await confirmModal.value.open("Delete Shipper?", `Are you sure you want to delete the shipper with the serial #${row.serialNumber}?<br/></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/Shipper/Delete/${row.id}`);

        if (response.status !== 200) {
            useToast().error("Unable to delete, perhaps the record is in use?");
            return;
        }

        let index = rows.value.indexOf(row);
        rows.value.splice(index, 1);
        useToast().success("The record was deleted successfully.");            
    }

    const isAdmin = computed(() => props.user && props.user.securityRole == constants.SecurityRole.Admin);
    const isMetric = computed(() => props.user.unitSystem === constants.UnitSystem.Metric);

    onMounted(() => {
        loadFilters();
        reset();
        utils.doubleScroll(document.getElementsByClassName('table-container')[0], .82);
    });
</script>

<template>
    <div>
        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons mr-1">propane_tank</i>Shippers</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <button class="button mr-5" v-on:click="reset" title="Resets all filters." >
                        <i class="material-icons mr-1">backspace</i> Reset Filters
                    </button>

                    <router-link class="button is-link" :to="{ name : 'ProcessShipper'}" title="Advances the user through the steps to complete shipper registration and quality inspection.">
                        <span class="material-icons mr-1">auto_fix_high</span> Process
                    </router-link>
                    <router-link class="button is-link" :to="{ name : 'PackingLists'}" title="Assigns shippers to packing lists for sending shippers to end-users after quality inspection and vacuum maintenance are completed.">
                        <span class="material-icons mr-1">pallet</span> Pack
                    </router-link>
                    <button class="button is-info" @click="sentryExport" title="Exports current Dashboard view into an appropriately formatted Excel spreadsheet for mass upload into the Sentry tracking system.">
                        <span class="material-icons mr-1">upload</span> SENTRY Export
                    </button>
                </div>
            </div>
        </div>
    
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div v-show="loading">
            <div class="heading-md">Loading data, please wait...</div>
            <progress class="progress is-large is-info" max="100">60%</progress>
        </div>
        <div v-show="!loading">            
            <div class="table-container" id="table-container">
                <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                    <thead class="has-background-white-ter">
                        <tr class="has-background-info-light">
                            <th rowspan="2"></th>
                            <th rowspan="2">
                                <GridHeader :pageInfo="pageInfo" sort-key="ShipperModelName" v-on:sorted="search">Model</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.shipperModelId" @change="search({pageNumber:1})">
                                        <option :value="null"></option>
                                        <option v-for="option in shipperModels" v-bind:key="option.id" v-bind:value="option.id">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th rowspan="2">
                                <GridHeader :pageInfo="pageInfo" sort-key="State" v-on:sorted="search">Status</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.state" @change="search({pageNumber:1})">
                                        <option v-for="option in states" v-bind:key="option.value" v-bind:value="option.value">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th rowspan="2">
                                <GridHeader :pageInfo="pageInfo" sort-key="SentryNumber" v-on:sorted="search">Sentry Number</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.sentryNumber"
                                           v-on:keyup.enter="search({pageNumber:1})"
                                           v-on:keyup.delete="search({sentryNumber : null,pageNumber:1})"
                                           title="Search by SENTRY Barcode Number">
                                </div>
                            </th>
                            <th rowspan="2">
                                <GridHeader :pageInfo="pageInfo" sort-key="SerialNumber" v-on:sorted="search">Serial Number</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.serialNumber"
                                           v-on:keyup.enter="search({pageNumber:1})"
                                           v-on:keyup.delete="search({serialNumber : null,pageNumber:1})"
                                           title="Search by Serial Number">
                                </div>
                            </th>
                            <th colspan="5">Initial Sublimation Rate Measurement</th>
                            <th rowspan="2">Original Sublimation Rate Deterioration ({{(isMetric ? "kg" : "lb")}}/day/day)</th>
                            <th rowspan="2"><GridHeader :pageInfo="pageInfo" sort-key="MostRecentVacuumMaintenance" v-on:sorted="search">Most Recent Vacuum Maintenance Date</GridHeader></th>
                            <th colspan="5">Most Recent Sublimation Rate Measurement</th>
                            <th colspan="4">Manufacturing Details</th>
                            <th colspan="3">Packing List Details</th>
                            <th rowspan="2">
                                <GridHeader :pageInfo="pageInfo" sort-key="IsInSentry" v-on:sorted="search">Uploaded to SENTRY?</GridHeader>     
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.isInSentry" @change="search()">
                                        <option :value="null"></option>
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="InitialFirstWeightTaken" v-on:sorted="search">First Weight Time</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="InitialFirstWeightRawValue" v-on:sorted="search">First Weight ({{(isMetric ? "kg" : "lb")}})</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="InitialSecondWeightTaken" v-on:sorted="search">Second Weight Time</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="InitialSecondWeightRawValue" v-on:sorted="search">Second Weight ({{(isMetric ? "kg" : "lb")}})</GridHeader></th>
                            <th>Sublimation Rate ({{(isMetric ? "kg" : "lb")}}/day)</th>

                            <th><GridHeader :pageInfo="pageInfo" sort-key="MostRecentFirstWeightTaken" v-on:sorted="search">First Weight Time</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="MostRecentFirstWeightRawValue" v-on:sorted="search">First Weight ({{(isMetric ? "kg" : "lb")}})</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="MostRecentSecondWeightTaken" v-on:sorted="search">Second Weight Time</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="MostRecentSecondWeightRawValue" v-on:sorted="search">Second Weight ({{(isMetric ? "kg" : "lb")}})</GridHeader></th>
                            <th>Sublimation Rate ({{(isMetric ? "kg" : "lb")}}/day)</th>

                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="JdeOrderNumber" v-on:sorted="search">Order #</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.jdeOrderNumber"
                                           v-on:keyup.enter="search()"
                                           v-on:keyup.delete="pageInfo.jdeOrderNumber = null; search()"
                                           title="Search by Order Number">
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="ShippedDate" v-on:sorted="search">Shipped from Manufacturer Date</GridHeader></th>
                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="ReceivingFacilityName" v-on:sorted="search">Receiving Facility</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.receivingFacilityId" @change="search()">
                                        <option :value="null"></option>
                                        <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="ReceivedDate" v-on:sorted="search">Recieved by Facility Date</GridHeader></th>
                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="PackingListFacilityName" v-on:sorted="search">Destination</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.packingListFacilityId" @change="search()">
                                        <option :value="null"></option>
                                        <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="PackingListDate" v-on:sorted="search">Packing Date</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="PackingListId" v-on:sorted="search">Packing List</GridHeader></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in rows" v-bind:key="item.id">
                            <td class="nowrap">
                                <router-link class="has-text-black" :to="{ name : 'EditShipper', params: { id: item.id}}" title="Edit Shipper">
                                    <span class="material-icons md-18">edit</span>
                                </router-link>
                                <a class="ml-2 has-text-black" @click="deleteShipper(item)" title="Delete shipper...">
                                    <span class="material-icons md-18">delete</span>
                                </a>
                            </td>
                            <td class="nowrap">                                
                                <span v-if="item.shipperModelId != null">
                                    <router-link :to="{ name : 'EditShipperModel', params: { id: item.shipperModelId}}" target="_blank">
                                        {{item.shipperModelName}}
                                    </router-link>
                                </span>
                            </td>
                            <td>
                                <span v-html="utils.formatShipperState(item.state)"></span>                                
                            </td>
                            <td>{{item.sentryNumber}}</td>
                            <td>{{item.serialNumber}}</td>

                            <td class="nowrap"><span v-if="item.initialFirstWeightTaken != null">{{utils.formatDateTime(item.initialFirstWeightTaken)}}</span></td>
                            <td align="right"><span v-if="item.initialFirstWeight != null">{{utils.formatNumber(item.initialFirstWeight,2)}}</span></td>
                            <td class="nowrap"><span v-if="item.initialSecondWeightTaken != null">{{utils.formatDateTime(item.initialSecondWeightTaken)}}</span></td>
                            <td align="right"><span v-if="item.initialSecondWeight != null">{{utils.formatNumber(item.initialSecondWeight,2)}}</span></td>
                            <td align="right"><span v-if="item.initialSublimationRate != null">{{utils.formatNumber(item.initialSublimationRate,3)}}</span></td>
                            <td align="right"><span v-if="item.sublimationRateDeteroriation != null">{{utils.formatNumber(item.sublimationRateDeteroriation,5)}}</span></td>
                            <td><span v-if="item.mostRecentVacuumMaintenance != null">{{utils.formatDate(item.mostRecentVacuumMaintenance)}}</span></td>

                            <td class="nowrap"><span v-if="item.mostRecentFirstWeightTaken != null">{{utils.formatDateTime(item.mostRecentFirstWeightTaken)}}</span></td>
                            <td><span v-if="item.mostRecentFirstWeight != null">{{utils.formatNumber(item.mostRecentFirstWeight,2)}}</span></td>
                            <td class="nowrap"><span v-if="item.mostRecentSecondWeightTaken != null">{{utils.formatDateTime(item.mostRecentSecondWeightTaken)}}</span></td>
                            <td align="right"><span v-if="item.mostRecentSecondWeight != null">{{utils.formatNumber(item.mostRecentSecondWeight, 2)}}</span></td>
                            <td align="right"><span v-if="item.mostRecentSublimationRate != null">{{utils.formatNumber(item.mostRecentSublimationRate,3)}}</span></td>

                            <td class="nowrap">{{item.jdeOrderNumber}}</td>
                            <td><span v-if="item.shippedDate != null">{{utils.formatDate(item.shippedDate)}}</span></td>
                            <td class="nowrap">
                                <span v-if="item.receivingFacilityId != null">
                                    <router-link :to="{ name : 'EditFacility', params: { id: item.receivingFacilityId}}" target="_blank">
                                        {{item.receivingFacilityName}}
                                    </router-link>
                                </span>
                            </td>
                            <td><span v-if="item.receivedDate != null">{{utils.formatDate(item.receivedDate)}}</span></td>
                            <td>
                                <span v-if="item.packingListFacilityId != null">
                                    <router-link :to="{ name : 'EditFacility', params: { id: item.packingListFacilityId}}" target="_blank">
                                        {{item.packingListFacilityName}}
                                    </router-link>
                                </span>
                            </td>
                            <td class="nowrap"><span v-if="item.packingListDate != null">{{utils.formatDate(item.packingListDate)}}</span></td>
                            <td>
                                <span v-if="item.packingListId != null">
                                    <router-link :to="{ name : 'EditPackingList', params: { id: item.packingListId}}" target="_blank">
                                        {{item.packingListId}}
                                    </router-link>
                                </span>                                                                
                            </td>
                            <td align="center">
                                <span v-if="item.isInSentry == true">
                                    <span class="material-icons md-18 has-text-success-dark">thumb_up</span>
                                </span>
                                <span v-else-if="item.isInSentry == false">
                                    <span class="material-icons md-18 has-text-danger-dark">thumb_down</span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
        </div>
    </div>
</template>