<script setup>
    import { ref } from 'vue';
    import * as yup from 'yup';
    import { useForm } from 'vee-validate';
    import TextPrompt from '@/components/TextPrompt.vue'
    import axios from 'axios';

    const defaultValues = { id: 0, name: "", email: "", description: "" };
    const root = ref(null);
    const model = ref(defaultValues);
    const emails = ref([]);
    const submitted = ref(false);
    const textPrompt = ref(null);
    let onSave, onError = null;
    
    const { meta, errors, handleSubmit } = useForm({
        validationSchema: yup.object({
            name: yup.string().nullable().required(),
            description: yup.string().nullable()
        }),
    });

    const onSubmit = handleSubmit( async () => {
        try {
            model.value.email = emails.value.join(",");
            const response = await axios.post("/api/CustomerServiceCenter/Save", model.value);
            if (response.status == 200)
                onSave(response.data);
            else
                onError(response);
        }
        finally {
            toggleDisplay(false)
        }
    });

    async function addEmail() {
        var value = await textPrompt.value.open("");
        emails.value.push(value);
    }

    function removeEmail(value) {
        var index = emails.value.indexOf(value);
        if (index < 0) return;
        emails.value.splice(index, 1);
    }

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";

    function open(item) {                
        model.value = Object.assign({}, item ?? defaultValues);
        emails.value = model.value.email.length == 0 ? [] : model.value.email.split(",").map(x => x.trim())

        submitted.value = false;

        return new Promise((resolve, reject) => {            
            //assign callbacks
            onSave = (result) => resolve(Object.assign(item ?? {}, result));
            onError = (error) => reject(error);            
            toggleDisplay(true);
        });
    };

    defineExpose({
        open
    });
</script>

<template>
    <div ref="root" class="modal">
        <TextPrompt ref="textPrompt" title="Enter email address" caption="Email"></TextPrompt>
        <form @submit.prevent="onSubmit">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{model.id ? "Edit" : "Add"}} Customer Service Center</p>
                    <button type="button" class="delete" aria-label="close" @click="toggleDisplay(false)"></button>
                </header>
                <section class="modal-card-body">
                    <div class="notification is-danger is-light" v-if="!meta.valid && submitted == true">
                        <div v-for="error in errors">
                            &bull; {{error}}
                        </div>
                    </div>

                    <div class="control">
                        <label class="label">Name *</label>
                        <Field class="input" type="text" v-model="model.name" name="name" />
                    </div>

                    <div class="control">                                               
                        <div class="label columns is-gapless mt-2 mb-2">
                            <div class="column">
                                Email(s)
                            </div>
                            <div class="column is-narrow">
                                <button type="button" class="button is-small" @click="addEmail"><i class="material-icons">add</i></button>
                            </div>
                        </div>                        
                        <div class="block is-bordered p-1 mb-2" style="box-shadow: inset 0 .0625em .125em #0a0a0a0d; border: solid 1px #dbdbdb">
                            <div v-for="email in emails">                                
                                <span @click="removeEmail(email)" class="cursor-pointer">
                                    <i class="material-icons md-18 has-text-danger">clear</i>
                                </span> 
                                <a class="ml-2" v-bind:href="`mailto:email`">{{email}}</a>
                            </div>
                        </div>
                    </div>

                    <div class="control">
                        <label class="label">Description</label>
                        <Field class="textarea" as="textarea" v-model="model.description" name="description" />
                    </div>
                </section>
                <footer class="modal-card-foot" style="justify-content:flex-end">
                    <div class="buttons">
                        <button type="submit" class="button is-success" @click="submitted = true"><i class="material-icons mr-1">save</i>Save</button>
                        <button type="button" class="button is-danger" @click="toggleDisplay(false)"><i class="material-icons mr-1">cancel</i>Cancel</button>
                    </div>
                </footer>
            </div>
        </form>
    </div>
</template>