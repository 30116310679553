<script setup>
    import { ref, computed} from 'vue';    
    import axios from 'axios';
    import { useToast } from 'vue-toastification';

    const root = ref(null);
    const model = ref(null);
    const state = ref({ busy: false, text: "" })

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";

    const formatAddress = (value) => {        
        var tokens = [value.street1, value.street2, value.street3, value.city, value.state, value.postal_code]
            .filter(x => x != null && x.trim().length > 0);        

        return tokens.join(", ");
    };

    async function open(order) {
        state.value = { busy: true, text: "Calculating shipping rates, please wait..." };
        toggleDisplay(true);

        try {
            const response = await axios.post("/api/Order/CalculateRates", order);
            if (response.status !== 200) {
                useToast().success(`Unable to calculate shipping rates at this time. Please try again later.`);
                return;
            }

             model.value = response.data;            
        }
        finally {
            state.value.busy = false;
        }
    };

    const grandTotal = computed(() => {
        if (model.value == null) return null;
        return model.value.reduce((partialSum, a) => partialSum + a.totalAmount, 0);
    })

    defineExpose({
        open
    });
</script>

<template>
    <div ref="root" class="modal">
        <form @submit.prevent="onSubmit">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Shipping Rates</p>
                    <button type="button" class="delete" aria-label="close" @click="toggleDisplay(false)"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="state.busy == true">
                        <p class="heading-lg">{{state.text}}</p>
                        <progress class="progress is-large is-info" max="100">60%</progress>
                    </div>
                    <div v-else>
                        <div class="has-text-weight-bold mb-2">
                            Grand Total: {{grandTotal}}
                        </div>

                        <div v-for="(item,index) in model" class="box">
                            <div class="heading-md">Package #{{index+1}}</div>
                            <div>From Address : <span v-html="formatAddress(item.from)"></span></div>
                            <div>To Address: <span v-html="formatAddress(item.to)"></span></div>
                            <div>Shipper: {{item.shipperModel}}</div>
                            <div>Quantity: {{item.quantity}}</div>
                            <div>Price Per Unit: {{item.pricePerUnit}}</div>
                            <div class="has-text-weight-bold">Total Amount: {{item.totalAmount}}</div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot" style="justify-content:flex-end">
                    <div class="buttons">                        
                        <button type="button" class="button is-danger" @click="toggleDisplay(false)"><i class="material-icons mr-1">cancel</i>Close</button>
                    </div>
                </footer>
            </div>
        </form>
    </div>
</template>