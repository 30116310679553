<script setup>
    import { ref, computed } from 'vue';
    import axios from 'axios';
    import utils from '@/utils.js';
    import constants from '@/constants.js';

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: {
            type: Object
        },
        demoMode: {
            type: Boolean,
            default: false
        }
    });
    
    const loading = ref(false);
    const expanded = ref(false);

    const toggle = () => expanded.value = !expanded.value;
    const valueChanged = (newValue) => monthValue.value = newValue;

    function updateTracking() {                
        return new Promise(async (resolve) => {
            loading.value = true;
            try {
                if (model.value?.trackingNumber === null) resolve();
                const response = await axios.post(`/api/Tracking/UpdateLeg/${model.value.id}`);
                if (response.status !== 200) return;
                model.value = response.data;
                expanded.value = true;
                resolve();
            }
            finally {
                loading.value = false;
            }
        });
        
    }

    function censor(text, partial) {
        if (props.demoMode == false) return text;
        return utils.censorText(text, partial == true && text ? text.length /2 : null );
    }

    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit('update:modelValue', value);
        }
    });

    const computedClass = computed(() => {
        if (!model.value) return "";
        if (model.value.state == constants.TrackingState.InTransit) return "is-warning";
        if (model.value.state == constants.TrackingState.Delivered) return "is-success";
        if (model.value.state == constants.TrackingState.Error) return "is-danger";
        if (model.value.state == constants.TrackingState.Cancelled) return "is-danger";
        return "";
    });
</script>

<template>
    <div class="box" v-if="model">
        <div class="columns is-gapless is-marginless">
            <div class="column">
                <p class="subtitle mb-2">
                    <slot></slot> Destination
                </p>
            </div>
            <div class="column is-narrow">
                <div class="buttons are-small">
                    <button class="button is-success" @click="updateTracking" title="Update Tracking Information"><span class="material-icons">refresh</span></button>
                    <button class="button" @click="toggle"><span class="material-icons" title="Expand/Collapse">{{(expanded ? "expand_less" : "expand_more")}}</span></button>
                </div>
            </div>
        </div>

        <div class="columns is-gapless is-marginless">
            <div class="column is-narrow">
                <span class="material-icons md-24 mr-2">place</span>
            </div>
            <div class="column">
                <p v-show="model.shippingCarrier != null">
                    Shipping via <span v-html="utils.formatShippingCarrier(model.shippingCarrier)"></span>

                    <span class="ml-1">
                        <a v-if="model.trackingLink" v-bind:href="model.trackingLink" target="_blank">{{censor(model.trackingNumber,true)}}</a>
                        <span v-else>{{censor(model.trackingNumber, true)}}</span>
                    </span>

                    <span class="tag ml-4" v-if="model.lastStatusMessage" v-bind:class="computedClass">{{model.lastStatusMessage}}</span>
                </p>

                <div>
                    <span v-if="model.deliveryCity" class="mr-1">{{censor(model.deliveryCity)}},</span>
                    <span v-if="model.deliveryStateProvince" class="mr-1">{{model.deliveryStateProvince}}</span>
                    <span v-if="model.deliveryPostalCode" class="mr-1">{{censor(model.deliveryPostalCode)}}</span>
                    <span v-if="model.deliveryCountry">{{model.deliveryCountry}}</span>
                </div>
            </div>
            <div class="column is-narrow">
                <table class="table is-narrow is-size-7">
                    <tbody>
                        <tr v-if="model.shipDate">
                            <th>Shipped</th>
                            <td>{{utils.formatDateTime(model.shipDate)}}</td>
                        </tr>
                        <tr v-if="model.deliveryDate">
                            <th>Delivered</th>
                            <td>{{utils.formatDateTime(model.deliveryDate)}}</td>
                        </tr>
                        <tr v-if="model.lastUpdated">
                            <th>Last Updated</th>
                            <td>{{utils.formatDateTime(model.lastUpdated)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="m-2" v-show="loading == true">
            <p class="subtitle">Loading shipping details, please wait...</p>
            <progress class="progress is-primary" max="100">30%</progress>
        </div>

        <div v-if="expanded">
            <hr />
            <p><span class="material-icons">timeline</span> Events</p>

            <div v-for="event in model.events" v-bind:key="event.id" class="mt-2">
                <div class="columns">
                    <div class="column is-narrow">
                        <i class="material-icons">history</i>
                    </div>
                    <div class="column">
                        <p>
                            <span class="has-text-weight-bold">{{utils.formatDateTime(event.date)}}</span> :
                            <span class="is-italic has-text-primary">{{event.statusDescription}}</span>
                        </p>
                        <div>
                            <span v-if="event.city" class="mr-1">{{event.city}},</span>
                            <span v-if="event.stateProvince" class="mr-1">{{event.stateProvince}}</span>
                            <span v-if="event.postalCode" class="mr-1">{{event.postalCode}}</span>
                            <span v-if="event.country" class="mr-1">{{event.country}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

