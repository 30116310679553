<script setup>       
    import NavMenu from './components/NavMenu.vue';
    import { useUserStore } from './stores/user'     
    import { computed } from 'vue';

    const environment = process.env.NODE_ENV;
    const store = useUserStore();    
    const user = computed(() => store.user);
    const impersonatedUser = computed(() => store.impersonatedUser);
    const activeUser = computed(() => store.impersonatedUser || store.user);
    const demoMode = computed(() => store.demoMode);

    const exitDemoMode = () => store.setDemoMode(false);
</script>

<template>
    <div id="app">
        <div class="has-background-warning has-text-centered" v-if="environment != 'production'">
            <span class="is-vcentered">You are currently working in the {{environment}} environment. This data is not 100% accurate and can be reset at anytime.</span>
        </div>
        <div class="has-background-info-light has-text-centered" v-if="demoMode == true">
            <span class="is-vcentered">
                You are in demo mode. Sensitive information is hidden. 
                <a class="has-text-weight-bold" @click="exitDemoMode">Click here to exit.</a>
            </span>
        </div>

        <NavMenu :user="user" :impersonatedUser="impersonatedUser"></NavMenu>

        <div class="container is-fluid mt-2">
            <router-view :user="activeUser" :demoMode="demoMode"></router-view>
        </div>

        <footer class="footer mt-6 has-text-grey">
            <div class="content">
                <div class="columns">
                    <div class="column">
                        <div class="has-text-centered">
                            <div><strong>SECCURUS</strong></div>
                            <div>
                                <span>Dry Ice Shipping and Storage System</span>
                                <span class="vertical-line m-2"></span>
                                Version 1.0
                            </div>
                            <div>
                                &copy; Linde plc {{new Date().getFullYear()}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>



