<script setup>
    import moment from 'moment';
    import { ref, computed, onMounted } from 'vue';
    import axios from 'axios';
    import { useToast } from 'vue-toastification';    
    import { UnitSystem } from '@/constants.js';
    import utils from '@/utils.js';

    const props = defineProps(['user']);
    const isMetric = computed(() => props.user.unitSystem === UnitSystem.Metric);   
    const rows = ref([]);
    const exporting = ref(false);
    const pageInfo = ref({ sortKey: 'SerialNumber', pageSize: 25 });
    const confirmModal = ref(null);

    async function search(parms) {        
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);
        const response = await axios.post("/api/Manufacturing/PagedList", pageInfo.value);        
        if (response.status !== 200) return;
        rows.value = response.data.rows;
        pageInfo.value = response.data.pageInfo;
    }

    async function exportToExcel() {
        exporting.value = true;
        try {            
            const response = await axios.post("/api/Manufacturing/Export", pageInfo.value, { responseType: 'blob' });
            if (response.status !== 200) return;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ShipperManufacturingData_${moment().format('MMDDYYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }
        finally {
            exporting.value = false
        }
    }

    async function deleteRow(row) {        
        await confirmModal.value.open("Delete Manufacturing Record?", `Are you sure you want to delete the record for the shipper with the serial #${row.serialNumber}?</br></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/Manufacturing/Delete/${row.id}`);        
        if (response.status === 200) {
            search();
            useToast().success("The manufacturing record was deleted succesfully.");
        }
        else
            useToast().error("Unable to delete, perhaps the record is in use?");
    }
        
    onMounted(() => search());
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">receipt_long</i> Shipper Manufacturing Data</p>
            </div>
            <div class="column is-narrow" v-show="exporting == false">
                <a class="button has-text-black mr-1" @click="exportToExcel()"><i class="material-icons sm">download</i> Export</a>
                <router-link class="button has-text-black" :to="{ name : 'ManufacturingImport' }">
                    <i class="material-icons sm">publish</i> Import
                </router-link>
            </div>
        </div>

        <div v-if="exporting == true">
            <div class="notification">
                <div class="heading-lg">Exporting data, please wait...</div>
                <progress class="progress is-large is-primary mb-5 mt-5" max="100">15%</progress>
                Depending on the size of the record set this may take a few moments, please standby...
            </div>
        </div>
        <div v-else>            
            <div class="field">
                <div title="Search" class="control has-icons-right">
                    <input class="input" type="text" placeholder="Search" v-on:keyup.enter="search({pageNumber:1})" v-model="pageInfo.search">
                    <span class="icon is-right"><i class="material-icons">search</i></span>
                </div>
            </div>

            <div class="table-container">
                <table class="table is-striped is-hoverable is-bordered is-fullwidth">
                    <thead class="has-background-white-ter">
                        <tr class="has-background-info-light">
                            <th colspan="3">General</th>
                            <th colspan="3">Manufacturer Information</th>
                            <th colspan="4">Linde Order Information</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th class="nowrap"><GridHeader :pageInfo="pageInfo" sort-key="SerialNumber" v-on:sorted="search">Serial #</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="ModelName" v-on:sorted="search">Model Name</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="Manufacturer" v-on:sorted="search">Manufacturer</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="SublimationTestDate" v-on:sorted="search">Sublimation Test Date</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="SublimationRate" v-on:sorted="search">Sublimation Rate<br />({{isMetric ? "kg" : "lb"}}/day)</GridHeader></th>

                            <th class="nowrap"><GridHeader :pageInfo="pageInfo" sort-key="JdeOrderNumber" v-on:sorted="search">Order #</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="Shipped" v-on:sorted="search">Shipped</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="Received" v-on:sorted="search">Received</GridHeader></th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="Facility.Name" v-on:sorted="search">Receiving Facility</GridHeader></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in rows" v-bind:key="item.id">
                            <td>
                                <div class="nowrap">
                                    <a class="has-text-black" title="Delete" v-on:click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>
                                </div>
                            </td>
                            <td>{{item.serialNumber}}</td>
                            <td>{{item.modelName}}</td>
                            <td class="nowrap">{{item.manufacturer}}</td>
                            <td align="right">{{utils.formatDate(item.sublimationTestDate)}}</td>
                            <td align="right">{{utils.formatNumber(item.sublimationRate,3)}}</td>
                            <td align="right">{{item.jdeOrderNumber}}</td>
                            <td class="right">{{utils.formatDate(item.shipped)}}</td>
                            <td align="right">{{utils.formatDate(item.received)}}</td>
                            <td>
                                <span v-if="item.facility">
                                    <router-link :to="{ name : 'EditFacility', params: { id: item.facilityId}}" title="Edit" target="_blank">{{item.facility.name}}</router-link>
                                </span>
                                <span v-else>
                                    N/A
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
        </div>
    </div>
</template>
