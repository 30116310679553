export const SecurityRole = Object.freeze({
    None: 0,
    User: 1,
    Admin: 8
});

export const UserState = Object.freeze({
    Unknown: 0,
    Pending: 1,
    Active: 2,
    Disabled: -1
});

export const UserType = Object.freeze({
    Unknown: 0,
    Customer: 1,
    Employee: 2
});

export const NotificationLevel = Object.freeze({
    None: 0,
    Delays: 1,
    All: 2
});

export const MessageFormat = Object.freeze({
    None: 0,
    Email: 1,
    SMS: 2
});

export const TrackingState = Object.freeze({
    Unknown: 0,
    InTransit: 2,
    Delivered: 3,
    Error: 98,
    Cancelled: 99
});

export const ShipmentState = Object.freeze({
    Unknown: 0,
    OrderInProcess: 1,

    FirstDestinationDelayed: 100,
    FirstDestinationInTransit: 110,
    FirstDestinationDelivered: 120,

    SecondDestinationDelayed: 200,
    SecondDestinationInTransit: 210,
    SecondDestinationDelivered: 220,

    ReturnDelayed: 300,
    ReturnInTransit: 310,
    ReturnDelivered: 320,
    ReturnCompleted: 330
});

export const ShipperState = Object.freeze({
    Unknown: 0,
    Registered: 1,
    SublimationMeasurementNeeded: 2,
    WarrantyVacuumMaintenance: 4,
    LindeVacuumMaintenance: 8,
    WarrantyReplacement: 16,
    QualifiedForUse: 32
});

export const Condition = Object.freeze({
    Unknown: 0,
    Good: 1,
    Damaged: 2,
    Missing: 3
});

export const UnitSystem = Object.freeze({
    Metric: 0,
    Imperial: 1
});

export const FacilityFlags = Object.freeze({
    None: 0,
    FillStation: 1,
    CentralOperations: 2
});

export default {
    FacilityFlags,
    SecurityRole,
    UserState,
    UserType,
    NotificationLevel,
    MessageFormat,
    ShipmentState,
    ShipperState,
    TrackingState,
    Condition,
    UnitSystem
};