import moment from 'moment';
import { ShipperState, ShipmentState } from '@/constants.js';

moment.suppressDeprecationWarnings = true;

const momentFormat = (date, format) => !date ? null : moment(String(date)).format(format);
const formatDateTime = (value, format) => momentFormat(value, format || "lll");
const formatDate = (value, format) => momentFormat(value, format || "ll");
const getIsoDate = () => moment().toISOString();

const formatNumber = (value, decimals) => {
    if (value == 0) return "0";
    if (!value || isNaN(value)) return "";
    if (typeof (decimals) === "undefined") decimals = 0;
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
};

const formatShippingCarrier = (carrier) => {
    carrier = (carrier || "").trim().toLowerCase();

    if (carrier == "fedex")
        return "<span class='has-text-weight-bold pl-1 pr-1'><span style='color:#660099'>Fed</span><span style='color:#ff6600'>Ex</span></span>";
    if (carrier == "ups")
        return "<span class='has-text-weight-bold pl-1 pr-1' style='color:#FFB500;background:#351C15;font-style:italic;'>UPS</span>";
    if (carrier.startsWith("dhl"))
        return "<span class='has-text-weight-bold pl-1 pr-1' style='color:#D40511;background:#FFCC00;font-style:italic;'>DHL</span>";

    return `<span class='has-text-grey-dark'>${carrier}</span>`;
};

const formatShipmentState = (state) => {
    if (state == ShipmentState.OrderInProcess) return "<span class='tag is-info'>Order In Process</span>";
    if (state == ShipmentState.FirstDestinationInTransit) return "<span class='tag is-warning is-light'>First Destination In Transit</span>";
    if (state == ShipmentState.FirstDestinationDelivered) return "<span class='tag is-success'>First Destination Delivered</span>";
    if (state == ShipmentState.FirstDestinationDelayed) return "<span class='tag is-danger is-light'>First Destination Delayed</span>";
    if (state == ShipmentState.SecondDestinationInTransit) return "<span class='tag is-warning'>Second Destination In Transit</span>";
    if (state == ShipmentState.SecondDestinationDelivered) return "<span class='tag is-success'>Second Destination Delivered</span>";
    if (state == ShipmentState.SecondDestinationDelayed) return "<span class='tag is-danger is-light'>Second Destination Delayed</span>";
    if (state == ShipmentState.ReturnInTransit) return "<span class='tag is-warning'>Return In Transit</span>";
    if (state == ShipmentState.ReturnDelivered) return "<span class='tag is-success'>Return Delivered</span>";
    if (state == ShipmentState.ReturnDelayed) return "<span class='tag is-danger is-light'>Return Delayed</span>";
    if (state == ShipmentState.ReturnCompleted) return "<span class='tag'>Return Completed</span>";
    return "";
};

const formatShipperState = (state) => {
    var html = "<span class='tags are-small'>";
    if (state & ShipperState.Registered) html += "<span class='tag is-info is-light'>Registered</span>";
    if (state & ShipperState.SublimationMeasurementNeeded) html += "<span class='tag is-warning is-light'>Sublimation Measurement Needed</span>";    
    if (state & ShipperState.WarrantyVacuumMaintenance) html += "<span class='tag is-warning is-light'>Warranty Vacuum Maintenance</span>";
    if (state & ShipperState.LindeVacuumMaintenance) html += "<span class='tag is-warning is-light'>Linde Vacuum Maintenance</span>";    
    if (state & ShipperState.WarrantyReplacement) html += "<span class='tag is-danger is-light'>Warranty Replacement</span>";            
    if (state & ShipperState.QualifiedForUse) html += "<span class='tag is-success is-light'>Qualified for Use</span>";    
    return ( html += "</span>" );
};

const formatError = (error) => {
    if (typeof error === "string" || error instanceof String) 
        return error;

    var messages = [];

    if (Object.prototype.hasOwnProperty.call(error, "message")) messages.push(error.message);

    if (Object.prototype.hasOwnProperty.call(error, "response")) {
        if (Object.prototype.hasOwnProperty.call(error.response, "data")) {
            messages.push(error.response.data);
        }
    }
    
    return messages.join("\r\n");
}

const sortLocal = (parms, primer) => {
    //if a nested object, we dig down to get the childs property value
    const dotValue = (o, path) => path.split('.').reduce((a, b) => a[b], o);

    //primer == do something special to object before sorting
    const key = primer
        ? (x) => primer(dotValue(x, parms.sortKey))
        : (x) => dotValue(x, parms.sortKey);

    //figure out the sort order
    var multiplier = (parms.sortOrder || "asc") == "asc" ? 1 : -1;

    //return the sort function
    return (a, b) => { return a = key(a), b = key(b), multiplier * ((b == null || a > b) - (a == null || b > a)) };
};

const doubleScroll = (element) => {
    var scrollbar = document.createElement('div');
    scrollbar.style.overflow = 'auto';
    scrollbar.style.overflowY = 'hidden';

    var childDiv = scrollbar.appendChild(document.createElement('div'));
    childDiv.style.width = (element.scrollWidth) + 'px';
    childDiv.style.paddingTop = '1px';

    scrollbar.onscroll = () => {
        element.scrollLeft = scrollbar.scrollLeft;
    }
    element.onscroll = () => {
        scrollbar.scrollLeft = element.scrollLeft;        
    }

    element.parentNode.insertBefore(scrollbar, element);

    setTimeout(() => { childDiv.style.width = (element.scrollWidth) + 'px'; }, 1000);
};

const censorText = (text, charLength) => {
    if (!text || text == null) return '';
    const sensor = (text) => text.replace(/./gi, '*');

    if (!charLength) return sensor(text);
    return text.substring(0, charLength) + sensor(text.substring(charLength, text.length));
}

const createDebounce = () => {
    let timeout = null;
    return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fnc();
        }, delayMs || 500);
    };
};

export default {    
    formatDate,
    formatDateTime,
    getIsoDate,
    formatNumber,
    formatShippingCarrier,
    formatShipmentState,
    formatShipperState,
    createDebounce,
    doubleScroll,
    sortLocal,
    formatError,
    censorText
};