<script setup>
    import { ref, watch, computed, onMounted } from 'vue';
    import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
    import axios from 'axios';
    import * as yup from 'yup';
    import ShipperPicker from './ShipperPicker.vue';
    import utils from '@/utils.js';
    import constants from '@/constants.js';
    import { useToast } from 'vue-toastification';

    const props = defineProps(["user"]);

    const schema = yup.object({
        facilityId: yup.number().nullable().required().label("Destination")
    });

    let debounce = utils.createDebounce();
    let watchers = [];
    const model = ref(null);    
    const route = useRoute();
    const router = useRouter();
    const modified = ref(false);
    const serialNumber = ref("");
    const facilities = ref([]);
    const pageInfo = ref({ sortKey: 'serialNumber', sortOrder: 'asc' });
    const shipperPicker = ref(null);
    const confirmModal = ref(null);
    
    function resetWatch() {
        //kill the watch if its already running
        watchers.forEach(x => x());
        watchers = [];

        modified.value = false;

        watchers.push(
            watch(
                () => model.value,
                () => modified.value = true,
                { deep: true }
            )
        );

        watchers.push(
            watch(
                () => serialNumber.value,
                (oldVal, newVal) => {
                    if (newVal == null || newVal.trim().length == 0) return;
                    if (newVal != oldVal) findShipper();
                }
            )
        );
    }

    function findShipper() {        
        if ((serialNumber.value || "").trim().length == 0) return;

        debounce( async () => {
            const response = await axios.post(`/api/PackingList/FindShipper/${serialNumber.value}`);

            if (response.status === 204) {
                useToast().success(`Could not find shipper matching the barcode "${serialNumber.value}".`);
                return;
            }

            if (response.status !== 200) return;
            let item = response.data;
            let matches = model.value.shipperSummaries.filter(x => x.shipperId == item.shipperId);
            if (matches.length > 0) return;
            model.value.shipperSummaries.push(response.data);
            serialNumber.value = "";
            useToast().success(`Added shipper with serial number ${item.serialNumber} to the packing list.`);
        });
    }

    async function pickShippers() {                                        
        const items = await shipperPicker.value.open();                
        items.forEach(item => {
            let matches = model.value.shipperSummaries.filter(x => x.shipperId == item.shipperId);
            if (matches.length == 0) model.value.shipperSummaries.push(item);
        });
    }

    function sortShippers(parms) {        
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);
        model.value.shipperSummaries = model.value.shipperSummaries.sort(utils.sortLocal(parms));
    }

    async function onSubmit() {        
        //map the shippers back to a PackingListShipperDto[]
        model.value.shipperSummaries = model.value.shipperSummaries.map((x) => ({ id: x.id || 0, packingListId: model.value.id || 0, shipperId: x.shipperId }));
        const response = await axios.post('/api/PackingList/Save', model.value);        
        if (response.status !== 200) return;
        resetWatch();
        await router.push({ name: 'PackingLists' });
        useToast().success("The packing list was saved successfully!");
    }

    async function loadLookups() {
        const response = await axios.get('/api/Facility/List');
        if (response.status !== 200) return;
        facilities.value = response.data;
    }

    async function load() {
        const response = await axios.get(`/api/PackingList/Get/${route.params.id || ""}`, pageInfo.value);
        if (response.status == 204) {
            useToast().error(`No packing list found with the id #${route.params.id}.`);
            return;
        }

        model.value = response.data;
        resetWatch();
    }

    async function remove(item) {
        await confirmModal.value.open("Delete Shipper?", `Are you sure you want to delete shipper #${item.serialNumber} from this packing list?`);
        let index = model.value.shipperSummaries.indexOf(item);
        model.value.shipperSummaries.splice(index, 1);
    }

    const packingListName = computed(() => model.value?.name);
    const isMetric = computed(() => props.user.unitSystem === constants.UnitSystem.Metric);
    const totalTareWeight = computed(() => {
        if (model.value == null || model.value.shipperSummaries.length == 0) return 0;
        return model.value.shipperSummaries.reduce((partialSum, a) => partialSum + a.tareWeight, 0);
    });

    onBeforeRouteLeave( async (to, from, next) => {
        if (modified.value == true) {
            await confirmModal.value.open("Discard Changes?", 'Are you sure you want to leave? You have unsaved changes!');
            next();
            return;
        }

        next();
    });

    onMounted(() => {
        loadLookups();
        load();
    });
</script>

<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <p class="title">
                    <i class="material-icons">pallet</i>&nbsp; {{$route.params.id ? "Edit" : "Add"}} Packing List
                </p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <a class="button has-text-black mr-1"
                       v-if="model != null && model.id > 0"
                       v-bind:href="`/api/PackingList/Export/${model.id}`" target="_parent"><i class="material-icons sm">download</i> Export</a>

                    <router-link class="button is-pulled-right" :to="{ name : 'PackingLists' }" title="Return to List">
                        <i class="material-icons md-18">list</i>
                    </router-link>
                </div>
            </div>
        </div>

        <ConfirmModal ref="confirmModal"></ConfirmModal>
        <ShipperPicker v-if="model" ref="shipperPicker" :id="$route.params.id" :summary="model.shipperSummaries"></ShipperPicker>

        <Form @submit="onSubmit" :validation-schema="schema" v-if="model">
            <div class="box">
                <div class="field is-horizontal">
                    <div class="field">
                        <label class="label">Where are these shippers being sent?</label>
                        <div class="select control is-fullwidth">
                            <Field as="select" name="facilityId" v-model="model.facilityId">
                                <option :value="null"></option>
                                <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                    {{option.name}}
                                </option>
                            </Field>
                        </div>
                        <ErrorMessage class="errors" name="facilityId" />
                    </div>
                </div>

                <h2 class="heading-md"><i class="material-icons md-18">propane_tank</i> Included Shippers</h2>
                <div class="columns">
                    <div class="column">
                        <label class="label">Scan shipper barcode</label>
                        <div class="control has-icons-right" title="scan barcode">
                            <input class="input" type="text" placeholder="Scan barcode" name="serialNumber" v-model="serialNumber" />
                            <span class="icon is-small is-right">
                                <span class="material-icons md-18">barcode_reader</span>
                            </span>
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <div class="mt-3 has-text-grey is-size-5">OR</div>
                    </div>
                    <div class="column">
                        <label class="label">Pick shipper(s) from list</label>
                        <button class="button" type="button" @click="pickShippers()">
                            <span class="material-icons md-18">playlist_add</span>
                        </button>
                    </div>
                </div>

                <div class="table-container">
                    <table class="table is-striped is-hoverable is-bordered is-narrow">
                        <thead class="has-background-white-ter">
                            <tr>
                                <th class="is-narrow"></th>
                                <th><GridHeader :pageInfo="pageInfo" sortKey="status" @sorted="sortShippers">Status</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sortKey="serialNumber" @sorted="sortShippers">Serial #</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sortKey="sentryNumber" @sorted="sortShippers">SENTRY #</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sortKey="shipperModelName" @sorted="sortShippers">Model</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sortKey="tareWeight" @sorted="sortShippers">Tare Weight ({{(isMetric ? "kg" : "lb")}})</GridHeader></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in model.shipperSummaries" v-bind:key="item.id">
                                <td>
                                    <div class="nowrap">
                                        <a class="has-text-black" title="Delete" v-on:click="remove(item)"><i class="material-icons md-18">delete</i></a>
                                    </div>
                                </td>
                                <td><div v-html="utils.formatShipperState(item.status)"></div></td>
                                <td>{{item.serialNumber}}</td>
                                <td>{{item.sentryNumber}}</td>
                                <td>{{item.shipperModelName}}</td>
                                <td align="right">{{utils.formatNumber(item.tareWeight,2)}}</td>
                            </tr>
                        </tbody>
                        <tfoot class="has-background-info-light">
                            <tr>
                                <th colspan="5">
                                    <span class="is-pulled-right">Total Weight</span>
                                </th>
                                <th align="right">
                                    {{utils.formatNumber(totalTareWeight,2)}}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="buttons is-pulled-right">
                        <button type="submit" class="button is-info" v-bind:disabled="modified == false"><i class="material-icons md-18 mr-1">save</i>Save</button>
                        <router-link class="button" :to="{ name : 'PackingLists' }"><i class="material-icons md-18 mr-1">cancel</i>Cancel</router-link>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>
