<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        title: {
            type: String,
            required: true
        }
    });

    const expanded = ref(true);
</script>

<template>
    <div class="box">
        <div class="columns">
            <div class="column">
                <h2 class="heading-md"><span class="material-icons mr-1">fact_check</span>{{title}}</h2>
            </div>
            <div class="column is-narrow cursor-pointer" @click="expanded = !expanded">
                <span v-if="expanded == true" class="material-icons">expand_less</span>
                <span v-else class="material-icons">expand_more</span>
            </div>
        </div>

        <div v-show="expanded">
            <slot></slot>
        </div>
    </div>
</template>