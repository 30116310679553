<script setup>
    import * as yup from 'yup';
    import { onMounted, ref } from 'vue';    
    import axios from 'axios';
    
    const schema = yup.object({
        carrier: yup.string().nullable().required().label("Carrier"),
        trackingNumber: yup.string().nullable().required().label("Tracking Number")
    });

    const carriers = ref([]);
    const carrier = ref("");
    const trackingNumber = ref(null);
    const aftershipData = ref(null);
    const unifiedData = ref(null);

    async function onSubmit() {        
        const response = await axios.get(`/api/Tracking/${carrier.value}/${trackingNumber.value}`);
        if (response.status !== 200) return;
        aftershipData.value = response.data.aftership;
        unifiedData.value = response.data.unified;
    }

    onMounted(async () => {
        const response = await axios.get('/api/Tracking/ListCarriers');
        if (response.status !== 200) return;
        carriers.value = response.data;
        carrier.value = response.data[0].slug;
    })
</script>

<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <p class="title"><span class="material-icons mr-1">travel_explore</span>Track Package</p>
                <p class="notification is-warning">This page is intended for <em>internal use only</em>. It allows you to view the 
                <a href="https://www.aftership.com/" target="_blank">aftership</a> API response and how it is unified into a common format 
                for use within the Seccurus application.</p>
            </div>
        </div>

        <Form @submit="onSubmit" :validation-schema="schema" @keydown.enter="$event.preventDefault()">
            <div class="box">
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Carrier</label>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="carrier" v-model="carrier">
                                    <option :value="null"></option>
                                    <option v-for="(option,idx) in carriers" v-bind:key="idx" v-bind:value="option.slug">
                                        {{option.otherName}} ({{option.name}})
                                    </option>
                                </Field>
                            </div>
                            <ErrorMessage class="errors" name="carrier" />
                        </div>
                        <div class="field">
                            <label class="label">Tracking Number</label>
                            <div class="field is-grouped">
                                <div class="control">
                                    <Field class="input" type="text" placeholder="Tracking #" name="trackingNumber" v-model="trackingNumber" />
                                </div>
                                <div class="control">
                                    <button class="button is-link" type="submit">Go</button>
                                </div>
                            </div>
                            <ErrorMessage class="errors" name="trackingNumber" />
                        </div>
                    </div>
                </div>
            </div>
        </Form>
        
        <div v-if="aftershipData != null">
            <hr />
            <p class="label">Response from Aftership API</p>
            <textarea class="textarea" rows="10" :value="JSON.stringify(aftershipData, null, 3)"></textarea>
            <p class="label">Unified response from Seccurus API</p>
            <textarea class="textarea" rows="10" :value="JSON.stringify(unifiedData, null, 3)"></textarea>
        </div>
    </div>
</template>