<script setup>
    import { ref, computed } from 'vue';
    import * as yup from 'yup';
    import axios from 'axios';
    import moment from 'moment';    
    import constants from '@/constants.js';    

    const schema = yup.object({
        minEmptyWeightWithCap: yup.number().nullable(),
        maxEmptyWeightWithCap: yup.number().nullable(),
        isCooledDown: yup.boolean().nullable().oneOf([true], 'Must be answered yes.'),
        firstWeightTaken: yup.date().nullable().required().label("first weight taken"),
        firstWeight: yup.number().nullable().required()
            .test("first_weight_too_low",
                "Entered weight is outside the possible range. Check your measurement and re-enter the value. If this error message continues, ask for help.",
                function (value, ctx) {
                    let minWeight = ctx.parent.minEmptyWeightWithCap;
                    let maxWeight = ctx.parent.maxEmptyWeightWithCap;
                    if (value == null || minWeight == null || maxWeight == null) return true;
                    return (value >= minWeight && value <= maxWeight);
                }
            )
            .label("first weight"),
        secondWeightTaken: yup.date().nullable()
            .test("second_weight_24_hours",
                "The second weight must be taken at least 24 hours after the first weight.",
                function (value) {
                    if (value == null || this.parent.firstWeightTaken == null) return true;
                    let hours = moment(value).diff(moment(this.parent.firstWeightTaken), 'hours');
                    return hours >= 24;
                }
            )
            .test("second_weight_in_past",
                "The second sample date must be less than or equal to the current date/time.",
                function (value) {
                    if (value == null || this.parent.firstWeightTaken == null) return true;
                    return moment(value) <= moment();
                }
            )
            .label("second weight taken"),
        secondWeight: yup.number().nullable()
            .test("second_weight_too_low",
                "Entered weight is outside the possible range. Check your measurement and re-enter the value. If this error message continues, ask for help.",
                function (value, ctx) {
                    let minWeight = ctx.parent.minEmptyWeightWithCap;
                    let maxWeight = ctx.parent.maxEmptyWeightWithCap;
                    if (value == null || minWeight == null || maxWeight == null) return true;
                    return (value >= minWeight && value <= maxWeight);
                }
            )
            .test("second_weight_less_than_first_weight",
                "The second weight must be less than the first weight.",
                function (value) {
                    if (value == null || this.parent.firstWeight == null) return true;
                    return parseFloat(value) < parseFloat(this.parent.firstWeight);
                }
            )
            .label("second weight")
    });

    const props = defineProps(["user", "shipper"]);
    const model = ref(null);
    const shipperModel = ref(null);
    const isCooledDown = ref(null);
    const action = ref("Add/Edit");
    const root = ref(null);
    let resolver = null;
    
    async function load(id) {        
        const modelResponse = await axios.get(`/api/ShipperModel/Get/${props.shipper.shipperModelId}`);
        if (modelResponse.status !== 200) return;
        shipperModel.value = modelResponse.data;
        const response = await axios.get(`/api/Shipper/GetSublimationMeasurement/${id || ""}`);                
        if (response.status != 200) return;
        model.value = response.data;
        model.value.shipperId = props.shipper.id;
        isCooledDown.value = model.value.id <= 0 ? null : model.value.id > 0;
    }

    async function onSubmit() {        
        model.value.shipperId = props.shipper.id;
        const response = await axios.post('/api/Shipper/SaveSublimationMeasurement', model.value);
        if (response.status !== 200) return;
        model.value = response.data;
        toggleDisplay(false);
        resolver(model.value);
    }

    function open(id) {
        action.value = id == null ? "Add" : "Edit";
        return new Promise((resolve) => {
            resolver = resolve;
            load(id);
            toggleDisplay(true);
        });
    }

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";

    const isMetric = computed(() => props.user.unitSystem === constants.UnitSystem.Metric);    

    defineExpose({
        open
    })
</script>

<template>
    <div class="modal" ref="root">
        <Form @submit="onSubmit" :validation-schema="schema" @keydown.enter="$event.preventDefault()" @keydown.escape="toggleDisplay(false)">
            <div class="modal-background"></div>
            <div class="modal-card mt-2">
                <header class="modal-card-head">
                    <p class="modal-card-title"><span class="material-icons">scale</span> {{action}} Sublimation Measurement</p>
                    <button type="button" class="delete" aria-label="close" v-on:click="toggleDisplay(false)" title="close"></button>
                </header>
                <section class="modal-card-body">                   
                    <div v-if="model != null">
                        <Field type="hidden" name="minEmptyWeightWithCap" :value="shipperModel.minEmptyWeightWithCap" />
                        <Field type="hidden" name="maxEmptyWeightWithCap" :value="shipperModel.maxEmptyWeightWithCap" />

                        <div class="box">
                            <p class="heading-sm mb-2">First Weight</p>

                            <div class="field" v-if="model.id <= 0">
                                <label>Was the shipper filled with dry ice overnight (for at least 8 hours) before measuring the first weight?</label>
                                <div class="control">
                                    <label class="radio">
                                        <Field type="radio" name="isCooledDown" v-model="isCooledDown" :value="true" />
                                        Yes
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="isCooledDown" v-model="isCooledDown" :value="false" />
                                        No
                                    </label>
                                </div>

                                <div class="notification is-warning is-light mt-1" v-show="isCooledDown == false">
                                    The shipper needs to cool for at least 2 hours after adding dry ice. Please return when at least 2 hours have elapsed.
                                </div>
                            </div>

                            <div class="field is-horizontal" v-show="isCooledDown == true || model.id > 0">
                                <div class="field-body">
                                    <div class="field">
                                        <label>Date &amp; Time</label>
                                        <DatePicker input-class="input" v-model="model.firstWeightTaken" name="firstWeightTaken" :showTime="true"></DatePicker>
                                        <Field type="hidden" v-model="model.firstWeightTaken" name="firstWeightTaken" />
                                        <ErrorMessage class="errors" name="firstWeightTaken" />
                                    </div>

                                    <div class="field">
                                        <div>
                                            <label class="is-pulled-left">First Weight of shipper with cap inserted.</label>
                                            <span class="material-icons md-18 ml-2 cursor-help" title="Enter value in the units shown below to 2 decimal places.">help</span>
                                        </div>
                                        <UnitEditor name="firstWeight" v-model.number="model.firstWeight">{{(isMetric ? "kg" : "lb")}}</UnitEditor>
                                        <ErrorMessage class="errors" name="firstWeight" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box" v-show="isCooledDown == true">
                            <p class="heading-sm mb-2">Second Weight</p>

                            <div class="field is-horizontal">
                                <div class="field-body">
                                    <div class="field">
                                        <label>Date &amp; Time</label>
                                        <DatePicker input-class="input" v-model="model.secondWeightTaken" name="secondWeightTaken" :showTime="true"></DatePicker>
                                        <Field type="hidden" v-model="model.secondWeightTaken" name="secondWeightTaken" />
                                        <ErrorMessage class="errors" name="secondWeightTaken" />
                                    </div>

                                    <div class="field">
                                        <div>
                                            <label class="is-pulled-left">Second Weight of shipper with cap inserted.</label>
                                            <span class="material-icons md-18 ml-2 cursor-help" title="Enter value in the units shown below to 2 decimal places.">help</span>
                                        </div>
                                        <UnitEditor name="secondWeight" v-model.number="model.secondWeight">{{(isMetric ? "kg" : "lb")}}</UnitEditor>
                                        <ErrorMessage class="errors" name="secondWeight" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </section>
                <footer class="modal-card-foot is-justify-content-right">
                    <div class="buttons">
                        <button type="submit" class="button is-success">OK</button>
                        <button type="button" class="button" @click="toggleDisplay(false)">Cancel</button>
                    </div>
                </footer>
            </div>
        </Form>
    </div>
</template>