<script setup>
    import { ref } from "vue";

    const props = defineProps({        
        icon: {
            type: String,
            default: 'help_outline'
        },
        iconClass: {
            type: String,
            default: 'has-text-primary'
        },
        confirmButtonClass: {
            type: String,
            default: 'is-success'
        },
        confirmButtonText: {
            type: String,
            default: 'Yes'
        },
        cancelButtonClass: {
            type: String,
            default: 'is-danger'
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel'
        }
    });

    const root = ref(null);    
    const captionText = ref('Are you absolutely sure?');
    const titleText = ref("Confirm");
    let onSubmit = null;

    function toggleDisplay(value) {
        root.value.style.display = value ? "block" : "none";
    }

    function open(title, caption) {        
        if (caption) captionText.value = caption;
        if (title) titleText.value = title;

        return new Promise((resolve) => {             
            onSubmit = () => {
                resolve();
                toggleDisplay(false);
            };
            toggleDisplay(true);
        })
    };

    defineExpose({        
        open
    });
</script>

<template>
    <div class="modal" ref="root">
        <div class="modal-background"></div>
        <div class="modal-card mt-6">
            <header class="modal-card-head">
                <p class="modal-card-title">{{titleText}}</p>
                <button type="button" class="delete" aria-label="close" @click="toggleDisplay(false)"></button>
            </header>
            <section class="modal-card-body">
                <div class="columns is-centered">
                    <div class="column is-narrow">
                        <i class="material-icons" v-bind:class="[iconClass]" style="font-size:96px">{{icon}}</i>
                    </div>
                    <div class="column">
                        <p class="subtitle" v-html="captionText"></p>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot" style="justify-content:flex-end">
                <div class="columns is-pulled-right">
                    <div class="column is-narrow">
                        <div class="field is-grouped">
                            <p class="control">
                                <a class="button" @click="onSubmit" v-bind:class="[confirmButtonClass]">{{confirmButtonText}}</a>
                            </p>
                            <p class="control ml-4">
                                <a class="button" @click="toggleDisplay(false)">{{cancelButtonText}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>