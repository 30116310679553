<script setup>
    import { ref } from 'vue';
    import axios from 'axios';

    const root = ref(null);
    const toggleAllCheckbox = ref(null);
    const items = ref([]);
    const selectedIds = ref([]);
    let resolver = null;
    const pagingInfo = ref({ sortKey: 'Customer.Name', pageSize: 10 });

    function ok() {
        resolver(selectedIds.value);
        toggleDisplay(false);
    }

    const toggleSelection = (event, item) => toggleItem(item, event.target.checked);     
    const toggleAll = (event) => items.value.forEach(x => toggleItem(x, event.target.checked));

    function toggleItem(item, checked) {
        if (checked)
            selectedIds.value.push(item.id);
        else
            selectedIds.value.splice(selectedIds.value.indexOf(item.id), 1);
    }

    const isSelected = (item) => selectedIds.value.filter(x => x == item.id).length > 0;

    async function search(parms) {
        if (parms) pagingInfo.value = Object.assign(pagingInfo.value, parms);
        toggleAllCheckbox.value.checked = false;
        const response = await axios.post("/api/Customer/PagedAccountList", pagingInfo.value);
        if (response.status !== 200) return;
        items.value = response.data.rows;
        pagingInfo.value = response.data.pagingInfo;           
    }

    function open(ids) {        
        selectedIds.value = ids;
        search();

        return new Promise((resolve, reject) => {
            resolver = resolve;
            toggleDisplay(true);
        })
    }

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";

    defineExpose({
        open
    })
</script>

<template>
    <div ref="root" class="modal" v-on:keydown.escape="toggleDisplay(false)">
        <div class="modal-background"></div>
        <div class="modal-card mt-6">

            <header class="modal-card-head">
                <p class="modal-card-title">Select customer account(s)</p>
                <button type="button" class="delete" aria-label="close" v-on:click="toggleDisplay(false)" title="close"></button>
            </header>
            <section class="modal-card-body">
                <p title="Search" class="control has-icons-right mb-2">
                    <input ref="keyword" class="input is-small" type="text" placeholder="Search" v-on:keyup.enter="search()" v-model="pagingInfo.search">
                    <span class="icon is-small is-right"><i class="material-icons">search</i></span>
                </p>

                <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                    <thead class="has-background-white-ter">
                        <tr>
                            <th>
                                <input type="checkbox" v-on:click="toggleAll($event)" ref="toggleAllCheckbox" />
                            </th>
                            <th><GridHeader :pageInfo="pagingInfo" sort-key="Customer.Name" v-on:sorted="search">Customer</GridHeader></th>
                            <th><GridHeader :pageInfo="pagingInfo" sort-key="Number" v-on:sorted="search">Account #</GridHeader></th>
                            <th><GridHeader :pageInfo="pagingInfo" sort-key="Name" v-on:sorted="search">Account Name</GridHeader></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,idx) in items" v-bind:key="idx">
                            <td>                                
                                <input type="checkbox" v-on:click="toggleSelection($event,item)" v-bind:checked="isSelected(item)" />
                            </td>
                            <td>{{item.customerName}}</td>
                            <td>{{item.number}}</td>
                            <td>{{item.name}}</td>
                        </tr>
                    </tbody>
                </table>

                <GridPager :pageInfo="pagingInfo" v-on:paged="search"></GridPager>
            </section>
            <footer class="modal-card-foot is-pulled-right">
                <div class="buttons is-pulled-right">
                    <button type="button" class="button is-success" @click="ok">OK</button>
                    <button type="button" class="button" @click="toggleDisplay(false)">Cancel</button>
                </div>
            </footer>
        </div>
    </div>
</template>

<style scoped>
    .modal-card { width: 90%; }
</style>