<script setup>
    import Datepicker from '@vuepic/vue-datepicker';
    import moment from 'moment';
    import { computed } from 'vue';

    const emit = defineEmits(['update:modelValue']);

    const props = defineProps({
        name: {
            type: String,
            required: true
        },
        modelValue: {}
    });

    const valueChanged = (newValue) => monthValue.value = newValue;

    const monthValue = computed({
        get() {
            if (props.modelValue == null) return null;
            let date = moment(props.modelValue);
            return { month: date.month(), year: date.year() };
        },
        set(value) {
            let date = moment({ month: value.month, year: value.year, day: 1 }).endOf("month");
            emit('update:modelValue', date);
        }
    });
</script>

<template>
    <Datepicker placeholder="Select a Date"
                :enable-Time-Picker="false"
                :clearable="false"
                autoApply
                :name="name"
                format="MMM yyyy"
                v-model="monthValue"
                @update:model-value="valueChanged"
                monthPicker>
    </Datepicker>
</template>