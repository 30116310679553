<script setup>
    import { ref, onMounted, watch, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import axios from 'axios';
    import * as yup from 'yup';    
    import { useForm } from 'vee-validate';
    import { useToast } from 'vue-toastification';
    import AccountModal from './AccountModal.vue';

    const { meta, errors, handleSubmit, submitCount } = useForm({
        validationSchema: yup.object({
            name: yup.string().nullable().required().label("Name"),
            customerServiceCenterId: yup.number().nullable().required().label("customer service center")
        })
    });

    const customer = ref({});
    const modified = ref(false);
    const route = useRoute();
    const router = useRouter();
    const accountModal = ref(null);
    const confirmModal = ref(null);
    const customerServiceCenters = ref([]);    
    let watcher = null;

    const onSubmit = handleSubmit(async () => {
        const response = await axios.post('/api/Customer/Save', customer.value);
        if (response.status !== 200) return;
        modified.value = false;
        customer.value = response.data;

        await router.push({ name: 'Customers' });
        useToast().success("The customer was saved successfully!");
    });

    async function editAccount(item) {
        await accountModal.value.open(item);
    }

    async function addAccount() {
        const account = await accountModal.value.open();
        account.customerId = route.params.id;
        customer.value.accounts.push(account);
    }

    async function deleteAccount(item) {
        await confirmModal.value.open("Delete Account?", `Are you sure you want to delete account #${item.number}?`);
        let index = customer.value.accounts.indexOf(item);
        customer.value.accounts.splice(index, 1);
    }

    function resetWatch() {
        //kill the watch if its already running
        if (watcher != null) watcher();
        modified.value = false;

        watcher = watch(customer.value, () => {
            modified.value = true;
        });
    }

    async function loadData() {
        const response = await axios.get(`/api/Customer/Get/${route.params.id || ""}`);

        if (response.status == 204) {
            useToast().warning(`No customer record found with the id #${route.params.id}.`);
            return;
        }

        if (response.status == 200) {
            customer.value = response.data;
            resetWatch();
        }
    }

    async function loadLookups() {
        await Promise.all([
            axios.get('/api/CustomerServiceCenter/List').then(x => customerServiceCenters.value = x.data),    
        ]);
    }

    onMounted(async () => {
        await loadLookups();
        loadData();
    });
</script>

<template>
    <div class="container">
        <ConfirmModal ref="confirmModal"></ConfirmModal>
        <AccountModal ref="accountModal"></AccountModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">business</i>&nbsp; {{route.params.id ? "Edit" : "Add"}} Customer</p>
            </div>
            <div class="column">
                <router-link class="button is-pulled-right" :to="{ name : 'Customers' }" title="Return to List">
                    <i class="material-icons md-18">list</i>
                </router-link>
            </div>
        </div>

        <div class="notification is-danger is-light errors" v-show="Object.keys(errors).length > 0 && submitCount > 0">
            <div v-for="error in errors">
                &bull; {{error}}
            </div>
        </div>

        <form @submit.prevent="onSubmit" v-if="customer">
            <div class="box">
                <div class="field is-grouped">
                    <div class="control is-expanded">
                        <label class="label">Name *</label>
                        <Field class="input" type="text" name="name" v-model="customer.name" />
                    </div>
                    <div class="control">
                        <label class="label">Customer Service Center *</label>
                        <div class="select control is-fullwidth">
                            <Field as="select" name="customerServiceCenterId" v-model="customer.customerServiceCenterId">
                                <option :value="null"></option>
                                <option v-for="center in customerServiceCenters" :value="center.id">{{center.name}}</option>
                            </Field>
                        </div>
                    </div>
                </div>

                <div class="columns is-marginless is-gapless mb-2">
                    <div class="column">
                        <p class="subtitle mb-1">Customer Accounts</p>
                    </div>
                    <div class="column is-narrow">
                        <div class="buttons are-small">
                            <a class="button" @click="addAccount()"><i class="material-icons">add</i></a>
                        </div>
                    </div>
                </div>

                <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                    <thead class="has-background-white-ter">
                        <tr>
                            <th></th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,idx) in customer.accounts" v-bind:key="idx">
                            <td>
                                <a @click="editAccount(item)" class="has-text-black"><i class="material-icons md-18">edit</i></a>
                                <a @click="deleteAccount(item)" class="has-text-black"><i class="material-icons md-18">delete</i></a>
                            </td>
                            <td>{{item.name}}</td>
                            <td>{{item.number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div class="buttons is-pulled-right">
                <button type="submit" class="button is-info"><i class="material-icons md-18 mr-1">save</i>Save</button>
                <router-link class="button" :to="{ name : 'Customers' }"><i class="material-icons md-18 mr-1">cancel</i>Cancel</router-link>
            </div>
        </form>
    </div>
</template>