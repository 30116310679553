<script setup>
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import VacuumMaintenanceModal from './VacuumMaintenanceModal.vue';
    import utils from '@/utils.js';
    import { useToast } from 'vue-toastification';    

    const props = defineProps(["user", "shipper"]);
    const emit = defineEmits(["changed"]);

    const items = ref([]);
    const modified = ref(false);
    const pagingInfo = ref({ sortKey: "Performed", sortOrder: "desc", pageNumber: 1, pageSize: 10 });
    const vacuumModal = ref(null);
    const confirmModal = ref(null);

    async function add() {
        const record = await vacuumModal.value.open(null);                
        items.value.push(record);
        emit("changed");
        useToast().success("The vacuum maintenance record was added successfully.");
    }

    async function edit(item) {
        const record = await vacuumModal.value.open(item.id);                
        Object.assign(item, record);
        emit("changed");
        useToast().success("The vacuum maintenance record was saved successfully.");                
    }

    async function remove(item) {
        await confirmModal.value.open("Delete Vacuum Maintenance?", `Are you sure you want to delete the vacuum maintenance performed on ${utils.formatDate(item.performed)}?</br></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/Shipper/DeleteVacuumMaintenance/${item.id}`);
        if (response.status !== 200) return;
        let index = items.value.indexOf(item);
        items.value.splice(index, 1);
        emit("changed");
        useToast().success("The vacuum maintenance record was deleted successfully.");
    }

    async function search(parms) {
        if (parms) pagingInfo.value = Object.assign(pagingInfo.value, parms);
        const response = await axios.post(`/api/Shipper/VacuumMaintenancePagedList/${props.shipper.id || ""}`, pagingInfo.value)            
        if (response.status !== 200) return;
        items.value = response.data.rows;
        pagingInfo.value = response.data.pagingInfo;
    }

    onMounted(() => search());
</script>

<template>
    <ConfirmModal ref="confirmModal"></ConfirmModal>
    <VacuumMaintenanceModal ref="vacuumModal" :shipper="shipper" :user="user"></VacuumMaintenanceModal>

    <div class="box">
        <p>
            If the dry ice sublimation rate exceeds the specification, vacuum maintenance must be performed either by the manufacturer under warranty or by Linde.  
            After vacuum maintenance is performed, record it in the table below.
        </p>

        <div class="columns mb-2 is-gapless">
            <div class="column"></div>
            <div class="column is-narrow">
                <button class="button" type="button" @click="add()">
                    <span class="material-icons md-18">add</span>
                </button>
            </div>
        </div>

        <div class="table-container" id="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                <thead class="has-background-white-ter">
                    <tr class="has-background-info-light">
                        <th></th>
                        <th class="nowrap"><GridHeader :pageInfo="pagingInfo" sort-key="IsExternal" v-on:sorted="search">Responsible Party</GridHeader></th>
                        <th class="nowrap"><GridHeader :pageInfo="pagingInfo" sort-key="Facility.Name" v-on:sorted="search">Facility</GridHeader></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="Performed" v-on:sorted="search">Date Performed</GridHeader></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="Comments" v-on:sorted="search">Comments</GridHeader></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td class="is-narrow nowrap">
                            <a class="has-text-black" @click="edit(item)" title="Edit...">
                                <span class="material-icons md-18">edit</span>
                            </a>
                            <a class="has-text-black" @click="remove(item)" title="Delete...">
                                <span class="material-icons md-18">delete</span>
                            </a>
                        </td>
                        <td class="is-narrow">{{(item.isExternal == true ? "Manufacturer" : "Linde")}}</td>
                        <td>
                            <span v-if="item.isExternal == true" class="has-text-grey">N/A</span>
                            <span v-else>
                                <span v-if="item.facility">
                                    <router-link :to="{ name : 'EditFacility', params: { id: item.facilityId}}" title="Edit" target="_blank">{{item.facility.name}}</router-link>
                                </span>
                            </span>                            
                        </td>
                        <td class="is-narrow nowrap" align="right">{{utils.formatDateTime(item.performed)}}</td>
                        <td>{{item.comments}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <GridPager :pageInfo="pagingInfo" v-on:paged="search"></GridPager>
    </div>
</template>

