<script setup>
    import { ref, computed, onMounted, watch } from 'vue';
    import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';    
    import ShippingRateModal from './ShippingRateModal.vue';
    import axios from 'axios';    
    import utils from '@/utils.js';
    import * as yup from 'yup';
    import moment from 'moment';
    import { useForm } from 'vee-validate';
    import { useToast } from 'vue-toastification';  
    import { SecurityRole } from '@/constants.js';

    const props = defineProps(["user"]);
    const route = useRoute();
    const router = useRouter();
    const model = ref({ number: '', includeSecondDestinationLabel: false, firstDestination: {}, secondDestination: {}, lineItems: [] });
    const shippingOptions = ref([]);
    const businessDays = ref([]);
    const loading = ref(true);
    const accounts = ref([]);
    const stateProvinces = ref([]);
    const shipperModels = ref([]);
    const selectedShipperModelId = ref(0);    
    const shippingRateModal = ref(null);
    const modified = ref(false);        
    let watcher = null;

    const { meta, errors, handleSubmit, submitCount } = useForm({
        validationSchema: yup.object({  
            requestedShipDate: yup.date().nullable().required().label("requested ship date"),
            telephone: yup.string().nullable().required(),            
            contactName: yup.string().nullable().required().label("contact name"),            
            email: yup.string().nullable().required(),            
            customerAccountId: yup.number().nullable().required().label("account number"),                   
            firstDestinationCompanyName: yup.string().nullable().required().label("First destination company name"),
            firstDestinationRecipients: yup.string().nullable().required().label("First destination recipient(s)"),
            firstDestinationAddressLine1: yup.string().nullable().required().label("First destination address line 1"),
            firstDestinationCity: yup.string().nullable().required().label("First destination city"),
            firstDestinationStateProvince: yup.string().nullable().required().label("First destination state/province"),
            firstDestinationPostalCode: yup.string().nullable().required().label("First destination postal code"),
            firstDestinationServiceType: yup.string().nullable().required().label("First destination service type"),
            includeSecondDestinationLabel: yup.bool(),
            secondDestinationCompanyName: yup.string().nullable()
                .when('includeSecondDestinationLabel', { is: (x) => x == true, then: () => yup.string().nullable().required() })
                .label("Second destination company name"),                           
            secondDestinationRecipients: yup.string().nullable()                     
                .when('includeSecondDestinationLabel', { is: (x) => x == true, then: () => yup.string().nullable().required() })
                .label("Second destination recipient(s)"),                           
            secondDestinationAddressLine1: yup.string().nullable()                   
                .when('includeSecondDestinationLabel', { is: (x) => x == true, then: () => yup.string().nullable().required() })
                .label("Second destination address line 1"),                         
            secondDestinationCity: yup.string().nullable()                           
                .when('includeSecondDestinationLabel', { is: (x) => x == true, then: () => yup.string().nullable().required() })
                .label("Second destination city"),                                   
            secondDestinationStateProvince: yup.string().nullable()                  
                .when('includeSecondDestinationLabel', { is: (x) => x == true, then: () => yup.string().nullable().required() })
                .label("Second destination state/province"),                         
            secondDestinationPostalCode: yup.string().nullable()                     
                .when('includeSecondDestinationLabel', { is: (x) => x == true, then: () => yup.string().nullable().required() })
                .label("Second destination postal code"),                            
            secondDestinationServiceType: yup.string().nullable()                
                .when('includeSecondDestinationLabel', { is: (x) => x == true, then: () => yup.string().nullable().required() })
                .label("Second destination service type"),                                   
            returnLabelServiceType: yup.string().nullable()
                .test("is_return_service_required", "Please indicate the return label shipping service.", function (value, ctx) {                      
                    if (returnLabelCarrierOptions.value <= 1) return true;                    
                    return value != null;                        
                })
                .label("Return label service type"),     
            totalQuantity: yup.number().nullable()
                .min(1)
                .label("Item quantity"),
            lineItems: yup.array().of(
                yup.object({
                    quantity: yup.number().required(),
                    includeProbeAndLogger: yup.bool().nullable()
                        .when('quantity', { is: (x) => x > 0, then: () => yup.bool().nullable().required() })
                        .label("Include temperature probe and data logger?"),
                    includeHolder: yup.bool().nullable()
                        .when('quantity', { is: (x) => x > 0, then: () => yup.bool().nullable().required() })
                        .label("Include product holding accessory?"),
                    includeGroundReturnLabel: yup.bool().nullable()
                        .test("is_return_question_required", "Please indicate if you need a return ground label.", function (value, ctx) {                                        
                            if (ctx.parent.quantity == 0) return true;                            
                            if (areVesselsFilledByLinde.value == true) return true;                            
                            return value !== null;
                         })
                        .label("Include return ground label")
                })
            ),
        }),
    });

    const isMetric = computed(() => props.user.unitSystem == 0); 
    const totalQuantity = computed(() => model.value == null ? 0 : model.value.lineItems.reduce((a, b) => +a + +b.quantity, 0));

    const canSaveDraft = computed(() => {
        if (props.user.securityRole == SecurityRole.Admin) return true;        
        return model.value != null && model.value.status != "Submitted";
    })

    const canSubmit = computed(() => {
        if (props.user.securityRole == SecurityRole.Admin) return true;
        if (model.value != null && model.value.id <= 0) return true;   
        return model.value != null && model.value.status != "Submitted";
    })   

    const selectedAccount = computed(() => getSelectedAccount() )
    
    const areVesselsFilledByLinde = computed(() => {
        let account = getSelectedAccount();
        return account == null ? null : account.areVesselsFilledByLinde;
    });

    const estimatedShipDate = computed(() => {                
        if (model.value == null || model.value.requestedShipDate == null) return null;
        let destination = model.value.firstDestination;
        if (destination.serviceType == null ) return null;
        let options = shippingOptions.value.filter(x => x.serviceType == destination.serviceType);
        if (options.length == 0) return null;
        let leadTime = options[0].leadTimeInDays  
        let dateMatches = businessDays.value.filter(x => moment(x).isSame(model.value.requestedShipDate, 'day'));        
        let idx = dateMatches.length == 0 ? 0 : businessDays.value.indexOf(dateMatches[0]) + leadTime;        
        return moment( businessDays.value[idx] );
    });

    function getLeadTime() {
        if (model.value == null) return 0;
        let destination = model.value.firstDestination;        
        return options.length == 1 ? options[0].leadTimeInDays : 0;
    }

    function getSelectedAccount() {
        if (model.value == null) return null;
        let matches = accounts.value.filter(x => x.id == model.value.customerAccountId);
        return matches.length == 1 ? matches[0] : null;
    }

    function onInvalidSubmit() {  
        useToast().error("One or more validation errors have occurred. Please correct the issues and try again");
        document.getElementsByClassName("errors")[0].scrollIntoView();
    }

    function saveAsDraft(){
        model.value.status = "Draft";
        onSubmit();
    }

    function saveAndSubmit() {
        model.value.status = "Submitted";
        onSubmit();
    }

    const onSubmit = handleSubmit(async () => {        
        if (model.value.status == "Submitted" && totalQuantity < 1) {
            useToast().warning("At least 1 shipper model must be given a quantity of 1 or above in the 'Product Information Section'.");
            return;
        }                

        resetWatch(); //didnt do this inside the POST in case we have an error, I dont want the user to get "stuck on this page"

        const response = await axios.post(`/api/Order/Save`, model.value);            
        if (response.status !== 200) return;
        modified.value = false;
        let message = model.value.status == "Submitted"
            ? "Thank you! Your order has been submitted, and a summary has been sent to your email."
            : "Your order has been saved as a draft. Please return here if you wish to complete you order at a later date.";
                        
        await router.push({ name: 'Orders' });
        useToast().success(message);

    }, onInvalidSubmit);

    const filteredShippingOptions = computed(() => {
        if (model.value == null) return [];
        let account = getSelectedAccount();
        if (account == null) return [];        
        
        var carriers = account.orderCarriers.split(",");
        var services = [];

        if (model.value.id > 0) {
            var firstServiceType = model.value.firstDestination?.serviceType || "";
            var secondServiceType = model.value.secondDestination?.serviceType || "";
            if (firstServiceType.length > 0) services.push(firstServiceType);
            if (secondServiceType.length > 0) services.push(secondServiceType);
        }

        return shippingOptions.value.filter(x => carriers.indexOf(x.carrier) > -1 || services.indexOf(x.serviceType) > -1);
    });

    const returnLabelCarrierOptions = computed(() => {
        if (model.value == null) return [];
        let account = getSelectedAccount();
        if (account == null) return [];

        var carriers = account.orderCarriers.split(",");        

        if (model.value.id > 0) {
            var firstCarrier = model.value.firstDestination?.carrier || "";
            var secondCarrier = model.value.secondDestination?.carrier || "";
            if (firstCarrier.length > 0 && carriers.indexOf(firstCarrier) < 0) carriers.push(firstCarrier);
            if (secondCarrier.length > 0 && carriers.indexOf(secondCarrier) < 0) carriers.push(secondCarrier);
        }

        return shippingOptions.value.filter(x => carriers.indexOf(x.carrier) > -1 && x.serviceType.indexOf("ground") >= 0);
    })

    async function loadLookups() {
        await Promise.all([
            axios.get('/api/Tracking/StateProvinces/usa').then(x => stateProvinces.value = x.data),
            axios.get('/api/Users/AssignedAccounts').then(x => accounts.value = x.data ),
            axios.get('/api/ShipperModel/List').then(x => shipperModels.value = x.data )
        ]);
    }

    const totalReturnLabels = computed(() => {
        if (model.value == null) return 0;
        return model.value.lineItems.filter(x => x.includeGroundReturnLabel === true && x.quantity > 0)
            .reduce((n, { quantity }) => n + quantity, 0);
    })

    async function showRates() {
        shippingRateModal.value.open(model.value);
    }

    function onCustomerAccountChanged() {
        let account = getSelectedAccount();
        if (account == null) return;

        var returnOptions = returnLabelCarrierOptions.value;
        model.value.returnLabelServiceType = returnOptions.length > 0 ? returnOptions[0].serviceType : null;
    }

    onBeforeRouteLeave((to, from, next) => {
        if (modified.value == true) {
            const answer = window.confirm("Are you sure you want to leave this page without saving your changes?");
            if (!answer) return;
        }
        next()
    })

    function resetWatch() {
        //kill the watch if its already running
        if (watcher != null) watcher();
        modified.value = false;

        watcher = watch(model.value, () => {
            modified.value = true;
        });

        //onCustomerAccountChanged();
    }

    onMounted(async () => {        
        loading.value = true;
        try {
            await loadLookups();
            const response = await axios.get(`/api/Order/Get/${route.params.id || ""}`);
            if (response.status !== 200) return;
            model.value = response.data.model;
            shippingOptions.value = response.data.shippingOptions;
            businessDays.value = response.data.businessDays;
            if (accounts.value.length == 1) model.value.customerAccountId = accounts.value[0].id;
            onCustomerAccountChanged();
            resetWatch();
        }
        finally {
            loading.value = false;
        }        
    });
</script>

<template>
    <div class="container">
        <ShippingRateModal ref="shippingRateModal"></ShippingRateModal>        

        <div class="columns">
            <div class="column">
                <p class="title">{{(route.name == "AddOrder" ? "Add Order" : `Edit Order #${model.number}`)}} </p>
            </div>
            <div class="column is-narrow">
                <div class="buttons is-pulled-right">
                    <router-link class="button is-pulled-right" :to="{ name : 'Orders' }" title="Return to List">
                        <i class="material-icons sm">keyboard_backspace</i>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="notification is-info is-light">
            <div class="columns">
                <div class="column is-narrow">
                    <span class="material-icons md-48">info_outline</span>
                </div>
                <div class="column">
                    This form submits a new order to Linde per the contracted pricing and terms agreed to separately.  All SECCURUS orders received on a business day before 11 am Eastern can be shipped out the same business day.
                    Business days are Monday through Friday not including major holidays.  Orders can be scheduled up to 10 business days in advance.  If you need to 
                    modify or cancel an existing order, please email <a href="mailto:seccurus@linde.com">seccurus@linde.com</a>.
                </div>
            </div>
        </div>        

        <div v-if="loading">            
            <h2>Loading, please wait...</h2>
            <progress class="progress is-large is-info" max="100">60%</progress>
        </div>
        <div v-else>
            <div class="notification is-danger is-light errors" v-show="Object.keys(errors).length > 0 && submitCount > 0">
                <div v-for="error in errors">
                    &bull; {{error}}
                </div>
            </div>

            <p class="subtitle has-text-link">Customer Information</p>

            <form @submit.prevent="onSubmit">
                <div class="box">
                    <div class="field is-grouped">
                        <div class="control">
                            <label class="label">Account Number *</label>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="customerAccountId" v-model="model.customerAccountId" @change="onCustomerAccountChanged">
                                    <option :value="null"></option>
                                    <option v-for="account in accounts" :value="account.id">{{account.name}} - {{account.number}}</option>
                                </Field>
                            </div>
                        </div>
                        <div class="control is-expanded">
                            <label class="label">Customer Reference #</label>
                            <Field type="input" class="input" name="customerReferenceNumber" v-model="model.customerReferenceNumber" placeholder="Optional - for customer use only" />
                        </div>
                        <div class="control">
                            <label class="label">Order Date *</label>
                            <DatePicker v-model="model.orderDate" name="orderDate" disabled="disabled"></DatePicker>
                        </div>
                    </div>

                    <div class="notification is-danger is-light is-small" v-show="model.customerAccountId !== null && filteredShippingOptions.length < 1">
                        There are no shipping services configured for the customer. Please contact an admin before proceeding.
                    </div>

                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <label class="label">Contact Name *</label>
                            <Field type="text" class="input" name="contactName" v-model="model.contactName" />
                        </div>
                        <div class="control">
                            <label class="label">Telephone *</label>
                            <Field type="input" class="input" name="telephone" v-model="model.telephone" />
                        </div>
                        <div class="control is-expanded">
                            <label class="label">Email *</label>
                            <Field type="input" class="input" name="email" v-model="model.email" />
                        </div>
                    </div>
                </div>

                <p class="subtitle has-text-link">Delivery Addresses <span class="has-text-black">(shipping only provided within USA)</span></p>

                <div v-show="model.firstDestination" class="box">
                    <p class="subtitle is-5"><span class="material-icons">location_city</span> First Destination</p>

                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <label class="label">Company Name *</label>
                            <Field type="input" class="input" name="firstDestinationCompanyName" v-model="model.firstDestination.companyName" />
                        </div>
                        <div class="control is-expanded">
                            <label class="label">Recipient Name(s) *</label>
                            <Field type="input" class="input" name="firstDestinationRecipients" v-model="model.firstDestination.recipients" />
                        </div>
                    </div>

                    <div class="control">
                        <label class="label">Address Line 1 *</label>
                        <Field type="input" class="input" name="firstDestinationAddressLine1" v-model="model.firstDestination.addressLine1" />
                    </div>
                    <div class="control">
                        <label class="label">Address Line 2</label>
                        <Field type="input" class="input" name="firstDestinationAddressLine2" v-model="model.firstDestination.addressLine2" />
                    </div>
                    <div class="control">
                        <label class="label">Address Line 3</label>
                        <Field type="input" class="input" name="firstDestinationAddressLine3" v-model="model.firstDestination.addressLine3" />
                    </div>

                    <div class="field is-grouped">
                        <div class="control">
                            <label class="label">City *</label>
                            <Field type="input" class="input" name="firstDestinationCity" v-model="model.firstDestination.city" />
                        </div>
                        <div class="control">
                            <label class="label">State / Province *</label>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="firstDestinationStateProvince" v-model="model.firstDestination.stateProvince">
                                    <option :value="null"></option>
                                    <option v-for="option in stateProvinces" v-bind:key="option.abbreviation" v-bind:value="option.abbreviation">
                                        {{option.abbreviation}} - {{option.name}}
                                    </option>
                                </Field>
                            </div>
                        </div>
                        <div class="control">
                            <label class="label">Postal Code *</label>
                            <Field type="input" class="input" name="firstDestinationPostalCode" v-model="model.firstDestination.postalCode" />
                        </div>
                    </div>

                    <div class="field is-grouped">
                        <div class="control">
                            <label class="label">Requested Ship Date *</label>
                            <Field type="hidden" name="requestedShipDate" v-model="model.requestedShipDate"></Field>
                            <DatePicker v-model="model.requestedShipDate" name="requestedShipDate"
                                        :allowed-dates="businessDays" />
                        </div>
                    </div>

                    <div class="field is-grouped">
                        <div class="control">
                            <label class="label">Shipping Service *</label>
                            <div class="select control">
                                <Field as="select" name="firstDestinationServiceType" v-model="model.firstDestination.serviceType">
                                    <option :value="null"></option>
                                    <option v-for="(option,idx) in filteredShippingOptions" :value="option.serviceType">{{option.description}}</option>
                                </Field>
                            </div>
                        </div>
                    </div>

                    <div class="notification is-info is-light is-small" v-show="model && model.status == 'Draft'">
                        <div v-if="estimatedShipDate != null">
                            Your order is <em>expected</em> to arrive at the First Destination by <strong>{{utils.formatDate(estimatedShipDate)}}</strong>
                        </div>
                        <div v-else>
                            Please select a customer account number and a shipping service to see the estimated arrival date.
                        </div>
                    </div>
                </div>

                <div class="box">
                    <div class="control">
                        <span class="material-icons">location_city</span>
                        <label class="checkbox ml-2">
                            <Field type="checkbox" name="includeSecondDestinationLabel" :value="true" v-model="model.includeSecondDestinationLabel" />
                            <span class="subtitle is-5 ml-3">Include Second Destination Shipping Label</span>
                        </label>
                    </div>

                    <div class="mt-5" v-show="model.includeSecondDestinationLabel == true">
                        <div class="field is-grouped">
                            <div class="control is-expanded">
                                <label class="label">Company Name *</label>
                                <Field type="input" class="input" name="secondDestinationCompanyName" v-model="model.secondDestination.companyName" />
                            </div>
                            <div class="control is-expanded">
                                <label class="label">Recipient Name(s) *</label>
                                <Field type="input" class="input" name="secondDestinationRecipients" v-model="model.secondDestination.recipients" />
                            </div>
                        </div>

                        <div class="control">
                            <label class="label">Address Line 1 *</label>
                            <Field type="input" class="input" name="secondDestinationAddressLine1" v-model="model.secondDestination.addressLine1" />
                        </div>
                        <div class="control">
                            <label class="label">Address Line 2</label>
                            <Field type="input" class="input" name="secondDestinationAddressLine2" v-model="model.secondDestination.addressLine2" />
                        </div>
                        <div class="control">
                            <label class="label">Address Line 3</label>
                            <Field type="input" class="input" name="secondDestinationAddressLine3" v-model="model.secondDestination.addressLine3" />
                        </div>

                        <div class="field is-grouped">
                            <div class="control">
                                <label class="label">City *</label>
                                <Field type="input" class="input" name="secondDestinationCity" v-model="model.secondDestination.city" />
                            </div>
                            <div class="control">
                                <label class="label">State / Province *</label>
                                <div class="select control is-fullwidth">
                                    <Field as="select" name="secondDestinationStateProvince" v-model="model.secondDestination.stateProvince">
                                        <option :value="null"></option>
                                        <option v-for="option in stateProvinces" v-bind:key="option.abbreviation" v-bind:value="option.abbreviation">
                                            {{option.abbreviation}} - {{option.name}}
                                        </option>
                                    </Field>
                                </div>
                            </div>
                            <div class="control">
                                <label class="label">Postal Code *</label>
                                <Field type="input" class="input" name="secondDestinationPostalCode" v-model="model.secondDestination.postalCode" />
                            </div>
                        </div>

                        <div class="field is-grouped">
                            <div class="control">
                                <label class="label">Shipping Service *</label>
                                <div class="select control">
                                    <Field as="select" name="secondDestinationServiceType" v-model="model.secondDestination.serviceType">
                                        <option :value="null"></option>
                                        <option v-for="(option,idx) in filteredShippingOptions" :value="option.serviceType">{{option.description}}</option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box" v-show="returnLabelCarrierOptions.length > 1">
                    <p class="subtitle is-5"><span class="material-icons">local_shipping</span> Return Label Shipping Service</p>                    
                    <div class="control">
                        <div class="select control">
                            <Field as="select" name="returnLabelServiceType" v-model="model.returnLabelServiceType">
                                <option :value="null"></option>
                                <option v-for="(option,idx) in returnLabelCarrierOptions" :value="option.serviceType">{{option.description}}</option>
                            </Field>
                        </div>
                    </div>                    
                </div>

                <p class="subtitle has-text-link">Product Information</p>

                <div class="box">
                    <Field as="hidden" name="totalQuantity" v-model="totalQuantity"></Field>
                    <table class="table is-striped is-hoverable is-bordered is-narrow">
                        <thead class="has-background-white-ter">
                            <tr>
                                <th>Model</th>
                                <th>Quantity *</th>
                                <th align="center">Include Product<br />Holding Accessory? *</th>
                                <th align="center">Include Temperature Probe<br />and Data Logger? *</th>
                                <th align="center" v-show="areVesselsFilledByLinde == false">Include Ground Return<br />Shipping Label? *</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,idx) in model.lineItems"
                                v-bind:class="{'has-background-warning': selectedShipperModelId == item.shipperModelId}"
                                @mouseover="selectedShipperModelId = item.shipperModelId">
                                <td>
                                    {{item.shipperModelName}}
                                </td>
                                <td style="max-width:100px">
                                    <Field class="input has-text-right" type="number" min="0" :name="`lineItems[${idx}].quantity`" v-model="item.quantity"></Field>
                                </td>
                                <td align="center">
                                    <div class="control mt-1" v-show="item.quantity > 0">
                                        <label class="radio">
                                            <Field type="radio" :name="`lineItems[${idx}].includeHolder`" v-model="item.includeHolder" :value="true" /> Yes
                                        </label>
                                        <label class="radio">
                                            <Field type="radio" :name="`lineItems[${idx}].includeHolder`" v-model="item.includeHolder" :value="false" /> No
                                        </label>
                                    </div>
                                    <div v-show="item.quantity <= 0" class="has-text-grey">N/A</div>
                                </td>
                                <td align="center">
                                    <div class="control mt-1" v-show="item.quantity > 0">
                                        <label class="radio">
                                            <Field type="radio" :name="`lineItems[${idx}].includeProbeAndLogger`" v-model="item.includeProbeAndLogger" :value="true" /> Yes
                                        </label>
                                        <label class="radio">
                                            <Field type="radio" :name="`lineItems[${idx}].includeProbeAndLogger`" v-model="item.includeProbeAndLogger" :value="false" /> No
                                        </label>
                                    </div>
                                    <div v-show="item.quantity <= 0" class="has-text-grey">N/A</div>
                                </td>
                                <td align="center" v-show="areVesselsFilledByLinde == false">
                                    <div class="control mt-1" v-show="item.quantity > 0">
                                        <label class="radio">
                                            <Field type="radio" :name="`lineItems[${idx}].includeGroundReturnLabel`" v-model="item.includeGroundReturnLabel" :value="true" /> Yes
                                        </label>
                                        <label class="radio">
                                            <Field type="radio" :name="`lineItems[${idx}].includeGroundReturnLabel`" v-model="item.includeGroundReturnLabel" :value="false" /> No
                                        </label>
                                    </div>
                                    <div v-show="item.quantity <= 0" class="has-text-grey">N/A</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br class="is-clearfix" />

                    <div v-for="item in shipperModels">
                        <div v-show="selectedShipperModelId == item.id">
                            <div class="columns">
                                <div class="column is-narrow" v-if="item.images.length > 0">
                                    <figure class="image">
                                        <img v-bind:src="`/api/ShipperModel/Image/${item.images[0].id}`" style="display: block; max-width: 200px; max-height: 200px; width: auto; height: auto;" />
                                    </figure>
                                </div>
                                <div class="column is-narrow">
                                    <div class="field">
                                        <span class="has-text-primary is-size-3">{{item.name}}</span>
                                    </div>

                                    <div class="field mt-2">
                                        <label class="label">Dry Ice Hold Time</label>
                                        {{item.dryIceHoldTime}} days
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Maximum Dry Ice Fill Weight</label>
                                        {{utils.formatNumber(item.maxDryIceFillWeight,2)}} {{(isMetric ? "kg" : "lb")}}
                                    </div>

                                    <div class="field">
                                        <label class="label">Package Dimensions</label>
                                        {{item.packageLength}} {{(isMetric ? "cm" : "in")}} x
                                        {{item.packageWidth}} {{(isMetric ? "cm" : "in")}} x
                                        {{item.packageHeight}} {{(isMetric ? "cm" : "in")}}
                                    </div>

                                    <div class="field">
                                        <label class="label">Package Weight</label>
                                        {{utils.formatNumber(item.totalPackageWeight,2)}} {{(isMetric ? "kg" : "lb")}}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-narrow">
                        <div class="buttons">
                            <button type="button" class="button is-warning" @click="saveAsDraft" v-bind:disabled="canSaveDraft == false"><i class="material-icons md-18 mr-1">save</i>Save as Draft</button>
                            <!--<button type="button" class="button is-info" @click="showRates"><i class="material-icons md-18 mr-1">calculate</i> Calculate Rates</button>-->
                        </div>
                    </div>
                    <div class="column">
                        <div class="buttons is-pulled-right">
                            <button type="button" class="button is-link mr-2" v-bind:disabled="canSubmit == false" @click="saveAndSubmit"><i class="material-icons md-18 mr-1">save</i>Submit Order</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
