<script setup>
    import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import axios from "axios";
    import { useToast } from "vue-toastification";
    import utils from '@/utils.js';
    import constants from '@/constants.js';
    const parameters = ref({ sortKey: "OrderDate", sortOrder: "Desc", pageSize: 50 });
    const rows = ref([]);
    const shipperModels = ref([]);
    const router = useRouter();
    const confirmBox = ref(null);

    const props = defineProps(["user", "demoMode"]);

    async function search(parms) {        
        if (parms) parameters.value = Object.assign(parameters.value, parms);
        const response = await axios.post(`/api/Order/PagedList`, parameters.value);
        if (response.status !== 200) return;
        rows.value = response.data.rows;
        parameters.value = response.data.parameters;
    }

    function censor(text, charLength) {
        if (props.demoMode == false) return text;
        return utils.censorText(text, charLength);
    }

    async function copy(item) {
        await confirmBox.value.open("Copy order?", "You are about to copy this record as a new order. The new order will be created as a \"Draft.\"  You can edit the order information and either save it as a \"Draft\" or \"Submit\" it for processing.  Do you want to proceed?");          
        const response = await axios.post(`/api/Order/Copy/${item.id}`);
        if (response.status !== 200) return;                
        await router.push({ name: 'EditOrder', params: { id: response.data } });
        useToast().success("The order has been copied successfully.");
    }

    async function remove(item)
    {
        await confirmBox.value.open("Delete Order?", `Are you sure you want to delete order #${item.number}?</br></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/Order/Delete/${item.id}`);
        if (response.status === 200) {
            search();
            useToast().success("The order was deleted successfully.");
        }
        else {
            useToast().warning(response.response.data.data);
        }
    }

    function canEdit(item) {
        if (!props.user) return false;
        if (props.user.securityRole == constants.SecurityRole.Admin) return true;
        return item.status != 'Submitted';
    }

    onMounted( () => {
        axios.get('/api/ShipperModel/List').then(x => shipperModels.value = x.data);
        search();
    })
</script>

<template>
    <div>
        <ConfirmModal ref="confirmBox"></ConfirmModal>

        <div class="columns">
            <div class="column">
                <p class="title"><span class="material-icons">shopping_cart</span> Orders</p>
            </div>
            <div class="column is-narrow">
                <router-link :to="{name: 'AddOrder'}" class="button is-link"><span class="material-icons">add</span> New Order</router-link>
            </div>
        </div>

        <div class="field">
            <div title="Search" class="control has-icons-right">
                <input class="input" type="text" placeholder="Search by Number, Customer or Contact" v-on:keyup.enter="search({pageNumber:1})" v-model="parameters.search">
                <span class="icon is-right"><i class="material-icons">search</i></span>
            </div>
        </div>

        <div class="table-container" id="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                <thead class="has-background-white-ter">
                    <tr>
                        <th rowspan="2" class="is-narrow"></th>
                        <th rowspan="2"><GridHeader :pageInfo="parameters" sort-key="Number" v-on:sorted="search">Number</GridHeader></th>
                        <th rowspan="2"><GridHeader :pageInfo="parameters" sort-key="Status" v-on:sorted="search">Status</GridHeader></th>
                        <th rowspan="2"><GridHeader :pageInfo="parameters" sort-key="OrderDate" v-on:sorted="search">Order Date</GridHeader></th>
                        <th rowspan="2"><GridHeader :pageInfo="parameters" sort-key="RequestedShipDate" v-on:sorted="search">Requested Ship Date</GridHeader></th>
                        <th rowspan="2"><GridHeader :pageInfo="parameters" sort-key="CustomerName" v-on:sorted="search">Customer</GridHeader></th>
                        <th rowspan="2"><GridHeader :pageInfo="parameters" sort-key="ContactName" v-on:sorted="search">Contact</GridHeader></th>
                        <th colspan="4" class="has-background-info-light"><span class="material-icons md-18 mr-1">local_shipping</span> First Destination</th>
                        <th v-bind:colspan="shipperModels.length" v-show="shipperModels.length > 0" class="has-background-info-light"><i class="material-icons md-18 mr-1">propane_tank</i>Shipper Quantities</th>
                    </tr>
                    <tr>
                        <th><GridHeader :pageInfo="parameters" sort-key="FirstDestinationAddressLine1" v-on:sorted="search">Address</GridHeader></th>
                        <th><GridHeader :pageInfo="parameters" sort-key="FirstDestinationCity" v-on:sorted="search">City</GridHeader></th>
                        <th><GridHeader :pageInfo="parameters" sort-key="FirstDestinationStateProvince" v-on:sorted="search">State</GridHeader></th>
                        <th><GridHeader :pageInfo="parameters" sort-key="FirstDestinationPostalCode" v-on:sorted="search">Postal Code</GridHeader></th>
                        <th v-for="item in shipperModels" class="nowrap" v-show="shipperModels.length > 0">
                            {{item.name}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in rows" v-bind:key="item.id">
                        <td class="nowrap">
                            <router-link :to="{ name : 'EditOrder', params: { id: item.id}}" title="Edit" class="mr-1" v-if="canEdit(item)">
                                <i class="material-icons md-18">edit</i>
                            </router-link>
                            <a :href="`/api/Order/Report/${item.id}`" target="_blank" class="mr-1" title="Order Summary Report"><i class="material-icons md-18">print</i></a>
                            <a @click="copy(item)" title="Copy as new order" class="mr-1"><i class="material-icons md-18">content_copy</i></a>
                            <a @click="remove(item)" title="Delete" class="ml-3"><i class="material-icons md-18">delete</i></a>
                        </td>
                        <td class="is-narrow">{{item.number}}</td>
                        <td class="is-narrow">
                            <div class="tag" v-bind:class="{'is-warning': item.status == 'Draft', 'is-success': item.status == 'Submitted'}">
                                {{item.status}}
                            </div>
                        </td>
                        <td class="is-narrow">{{utils.formatDate(item.orderDate)}}</td>
                        <td class="is-narrow">{{utils.formatDate(item.requestedShipDate)}}</td>
                        <td class="is-narrow">
                            <router-link v-if="user.securityRole == constants.SecurityRole.Admin" :to="{name: 'EditCustomer', params : { id: item.customerId}}">
                                {{censor(item.customerName)}}
                            </router-link>
                            <span v-else>
                                {{censor(item.customerName)}}
                            </span>
                        </td>
                        <td class="is-narrow">
                            <a v-bind:href="`mailto:${item.contactEmail}`">{{censor(item.contactName)}}</a>
                        </td>
                        <td class="is-narrow">{{censor(item.firstDestinationAddressLine1)}}</td>
                        <td class="is-narrow">{{item.firstDestinationCity}}</td>
                        <td class="is-narrow">{{item.firstDestinationStateProvince}}</td>
                        <td class="is-narrow">{{censor(item.firstDestinationPostalCode)}}</td>
                        <td v-for="model in shipperModels" class="is-narrow has-text-right" v-show="shipperModels.length > 0">
                            {{item.modelQuantities.find( x=> x.name === model.name)?.quantity}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <GridPager :pageInfo="parameters" v-on:paged="search"></GridPager>
    </div>    
</template>