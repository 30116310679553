<script setup>
    import { ref, onMounted } from 'vue';
    import { useToast } from 'vue-toastification';
    import axios from 'axios';
    import utils from '@/utils.js';

    const rows = ref([]);
    const shipperModels = ref([]);
    const showFilters = ref(false);
    const loading = ref(false);
    const pageInfo = ref({ sortKey: 'Created', sortOrder: 'Descending', pageSize: 25 });
    const confirmModal = ref(null);

    async function search(parms) {
        loading.value = true;
        try {
            if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);
            const response = await axios.post("/api/PreparationChecklist/PagedList", pageInfo.value);
            if (response.status !== 200) return;
            rows.value = response.data.rows;
            pageInfo.value = response.data.pageInfo;
        }
        finally {
            loading.value = false;
        }
    }

    async function deleteRow(row) {    
        if (row.submitted != null) {            
            useToast().warning(`This checklist has been submitted and can only be deleted by removing the shipment record in the dashboard associated with JDE order #${row.jdeOrderNumber}.`);
            return;
        }

        await confirmModal.value.open("Delete Preparation Checklist?", `Are you sure you want to delete this checklist created by ${row.authorFullName} on ${utils.formatDate(row.created)}?</br></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/PreparationChecklist/Delete/${row.id}`);        
        if (response.status !== 200) return;
        let index = rows.value.indexOf(row);
        rows.value.splice(index, 1);
        useToast().success("The checklist was deleted successfully.");
    }

    onMounted( async () => {
        const response = await axios.get("/api/ShipperModel/List");
        if (response.status !== 200 ) return;
        shipperModels.value = response.data;
        search();
    })
</script>

<template>
    <div>
        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons mr-1">local_shipping</i>Dry Ice Shipper Package Preparation Checklists</p>
            </div>
            <div class="column is-narrow">
                <router-link class="button" :to="{ name : 'AddPreparationChecklist' }">
                    <i class="material-icons">add</i>
                </router-link>
            </div>
        </div>

        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div v-if="loading">
            <div class="heading-md">Loading data, please wait...</div>
            <progress class="progress is-large is-info" max="100">60%</progress>
        </div>
        <div v-else>
            <div class="columns">
                <div class="column">
                    <div title="Search" class="control has-icons-right">
                        <input class="input" type="text" placeholder="Search" v-on:keyup.enter="search({pageNumber:1})" v-model="pageInfo.search">
                        <span class="icon is-right"><i class="material-icons">search</i></span>
                    </div>
                </div>
                <div class="column is-narrow is-hidden-mobile">
                    <button class="button" title="Hide/Show Filters" v-bind:class="{'is-light is-info' : showFilters == true}" v-on:click="showFilters =!showFilters">
                        <i class="material-icons">filter_list</i>
                    </button>
                </div>
            </div>

            <div class="columns">
                <div class="column is-narrow" v-show="showFilters == true">
                    <div class="field">
                        <label class="label">Shipper Model</label>
                        <div class="select control is-fullwidth is-size-7">
                            <select v-model="pageInfo.shipperModelId" @change="search({pageNumber:1})">
                                <option :value="null">Any</option>
                                <option v-for="option in shipperModels" v-bind:key="option.id" v-bind:value="option.id">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">State</label>
                        <div class="control is-size-7">
                            <input type="checkbox" v-model="pageInfo.showCompleted" @change="search({pageNumber:1})"> <span class="tag"> Submitted</span>
                        </div>
                        <div class="control is-size-7">
                            <input type="checkbox" v-model="pageInfo.showDrafts" @change="search({pageNumber:1})"> <span class="tag"> Draft</span>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="table-container">
                        <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                            <thead class="has-background-white-ter">
                                <tr>
                                    <th class="is-narrow"></th>
                                    <th class="nowrap"><GridHeader :pageInfo="pageInfo" sort-key="Created" v-on:sorted="search">Created</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="Author.LastName" v-on:sorted="search">Created By</GridHeader></th>
                                    <th class="nowrap"><GridHeader :pageInfo="pageInfo" sort-key="Submitted" v-on:sorted="search">Completed</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="Submitter.LastName" v-on:sorted="search">Completed By</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="JdeOrderNumber" v-on:sorted="search">Order #</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="CustomerAccountNumber" v-on:sorted="search">Customer<br />Account #</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="ShipperModel.Name" v-on:sorted="search">Shipper<br />Model</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="ShipperSentryNumber" v-on:sorted="search">Shipper<br />SENTRY #</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="FirstLegTrackingNumber" v-on:sorted="search">First Destination<br />Tracking</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="SecondLegTrackingNumber" v-on:sorted="search">Second Destination<br />Tracking</GridHeader></th>
                                    <th><GridHeader :pageInfo="pageInfo" sort-key="ReturnLegTrackingNumber" v-on:sorted="search">Return Destination<br />Tracking</GridHeader></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in rows" v-bind:key="item.id">
                                    <td class="nowrap">
                                        <router-link class="has-text-black" :to="{ name : 'ViewPreparationChecklist', params: { id: item.id}}" title="View"><i class="material-icons md-18">visibility</i></router-link>
                                        <router-link class="has-text-black mr-2" :to="{ name : 'EditPreparationChecklist', params: { id: item.id}}" title="Edit"><i class="material-icons md-18">edit</i></router-link>
                                        <a class="has-text-black" title="Delete" v-on:click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>

                                        <span class="ml-3">
                                            <span v-if="item.submitted" class="tag is-success">Submitted</span>
                                            <span v-else class="tag is-info">Draft</span>
                                        </span>
                                    </td>
                                    <td class="is-narrow" align="right">{{utils.formatDate(item.created)}}</td>
                                    <td>
                                        <span v-if="item.authorId">
                                            <router-link :to="{ name : 'EditUser', params: { id: item.authorId}}">{{item.authorFullName}}</router-link>
                                        </span>
                                    </td>
                                    <td class="is-narrow" align="right">
                                        <span v-if="item.submitted != null">
                                            {{utils.formatDate(item.submitted)}}
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="item.submitterId">
                                            <router-link :to="{ name : 'EditUser', params: { id: item.submitterId}}">{{item.submitterFullName}}</router-link>
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>{{item.jdeOrderNumber}}</td>
                                    <td>
                                        {{item.customerAccountNumber}}
                                        <span v-if="item.customer" class="ml-2">
                                            <router-link :to="{ name : 'EditCustomer', params: { id: item.customerId}}">{{item.customer.name}}</router-link>
                                        </span>
                                    </td>
                                    <td class="nowrap">
                                        {{item.shipperModelName}}
                                    </td>
                                    <td>{{item.shipperSentryNumber}}</td>
                                    <td class="nowrap"><span v-html="utils.formatShippingCarrier(item.firstLegCarrier)"></span> {{item.firstLegTrackingNumber}}</td>
                                    <td class="nowrap"><span v-html="utils.formatShippingCarrier(item.secondLegCarrier)"></span> {{item.secondLegTrackingNumber}}</td>
                                    <td class="nowrap"><span v-html="utils.formatShippingCarrier(item.returnLegCarrier)"></span> {{item.returnLegTrackingNumber}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
        </div>
    </div>
</template>
