<script setup>
    import { ref, watch, computed, onMounted } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import * as yup from 'yup';
    import axios from 'axios';
    import utils from '@/utils.js';
    import constants from '@/constants.js';
    import ShipmentTracker from '@/components/ShipmentTracker.vue';
    import ShippingLegModal from './ShippingLegModal.vue';
    import { useToast } from 'vue-toastification';

    const props = defineProps(["user", "demoMode"]);

    const schema = yup.object({
        comments: yup.string().nullable()
    });

    const model = ref({});
    const history = ref([]);
    const returnChecklistId = ref(null);
    const preparationChecklistId = ref(null);
    const router = useRouter();
    const route = useRoute();
    const tracking = ref(false);
    const modified = ref(false);
    const shippingLegModal = ref(null);

    async function onSubmit() {
        const response = await axios.post('/api/Shipment/Save', model.value);
        if (response.status !== 200) return;
        modified.value = false;
        model.value = response.data;
        await router.push({ name: 'Dashboard' });
        useToast().success("The shipment was saved successfully!");
    }

    async function updateTracking() {
        tracking.value = true;
        try {
            const response = await axios.post(`/api/Tracking/UpdateShipment/${model.value.id}`);
            if (response.status !== 200) return;
            model.value.firstLeg = response.data.firstLeg;
            model.value.secondLeg = response.data.secondLeg;
            model.value.returnLeg = response.data.returnLeg;
            model.value.state = response.data.state;
        }
        finally {
            tracking.value = false;
        }
    }

    async function loadShipment() {
        const response = await axios.get(`/api/Shipment/Get/${route.params.id || ''}`);

        if (response.status == 204) {
            useToast().info(`No shipment record found for id #${route.params.id}.`);
            return;
        }

        if (response.status !== 200) return;
        model.value = response.data.model;
        preparationChecklistId.value = response.data.preparationChecklistId;
        returnChecklistId.value = response.data.returnChecklistId;

        //watch for changes
        watch(
            () => model.value,
            () => modified.value = true,
            { deep: true }
        )

        loadHistory();
    }

    async function loadHistory() {
        const response = await axios.get(`/api/Shipment/History/${id.value || ''}`);
        if (response.status !== 200) return;
        history.value = response.data;
    }

    async function editSecondLeg() {
        await shippingLegModal.value.open(2);
        loadShipment();
    }

    function censor( text, charLength ) {
        if (props.demoMode == false) return text;
        return utils.censorText(text, charLength);        
    }

    const isAdmin = computed(() => props.user && props.user.securityRole == constants.SecurityRole.Admin);
    const isMetric = computed(() => props.user.unitSystem === constants.UnitSystem.Metric);
    const id = computed(() => route.params.id ? parseInt(route.params.id) : null);
    const url = computed(() => window.location.href);

    onMounted(() => loadShipment());
</script>

<template>
    <div>
        <ShippingLegModal ref="shippingLegModal" :shipmentId="id" />

        <div class="container">
            <div class="columns">
                <div class="column">
                    <h1 class="heading-lg"><span class="material-icons mr-1">checklist</span>Shipment</h1>
                </div>
                <div class="column is-narrow">
                    <router-link class="button is-pulled-right" :to="{ name : 'Dashboard' }" title="Return to Dashboard">
                        <i class="material-icons md-18">dashboard</i>
                    </router-link>
                </div>
            </div>

            <div v-if="model">
                <div class="columns is-marginless is-gapless mb-2">
                    <div class="column">
                        <p class="subtitle"><span class="material-icons mr-2">info</span> General Information</p>
                    </div>
                    <div class="column is-narrow" v-if="user.userType == constants.UserType.Employee">
                        <div class="buttons are-small">
                            <router-link class="button is-link" v-if="preparationChecklistId" :to="{ name : 'ViewPreparationChecklist', params: { id: preparationChecklistId}}" target="_blank">
                                <span class="material-icons md-18 mr-1">local_shipping</span> View Preparation Checklist
                            </router-link>

                            <router-link class="button is-link" v-if="returnChecklistId" :to="{ name : 'ViewReturnChecklist', params: { id: returnChecklistId}}" target="_blank">
                                <span class="material-icons md-18 mr-1">assignment_return</span> View Return Checklist
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="box">
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <label class="label mb-0">Status</label>
                                <div v-html="utils.formatShipmentState(model.state)"></div>
                            </div>

                            <div class="field">
                                <label class="label mb-0">Customer</label>
                                <div v-if="model.customerAccount">
                                    {{censor(model.customerAccount.customerName)}} 
                                    <span v-if="model.customerAccount.number">({{censor(model.customerAccount.number)}})</span>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Order #</label>
                                {{model.jdeOrderNumber}}
                            </div>

                            <div class="field">
                                <label class="label">Shipper Fill Date</label>
                                {{utils.formatDate(model.shipperFillDate)}}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="model.shipperModel">
                    <p class="subtitle mb-2"><span class="material-icons mr-2">propane_tank</span> Shipper Details</p>

                    <div class="box mb-2">
                        <div class="columns">
                            <div class="column is-4" v-if="model.shipperModel.images.length > 0">
                                <figure class="image">
                                    <img v-bind:src="`/api/ShipperModel/Image/${model.shipperModel.images[0].id}`" style="display: block; max-width: 200px; max-height: 200px; width: auto; height: auto;" />
                                </figure>
                            </div>

                            <div class="column is-4">
                                <div class="field">
                                    <label class="label">Model</label>
                                    {{model.shipperModel.name}}
                                </div>

                                <div class="field">
                                    <label class="label">Shipper Serial #</label>
                                    {{model.shipperSerialNumber}}
                                </div>

                                <div class="field">
                                    <label class="label">Shipper SENTRY #</label>
                                    {{model.shipperSentryNumber}}
                                </div>

                                <div class="field">
                                    <label class="label">Probe SENTRY #</label>
                                    {{model.probeSentryNumber}}
                                </div>
                            </div>

                            <div class="column is-4">
                                <div class="field">
                                    <label class="label">Dry Ice Hold Time</label>
                                    {{model.shipperModel.dryIceHoldTime}} days
                                </div>

                                <div class="field">
                                    <label class="label">Maximum Dry Ice Fill Weight</label>
                                    {{utils.formatNumber(model.shipperModel.maxDryIceFillWeight,2)}} {{(isMetric ? "kg" : "lb")}}
                                </div>

                                <div class="field">
                                    <label class="label">Package Dimensions</label>
                                    {{model.shipperModel.packageLength}} {{(isMetric ? "cm" : "in")}} x
                                    {{model.shipperModel.packageWidth}} {{(isMetric ? "cm" : "in")}} x
                                    {{model.shipperModel.packageHeight}} {{(isMetric ? "cm" : "in")}}
                                </div>

                                <div class="field">
                                    <label class="label">Package Weight</label>
                                    {{utils.formatNumber(model.shipperModel.totalPackageWeight,2)}} {{(isMetric ? "kg" : "lb")}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns is-gapless is-marginless mt-3 mb-3">
                    <div class="column">
                        <p class="subtitle"><span class="material-icons mr-2">local_shipping</span> Tracking Details</p>
                    </div>
                    <div class="column is-narrow">
                        <div class="buttons are-small">
                            <button class="button is-primary" @click="editSecondLeg()" v-if="model.state < constants.ShipmentState.ReturnDelayed">Edit Second Destination</button>
                            <button class="button is-success" v-bind:disabled="tracking == true" @click="updateTracking"><span class="material-icons md-18">refresh</span> Update All</button>
                        </div>
                    </div>
                </div>

                <ShipmentTracker ref="firstLeg" v-model="model.firstLeg" :demoMode="demoMode"><span class="material-icons">north_east</span> First</ShipmentTracker>
                <ShipmentTracker ref="secondLeg" v-model="model.secondLeg" :demoMode="demoMode"><span class="material-icons">timeline</span> Second </ShipmentTracker>
                <ShipmentTracker ref="returnLeg" v-model="model.returnLeg" :demoMode="demoMode"><span class="material-icons">south_west</span> Return</ShipmentTracker>


                <p class="subtitle mb-2"><span class="material-icons mr-2">history</span> History</p>

                <div class="box">
                    <div v-if="history.length == 0">
                        No history available.
                    </div>
                    <div v-else>
                        <table class="table is-fullwidth is-striped is-bordered">
                            <tbody>
                                <tr v-for="item in history" v-bind:key="item.id">
                                    <td>
                                        <i class="material-icons md-18 mr-2">event_note</i>
                                        <span class="has-text-black">{{utils.formatDateTime(item.statusDate)}}</span> - Status changed
                                        <span v-if="item.oldStatus > 0">from <span v-html="utils.formatShipmentState(item.oldStatus)"></span></span>
                                        to <span v-html="utils.formatShipmentState(item.newStatus)"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <Form @submit="onSubmit" :validation-schema="schema" v-show="isAdmin">
                    <div class="field">
                        <label class="label">Comments</label>
                        <div class="control">
                            <Field class="textarea" as="textarea" name="comments" placeholder="Comments" v-model="model.comments" />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="buttons is-pulled-right">
                                <button type="submit" class="button is-info"><i class="material-icons md-18 mr-1">save</i>Save</button>
                                <router-link class="button" :to="{ name : 'Dashboard' }"><i class="material-icons md-18 mr-1">cancel</i>Cancel</router-link>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>

            <a v-if="id" title="Contact Us"
               v-bind:href="'mailto:?body=I%20have%20a%20question%20regarding%20this%20shipment:%20' + url + '&amp;subject=Seccurus%20Shipment%20Question'">
                For questions regarding this shipment, please contact us
            </a>
        </div>
    </div>
</template>

