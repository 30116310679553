<script setup>
    import { computed } from 'vue';
    import axios from 'axios';

    const emit = defineEmits(["upload", "remove"]);

    const props = defineProps({
        category: {
            type: String,
            required: true
        },
        files: {
            type: Array,
            default: () => []
        },
        itemsPerRow: {
            type: Number,
            default: 3
        },
        canUpload: {
            type: Boolean,
            default: true
        }
    });

    async function upload(event) {        
        let formData = new FormData();
        let files = event.target.files;

        for (let i = 0; i < files.length; i++)
            formData.append('file', files[i]);

        const response = await axios.post('/api/ReturnChecklist/Upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });        
        if (response.status !== 200) return;
        response.data.forEach((x, index) => {
            x.objectUri = URL.createObjectURL(files[index]);
            x.category = props.category;
            emit("upload", x);
        });
    }

    const remove = (item) => emit("remove", item);
    const getFiles = (row) => props.files.slice((row - 1) * props.itemsPerRow, row * props.itemsPerRow);

    const rowCount = computed(() => {
        let result = Math.ceil(props.files.length / props.itemsPerRow);
        if (props.files.length == (result * props.itemsPerRow)) result += 1;
        return result;            
    });
</script>

<template>
    <div class="attachment-block"> 
        <div class="file is-link block">
            <label class="file-label" v-show="canUpload == true">
                <input class="file-input" type="file" accept=".gif,.jpg,.jpeg,.png" @change="upload($event)" ref="fileInput"/>
                <span class="file-cta">
                    <span class="file-icon">
                        <i class="material-icons">photo</i>
                    </span>
                    <span class="file-label">
                        Add Photo(s)
                    </span>
                </span>
            </label>
        </div>

        <div v-for="i in rowCount" class="tile is-ancestor" :key="i">
            <div class="tile is-parent is-4" v-for="(file,idx) in getFiles(i)" v-bind:key="idx">
                <div class="tile is-child">
                    <div class="picture-box p-2">
                        <figure class="image mb-1">
                            <img v-bind:src="file.objectUri || `/api/ReturnChecklist/FileThumbnail/${file.id}`" />
                        </figure>

                        <input type="text" class="input is-small pt-1" v-model="file.description" placeholder="description" :title="file.description" />
                    </div>

                    <i class="material-icons cursor-pointer has-text-danger-dark mt-2" @click="remove(file)" title="Click to remove picture!">remove_circle_outline</i>                                      
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .picture-box {
        border: solid 1px #c0c0c0;
    }
</style>