<template>
    <div>
        <div class="columns has-text-link">
            <div class="column has-text-centered">
                <i class="material-icons has-text-danger large-icon">security</i>                
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <p class="has-text-centered">
                    Access Denied! Please check with a security administrator.                    
                </p>
                <br />
                <p class="has-text-centered">
                    Let's go <router-link to="/">Home</router-link> and try from there.
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .large-icon {
        font-size: 256px;
    }
</style>