<script setup>
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import { useToast } from 'vue-toastification';

    const rows = ref([]);
    const pageInfo = ref({ sortKey: 'Name', pageSize: 25 });
    const confirmModal = ref(null);

    async function search(parms) {
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);

        const response = await axios.post("/api/Customer/PagedList", pageInfo.value);
        if (response.status !== 200) return;
        rows.value = response.data.rows;
        pageInfo.value = response.data.pageInfo;
    }

    async function deleteRow(row) {
        await confirmModal.value.open("Confirm Delete", `Are you sure you want to delete the customer "${row.name}"?</br></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/Customer/Delete/${row.id}`);

        if (response.status !== 200) {
            useToast().error("Unable to delete customer. Perhaps the record is in use?");
            return;
        }

        rows.value.splice(rows.value.indexOf(row), 1);
        useToast().success("The customer was deleted successfully.");
    }

    onMounted(() => search());
</script>

<template>
    <div class="container">
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">business</i> Customers</p>
            </div>
            <div class="column is-narrow">
                <router-link class="button" :to="{ name : 'AddCustomer' }">
                    <i class="material-icons">add</i>
                </router-link>
            </div>
        </div>
        
        <div class="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow">
                <thead class="has-background-white-ter">
                    <tr>
                        <th class="is-narrow"></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="Name" v-on:sorted="search">Name</GridHeader></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="CustomerServiceCenter.Name" v-on:sorted="search">Customer Service Center</GridHeader></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in rows" v-bind:key="item.id">
                        <td>
                            <div class="nowrap">
                                <router-link class="has-text-black" :to="{ name : 'EditCustomer', params: { id: item.id}}" title="Edit"><i class="material-icons md-18">edit</i></router-link>
                                <a class="has-text-black" title="Delete" v-on:click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>
                            </div>
                        </td>
                        <td class="nowrap">
                            {{item.name}}
                        </td>
                        <td class="nowrap">
                            {{item.customerServiceCenterName}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
    </div>
</template>

