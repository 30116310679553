<script setup>
    import { ref, computed } from 'vue';
    import * as yup from 'yup';
    import axios from 'axios';

    const schema = yup.object({
        shippingCarrier: yup.string().nullable().label("carrier").required(),
        trackingNumber: yup.string().nullable().label("tracking #").required(),
        deliveryCity: yup.string().nullable().label("city").required(),
        deliveryStateProvince: yup.string().nullable().label("state / province").required()
    });

    const legNumber = ref(null);
    const model = ref(null);
    const stateProvinces = ref([]);
    const carriers = ref([]);
    const form = ref(null);
    let resolver = null;

    const props = defineProps({
        shipmentId: {
            type: Number,
            default: 0
        }
    });

    async function onSubmit() {
        try {
            await axios.post('/api/Shipment/SaveLeg', model.value);            
            resolver();
        }
        finally {
            toggleDisplay(false);
        }        
    }

    async function loadLookups() {
        await Promise.all([
            axios.get('/api/Tracking/StateProvinces/usa').then(x => stateProvinces.value = x.data),
            axios.get('/api/Tracking/ListCarriers').then(x => carriers.value = x.data)
        ]);
    }

    async function load() {
        return new Promise(async (resolve, reject) => {
            const response = await axios.get(`/api/Shipment/GetLeg/${props.shipmentId.value}/${legNumber.value}`);
            if (response.status !== 200) return;
            model.value = response.data;
            form.value.resetForm();
            resolve();
        })        
    }

    async function open(legNumber) { 
        model.value = null;
        legNumber.value = legNumber;
        await load();

        return new Promise((resolve, reject) => {
            resolver = resolve;
            toggleDisplay(true);
        });        
    }

    const legName = computed(() => {
        if (legNumber.value == 1) return "First";
        if (legNumber.value == 2) return "Second";
        if (legNumber.value == 3) return "Return";
        return "?";
    });

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";

    defineExpose({
        open
    })
</script>

<template>
    <div class="modal" ref="root">
        <Form @submit="onSubmit" :validation-schema="schema" @keydown.enter="$event.preventDefault()" ref="form">
            <div class="modal-background"></div>
            <div class="modal-card mt-6">

                <header class="modal-card-head">
                    <p class="modal-card-title">{{legName}} Destination Shipping Leg</p>
                    <button type="button" class="delete" aria-label="close" v-on:click="toggleDisplay(false)" title="close"></button>
                </header>
                <section class="modal-card-body">                    
                    <div v-if="model">
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">Shipping Carrier</label>
                                    <div class="select control is-fullwidth">
                                        <Field as="select" name="shippingCarrier" v-model="model.shippingCarrier">
                                            <option :value="null"></option>
                                            <option v-for="(option,idx) in carriers" v-bind:key="idx" v-bind:value="option.name">
                                                {{option.otherName}} ({{option.name}})
                                            </option>
                                        </Field>
                                    </div>
                                    <ErrorMessage class="errors" name="shippingCarrier" />
                                </div>
                                <div class="field">
                                    <label class="label">Tracking Number</label>
                                    <div class="control has-icons-right" title="scan barcode">
                                        <Field class="input" type="text" name="trackingNumber" v-model="model.trackingNumber" />
                                        <span class="icon is-small is-right">
                                            <span class="material-icons md-18">barcode_reader</span>
                                        </span>
                                    </div>
                                    <ErrorMessage class="errors" name="trackingNumber" />
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">City</label>
                                    <Field class="input" type="text" name="deliveryCity" v-model="model.deliveryCity" />
                                    <ErrorMessage class="errors" name="deliveryCity" />
                                </div>
                                <div class="field">
                                    <label class="label">State/Province</label>
                                    <div class="select control is-fullwidth">
                                        <Field as="select" name="deliveryStateProvince" v-model="model.deliveryStateProvince">
                                            <option :value="null"></option>
                                            <option v-for="option in stateProvinces" v-bind:key="option.abbreviation" v-bind:value="option.abbreviation">
                                                {{option.abbreviation}} - {{option.name}}
                                            </option>
                                        </Field>
                                    </div>
                                    <ErrorMessage class="errors" name="deliveryStateProvince" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot is-pulled-right">
                    <div class="buttons is-pulled-right">
                        <button type="submit" class="button is-success">OK</button>
                        <button type="button" class="button" @click="toggleDisplay(false)">Cancel</button>
                    </div>
                </footer>
            </div>
        </Form>
    </div>
</template>

