<script setup>
    import { ref, computed } from 'vue';
    import axios from 'axios';
    import * as yup from 'yup';
    import utils from '@/utils.js';

    const schema = yup.object({
        shipperModelId: yup.number().nullable().required().label("model"),
        tareWeight: yup.number().nullable().required()
            .test(
                "weight",
                "Entered weight is outside the possible range. Check your measurement and re-enter the value. If this error message continues, ask for help.", function (value) {

                    let minElement = document.getElementById("minEmptyWeightWithoutCap");
                    let maxElement = document.getElementById("maxEmptyWeightWithoutCap");

                    if (value != null && minElement != null && maxElement != null) {
                        let weight = parseFloat(value);
                        let minWeight = parseFloat(minElement.value);
                        let maxWeight = parseFloat(maxElement.value);

                        if (weight < minWeight || weight > maxWeight) {
                            return false;
                        }
                    }

                    return true;
                }).label("Tare weight"),
        serialNumber: yup.string().nullable().required().label("Serial #"),
        sentryNumber: yup.string().nullable().required().label("SENTRY number"),
        isConditionAcceptable: yup.boolean().required().nullable().oneOf([true, false], 'Must be answered yes or no.').label("Shipper condition"),
        conditionObservations: yup.string().nullable()
            .when(['isConditionAcceptable'], { is: (x) => x == false, then: () => yup.string().nullable().required() })
            .label("Shipper observations"),
    });

    const code = ref("");
    const shipperModels = ref([]);
    const model = ref(null);
    const root = ref(null);
    let resolver = null;

    async function load() {
        model.value = null;

        const response = await axios.get('/api/ShipperModel/List');
        if (response.status !== 200) return;
        shipperModels.value = response.data;

        const registration = await axios.get(`/api/Shipper/GetRegistration/${code.value}`);
        if (registration.status != 200) return;
        model.value = registration.data;
    }

    async function onSubmit() {
        const response = await axios.post('/api/Shipper/Register', model.value);
        if (response.status !== 200) return;
        toggleDisplay(false);
        resolver(response.data);
    }

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";
        
    function open(codeValue) {
        code.value = codeValue;
        load();

        return new Promise((resolve) => {            
            resolver = resolve;
            toggleDisplay(true);
        })
    }

    const shipperModel = computed(() => {        
        if (model.value == null) return null;
        let matches = shipperModels.value.filter(x => x.id == model.value.shipperModelId);
        return matches.length == 1 ? matches[0] : null;
    });

    defineExpose({
        open
    })
</script>

<template>
    <div class="modal" ref="root">
        <Form @submit="onSubmit" :validation-schema="schema" @keydown.enter="$event.preventDefault()" @keydown.escape="toggleDisplay(false)">            
            <input type="hidden" id="minEmptyWeightWithoutCap" :value="shipperModel == null ? null : shipperModel.minEmptyWeightWithoutCap" />
            <input type="hidden" id="maxEmptyWeightWithoutCap" :value="shipperModel == null ? null : shipperModel.maxEmptyWeightWithoutCap" />

            <div class="modal-background"></div>
            <div class="modal-card mt-2">
                <header class="modal-card-head">
                    <p class="modal-card-title"><span class="material-icons">app_registration</span> Register Shipper</p>
                    <button type="button" class="delete" aria-label="close" v-on:click="toggleDisplay(false)" title="close"></button>
                </header>
                <section class="modal-card-body">     
                    <div v-if="model != null">
                        <div>
                            <div class="field is-grouped">
                                <div class="field">
                                    <span class="label">Serial #</span>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="Serial #" name="serialNumber" v-model="model.serialNumber" disabled="disabled" />
                                    </div>
                                    <ErrorMessage class="errors" name="serialNumber" />
                                </div>

                                <div class="field">
                                    <span class="label">SENTRY Number</span>
                                    <div class="control has-icons-right" title="scan barcode">
                                        <Field class="input" type="text" placeholder="Sentry #" name="sentryNumber" v-model="model.sentryNumber" />
                                        <span class="icon is-small is-right">
                                            <span class="material-icons md-18">barcode_reader</span>
                                        </span>
                                    </div>
                                    <ErrorMessage class="errors" name="sentryNumber" />
                                </div>
                            </div>

                            <div class="field is-grouped">
                                <div class="field">
                                    <label class="label">Shipper Model</label>
                                    <div class="select control is-expanded">
                                        <Field as="select" name="shipperModelId" v-model="model.shipperModelId">
                                            <option :value="null"></option>
                                            <option v-for="option in shipperModels" v-bind:key="option.id" v-bind:value="option.id">
                                                {{option.name}}
                                            </option>
                                        </Field>
                                        <ErrorMessage class="errors is-block" name="shipperModelId" />
                                    </div>
                                </div>

                                <div class="field">
                                    <label class="label">
                                        Measure tare weight for completely empty shipper without cap.
                                        <span class="material-icons md-18 ml-2 cursor-help" title="Please enter a number with 2 decimal points">help</span>
                                    </label>
                                    <UnitEditor name="tareWeight" v-model="model.tareWeight">lbs</UnitEditor>
                                    <ErrorMessage class="errors is-clearfix mb-2" name="tareWeight" />
                                </div>
                            </div>

                            <div class="box has-background-info-light" v-if="shipperModel">
                                <div class="columns">
                                    <div class="column is-narrow is-hidden-mobile" v-if="shipperModel && shipperModel.images.length > 0">
                                        <figure class="image">
                                            <img v-bind:src="`/api/ShipperModel/Image/${shipperModel.images[0].id}`" style="max-width: 100%; height: auto; max-height: 100px" />
                                        </figure>
                                    </div>
                                    <div class="column">
                                        <p class="heading-md">{{shipperModel.name}}</p>
                                        <p>Tare weight requirements for empty shipper without cap:</p>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Minimum</th>
                                                    <th>Maximum</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width="100px">{{utils.formatNumber(shipperModel.minEmptyWeightWithoutCap,2)}} lbs</td>
                                                    <td>{{utils.formatNumber(shipperModel.maxEmptyWeightWithoutCap,2)}} lbs</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label mt-2">Shipper physical condition acceptable for transfer to end-user? (Inspect shipper vessel interior and exterior, shipper cap, and product canister/rack for damage, defects, or poor workmanship.)</label>
                                <div class="control">
                                    <label class="radio">
                                        <Field type="radio" name="isConditionAcceptable" :value="true" v-model="model.isConditionAcceptable" /> Yes
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="isConditionAcceptable" :value="false" v-model="model.isConditionAcceptable" /> No
                                    </label>
                                </div>
                                <ErrorMessage class="errors" name="isConditionAcceptable" />
                            </div>

                            <div class="field" v-show="model.isConditionAcceptable == false">
                                <div>
                                    <label class="label">Document observations.<span class="has-text-danger md-16 ml-1">*</span></label>

                                </div>
                                <div class="control">
                                    <Field class="textarea" as="textarea" name="conditionObservations" v-model="model.conditionObservations" />
                                </div>
                                <ErrorMessage class="errors" name="conditionObservations" />
                            </div>
                        </div>

                        <div class="notification is-light"
                             v-bind:class="model.isConditionAcceptable == true ? 'is-info' : 'is-warning'"
                             v-show="model != null && model.isConditionAcceptable != null">
                            <div class="columns">
                                <div class="column is-narrow">
                                    <span class="material-icons md-36">{{(model.isConditionAcceptable == true ? "front_hand" : "info")}}</span>
                                </div>
                                <div class="column">
                                    <span v-if="model.isConditionAcceptable == true">
                                        Shipper dry ice sublimation rate needs to be measured to determine whether shipper can be sent to end-user, needs vacuum maintenance, or should
                                        be replaced under manufacturer warranty.  Fill shipper with dry ice and allow shipper to cool down overnight (at at least 8 hours).  When ready to
                                        proceed, select Process Shipper from the Dashboard and scan the shipper QR code or SENTRY barcode.
                                    </span>
                                    <span v-show="model.isConditionAcceptable == false">
                                        Replace shipper under manufacturer warranty.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot is-justify-content-right">
                    <div class="buttons">
                        <div v-if="model != null" class="mr-1">
                            <button type="submit" class="button" 
                                    v-bind:class="{                                         
                                        'is-warning' : model.isConditionAcceptable == false, 
                                        'is-success' : model.isConditionAcceptable == true  }">
                                I Acknowledge
                            </button>
                        </div>
                        <button type="button" class="button" @click="toggleDisplay(false)">Cancel</button>
                    </div>
                </footer>
            </div>
        </Form>
    </div>
</template>