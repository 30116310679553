<script setup>
    import * as yup from 'yup';
    import AccountPicker from "../Customers/AccountPicker.vue";
    import { ref, watch, computed, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import axios from 'axios';
    import utils from '@/utils.js';
    import { useToast } from 'vue-toastification';
    import { UserType, UserState, UnitSystem, NotificationLevel, SecurityRole, MessageFormat } from '@/constants.js';

    const props = defineProps(["user", "demoMode"]);

    const schema = yup.object({
        firstName: yup.string().typeError('First name is required.').required("First name is required."),
        lastName: yup.string().typeError('Last name is required.').required("Last name is required."),
        email: yup.string().typeError('Email is required.').email("Email must be valid"),
    });

    const facilities = ref([]);
    const model = ref();    
    const route = useRoute();
    const router = useRouter();
    const accountPicker = ref(null);
    const confirmModal = ref(null);

    async function onSubmit() {
        const response = await axios.post('/api/Users/Save', model.value);
        if (response.status !== 200) return;
        
        model.value = response.data;

        if (model.value.id == props.user.id) {                        
            alert("Your user profile has been updated successfully. The page will now reload.");
            router.go(0);                        
            return;
        }

        await router.push({ name: 'Users' });
        useToast().success("User saved successfully.");
    }

    async function addAccount() {        
        let selectedIds = model.value.customerAccounts.map(x => x.customerAccount.id);
        const items = await accountPicker.value.open(selectedIds);
        let toDelete = model.value.customerAccounts.filter(x => items.indexOf(x.customerAccountId) < 0);
        for (var x = 0; x < toDelete.length; x++) {
            let match = model.value.customerAccounts.filter(x => x.customerAccountId == toDelete[x])[0];
            let index = model.value.customerAccounts.indexOf(match);
            model.value.customerAccounts.splice(index, 1);
        }

        for (let i = 0; i < items.length; i++) {
            let exists = model.value.customerAccounts.filter(x => x.customerAccountId == items[i]);
            if (exists.length > 0) continue;

            const response = await axios.get(`/api/Customer/Account/${items[i]}`);
            if (response.status !== 200) continue;

            let record = {
                id: 0,
                customerName: response.data.customerName,
                userId: props.user.id,
                customerAccountId: response.data.id,
                customerAccount: {
                    id: response.data.id,
                    name: response.data.name,
                    number: response.data.number
                }
            };
            model.value.customerAccounts.push(record);
        }
    }

    async function deleteAccount(item) {
        await confirmModal.value.open("Delete Customer Account?", `Are you sure you want to delete account named "${item.customerAccount.name}"?`);
        let index = model.value.customerAccounts.indexOf(item);
        model.value.customerAccounts.splice(index, 1);
    }

    function switchUserType(type) {
        if (type == UserType.Customer) {
            model.value.username = null;
            model.value.facilityId = null;

            if (model.value.userType == UserType.Employee)
                model.value.customer = null;

            if (model.value.id <= 0) {
                model.value.state = UserState.Pending;
            }
        }

        if (type == UserType.Employee) {
            model.value.accountNumber = null;

            if (model.value.id <= 0) {
                model.value.state = UserState.Active;
            }
        }

        model.value.userType = type;
    }

    const hasFormat = (flag) => (model.value.messageFormat & flag) == flag;

    function setFormat(event) {
        let checkbox = event.currentTarget;
        model.value.messageFormat += parseInt(checkbox.value) * (checkbox.checked ? 1 : -1);
    }

    const canEdit = computed(() => {
        if (!props.user || !model.value) return false;
        return props.user.id !== model.value.id;
    });

    function censor(text, charLength) {
        if (props.demoMode == false) return text;
        return utils.censorText(text, charLength);
    }

    onMounted( async () => {        
        await axios.get('/api/Facility/List').then(x => facilities.value = x.data);

        const response = await axios.get(`/api/Users/Get/${route.params.id || ""}`);

        if (response.status === 204) {
            useToast().info(`No user account found with id #${route.params.id}.`);
            return;
        }

        if (response.status === 200) {
            model.value = response.data;
            if (model.value.userType == 0) model.value.userType = UserType.Employee;
            switchUserType(model.value.userType);

            if (canEdit && model.value.id > 0 && model.value.state == UserState.Pending) {
                useToast().info("This user account is in the pending state. Before this user can access the system please review their information. To activate the account set the State as 'Active', add them to the applicable accounts and then save the record. Upon activation please send them an email to notify them that their account is ready to use.")
            }
        }
    });
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal" />
        <AccountPicker ref="accountPicker" />

        <Form @submit="onSubmit" :validation-schema="schema">
            <div class="container" v-if="model">
                <div class="columns">
                    <div class="column">
                        <p class="title">
                            <i class="material-icons">group</i>&nbsp; {{$route.params.id ? "Edit" : "Add"}} {{(model.userType == UserType.Customer ? "Customer" : "Employee")}}
                        </p>
                    </div>
                    <div class="column">
                        <div class="buttons is-pulled-right">
                            <router-link v-if="model" class="button" :to="{ name : 'ChangePassword' }" title="Change Password">
                                <i class="material-icons md-18">key</i>
                            </router-link>
                            <router-link class="button" :to="{ name : 'Users' }" title="Return to List">
                                <i class="material-icons md-18">keyboard_backspace</i>
                            </router-link>
                        </div>
                    </div>
                </div>


                {{model.location}}
                <div>
                    <p class="subtitle mb-2"><span class="material-icons mr-2">info</span> General Information</p>

                    <div class="box">
                        <div class="notification p-2 is-info is-light" v-if="model.notes">
                            {{model.notes}}
                        </div>

                        <div class="field" v-if="model.id <= 0">
                            <div class="control">
                                <label class="radio">
                                    <input type="radio" name="userType"
                                           v-bind:checked="model.userType == UserType.Customer"
                                           @click="switchUserType(UserType.Customer)"> Customer
                                </label>
                                <label class="radio">
                                    <input type="radio" name="userType"
                                           v-bind:checked="model.userType == UserType.Employee"
                                           @click="switchUserType(UserType.Employee)"> Employee
                                </label>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">First Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="First Name" name="firstName" v-model="model.firstName" />
                                        <ErrorMessage class="errors" name="firstName" />
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label">Last Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="Last Name" name="lastName" v-model="model.lastName" />
                                        <ErrorMessage class="errors" name="lastName" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">Email</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="Email" name="email" v-model="model.email" />
                                        <ErrorMessage class="errors" name="email" />
                                    </div>
                                </div>

                                <div class="field">
                                    <label class="label">Mobile Phone Number <span title="Please use E.164 formatting for your phone number. E.164 is an international standard, titled The international public telecommunication numbering plan, that defines a numbering plan for the worldwide public switched telephone network and some other data networks. E.164 defines a general format for international telephone numbers." class="ml-1 material-icons md-18 has-text-info-dark cursor-help">help</span></label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="e.g +17168675309" name="mobilePhoneNumber" v-model="model.mobilePhoneNumber" />
                                        <ErrorMessage class="errors" name="mobilePhoneNumber" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal" v-show="model.userType == UserType.Employee">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">User Principal Name (UPN) <span title="Only applies to employee accounts. A User Principal Name (UPN) is a username and domain in an email address format. In a UPN, the username is followed by a separator &quot;at sign&quot; (@) followed by the active directory's internet domain. An example UPN is michael.ceranski@linde-le.com" class="m-l1 material-icons md-18 has-text-info-dark cursor-help">help</span></label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="Username" name="username" v-model="model.username" />
                                        <ErrorMessage class="errors" name="username" />
                                    </div>
                                </div>

                                <div class="field">
                                    <label class="label">Facility <span title="This will be used as the default facility when creating a new preparation checklist" class="ml-1 material-icons md-18 has-text-info-dark cursor-help">help</span></label>
                                    <div class="select control is-fullwidth">
                                        <Field as="select" name="facilityId" v-model="model.facilityId">
                                            <option :value="null"></option>
                                            <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                                {{option.name}}
                                            </option>
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="columns">
                            <div class="column is-narrow">
                                <label class="label mb-3">Message Formats</label>
                                <div class="control" v-if="user.id === model.id || user.securityRole === SecurityRole.Admin">                                    
                                    <label class="checkbox mr-1">
                                        <input type="checkbox" :value="MessageFormat.Email" v-on:click="setFormat($event)" v-bind:checked="hasFormat(MessageFormat.Email)" />
                                        <span class="ml-1">Email</span>
                                    </label>
                                    <label class="checkbox mr-1">
                                        <input type="checkbox" :value="MessageFormat.SMS" v-on:click="setFormat($event)" v-bind:checked="hasFormat(MessageFormat.SMS)" />
                                        <span class="ml-1">SMS</span>
                                    </label>
                                </div>
                                <div class="tags" v-else>
                                    <span v-if="hasFormat(MessageFormat.Email)" class="tag">Email</span>
                                    <span v-if="hasFormat(MessageFormat.SMS)" class="tag">SMS</span>
                                </div>
                            </div>
                            <div class="column is-narrow">
                                <label class="label mb-3">Shipping Notifications</label>
                                <div class="control" v-if="user.id === model.id || user.securityRole === SecurityRole.Admin">
                                    <label class="radio">
                                        <input type="radio" name="notificationLevel" v-bind:value="NotificationLevel.All" v-model="model.notificationLevel">
                                        <span class="ml-1 has-text-success">All</span>
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="notificationLevel" v-bind:value="NotificationLevel.Delays" v-model="model.notificationLevel">
                                        <span class="ml-1 has-text-warning-dark">Delays Only</span>
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="notificationLevel" v-bind:value="NotificationLevel.None" v-model="model.notificationLevel">
                                        <span class="ml-1 has-text-danger-dark">None</span>
                                    </label>
                                </div>
                                <div class="control" v-else>
                                    <span v-if="model.notificationLevel === NotificationLevel.All" class="has-text-warning">All</span>
                                    <span v-if="model.notificationLevel === NotificationLevel.Delays" class="has-text-success">Delays Only</span>
                                    <span v-if="model.notificationLevel === NotificationLevel.None" class="has-text-danger">None</span>
                                </div>
                            </div>
                            <div class="column is-narrow">
                                <label class="label">Unit System</label>
                                <div class="control">
                                    <label class="radio">
                                        <input type="radio" name="unitSystem" :value="UnitSystem.Metric" v-model.number="model.unitSystem"> Metric
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="unitSystem" :value="UnitSystem.Imperial" v-model.number="model.unitSystem"> Imperial
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <label class="label">Role</label>
                                <div class="control" v-if="canEdit">
                                    <label class="radio">
                                        <input type="radio" name="securityRole" v-bind:value="SecurityRole.User" v-model.number="model.securityRole"> User
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="securityRole" v-bind:value="SecurityRole.Admin" v-model.number="model.securityRole"> Admin
                                    </label>
                                </div>
                                <div class="control" v-if="!canEdit">
                                    <span v-if="model.securityRole === SecurityRole.User">User</span>
                                    <span v-if="model.securityRole === SecurityRole.Admin" class="has-text-danger">Admin</span>
                                </div>
                            </div>
                            <div class="column">
                                <label class="label">State <span title="An account must be active for a user to login. Newly requested accounts are in the pending state." class="m-1 material-icons md-18 has-text-info-dark cursor-help md-18">help</span></label>
                                <div class="control" v-if="canEdit">
                                    <label class="radio">
                                        <input type="radio" name="state" v-bind:value="UserState.Pending" v-model.number="model.state"> <span class="has-text-warning-dark">Pending</span>
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="state" v-bind:value="UserState.Active" v-model.number="model.state"> <span class="has-text-success">Active</span>
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="state" v-bind:value="UserState.Disabled" v-model.number="model.state"> <span class="has-text-danger-dark">Disabled</span>
                                    </label>
                                </div>
                                <div class="control" v-else>
                                    <span v-if="model.state === UserState.Pending" class="has-text-warning">User</span>
                                    <span v-if="model.state === UserState.Active" class="has-text-success">Active</span>
                                    <span v-if="model.state === UserState.Disabled" class="has-text-danger">Disabled</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="columns is-marginless is-gapless mb-2">
                        <div class="column">
                            <p class="subtitle mb-1"><span class="material-icons mr-2">info</span> Customer Accounts</p>
                        </div>
                        <div class="column is-narrow">
                            <div class="buttons are-small">                                                                  
                                <a v-if="user.securityRole === SecurityRole.Admin" class="button" @click="addAccount()"><i class="material-icons">add</i></a>
                            </div>
                        </div>
                    </div>

                    <div class="box">
                        <p class="has-text-info-dark pb-1">*Admins implicitly have access to all accounts.</p>

                        <table class="table is-hoverable is-bordered is-narrow is-fullwidth">
                            <thead class="has-background-white-ter">
                                <tr>
                                    <th v-if="user.securityRole === SecurityRole.Admin">
                                    </th>
                                    <th>Customer</th>
                                    <th>Account #</th>
                                    <th>Account Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,idx) in model.customerAccounts" v-bind:key="idx">
                                    <td class="is-narrow" v-if="user.securityRole === SecurityRole.Admin">
                                        <a @click="deleteAccount(item)" class="has-text-black"><i class="material-icons md-18">delete</i></a>
                                    </td>
                                    <td>{{censor(item.customerName)}}</td>
                                    <td>{{censor(item.customerAccount.number)}}</td>
                                    <td>{{censor(item.customerAccount.name)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="columns mt-3">
                        <div class="column">
                            <div class="is-pulled-right">
                                <button type="submit" class="button is-success mr-2"><i class="material-icons md-18 mr-1">save</i>Save</button>
                                <router-link class="button is-danger" :to="{ name : 'Users' }"><i class="material-icons md-18 mr-1">cancel</i>Cancel</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

