<template>
    <center>
        <h1>Not Found!</h1>
        <p>
            <a href="/">Go home?</a>
        </p>
    </center>
</template>

<style scoped>
    center {
        margin: 15vw;
    }

    h1 {
        color: var(--border);
        font-size: 4em;
    }
</style>