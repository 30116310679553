<script setup>
    import { computed } from 'vue'

    const props = defineProps({
        name: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'number'
        },
        modelValue: {
            type: [String, Number]
        },
        disabled: {
            type: Boolean,
            default: false
        }
    })

    const emit = defineEmits(['update:modelValue'])

    const model = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            return emit('update:modelValue', value)
        }
    })
</script>

<template>
    <div class="field has-addons">
        <div class="control is-expanded">            
            <Field :type="type" class="input nowrap" v-bind:class="{'has-text-right' : type == 'number'}" :name="name" v-model="model" v-bind:disabled="disabled == true"/>
        </div>
        <p class="control is-right">
            <button class="button is-static">
                <span class="units"><slot></slot></span>
            </button>
        </p>
    </div>
</template>


