<script setup>
    import { useToast } from "vue-toastification";
    import { ref, onMounted } from 'vue';
    import axios from 'axios'
    import utils from '@/utils.js';

    const pageInfo = ref({ sortKey: 'EntryDate', sortOrder: 'desc' });
    const rows = ref([]);
    const selectedItems = ref([]);

    async function search(parms) {
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);

        const response = await axios.post("/api/TelemetryLog/List", pageInfo.value);
        if (response.status !== 200) return;
        rows.value = response.data.rows;
        pageInfo.value = response.data.pageInfo;
    };

    function toggleAll(event) {
        let state = event.target.checked;
        selectedItems.value = [];
        if (!state) return;
        rows.value.forEach(row => { selectedItems.value.push(row.id); });
    };

    function toggleItem(event, item) {        
        if (event.target.checked)
            selectedItems.value.push(item.id);
        else 
            selectedItems.value.splice(selectedItems.value.indexOf(item.id), 1);
    }

    async function deleteSelection() {
        const response = await axios.post("/api/TelemetryLog/Delete", selectedItems.value);
        if (response.status !== 200) return;
        useToast().success(`${selectedItems.value.length} record(s) were deleted successfully!`);
        selectedItems.value = [];
        search();
    }

    onMounted(() => search());
</script>

<style scoped>
    .scrollbox {
        white-space: pre-line;
        cursor: pointer;
        overflow: auto;
        max-height: 150px;
    }
</style>

<template>
    <div>
        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">query_stats</i> Telemetry Log</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <button class="button is-link" @click="deleteSelection" v-bind:disabled="selectedItems.length === 0">
                        <i class="material-icons">delete</i>
                    </button>
                </div>
            </div>
        </div>

        <div class="field">
            <div title="Search" class="control has-icons-right">
                <input class="input" type="text" placeholder="Search" v-on:keyup.enter="search()" v-model="pageInfo.search">
                <span class="icon is-right"><i class="material-icons">search</i></span>
            </div>
        </div>

        <div class="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                <thead class="has-background-white-ter">
                    <tr>
                        <th>
                            <input type="checkbox" @click="toggleAll($event)" v-bind:checked="selectedItems.length === rows.length" />
                        </th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="EntryDate" v-on:sorted="search">Entry Date</GridHeader></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="TaskName" v-on:sorted="search">Task</GridHeader></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="Text" v-on:sorted="search">Text</GridHeader></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in rows" v-bind:key="item.id">
                        <td class="is-narrow">
                            <input type="checkbox" @click="toggleItem($event,item)" v-bind:checked="selectedItems.indexOf(item.id) >= 0" />
                        </td>
                        <td class="is-narrow" align="right">{{utils.formatDateTime(item.entryDate)}}</td>
                        <td>{{item.taskName}}</td>
                        <td>
                            <div class="scrollbox">
                                {{item.text}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
    </div>
</template>

