<script setup>
    import { ref, computed, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import helpers from '@/checklist-helpers';
    import schema from './schema.js';
    import utils from '@/utils.js';           
    import constants from '@/constants.js';
    import axios from 'axios';     

    const props = defineProps(["user"]);

    const shipperModel = ref(null);
    const shipperModels = ref([]);
    const facility = ref(null);
    const facilities = ref([]);
    const isShipmentComplete = ref(false);
    const model = ref(null);
    const route = useRoute();
       
    const getFieldMeta = (fieldName) => helpers.getFieldMeta(schema, fieldName);
    const getFieldLabel = (fieldName) => helpers.getFieldLabel(schema, fieldName);
            
    function formatBool(value) {
        if (value == null) return "";
        if (value == true) return "<div class='has-text-success-dark'>Yes</div>";
        return "<div class='has-text-danger-dark'>No</div>";
    }

    async function loadLookups() {            
        await Promise.all([
            axios.get('/api/ShipperModel/List').then(x => shipperModels.values = x.data),
            axios.get('/api/Facility/List').then(x => facilities.value = x.data)
        ]);
    }

    function getReturnAddress() {        
        if (model.value == null ) return "";
        let matches = facilities.value.filter(x => x.id == (model.value.facilityId || -1));
        return (matches.length == 1) ? `${matches[0].city}, ${matches[0].stateProvince}, ${matches[0].postalCode}` : "";
    }

    const isMetric = computed(() => props.user.unitSystem === constants.UnitSystem.Metric);

    onMounted( async () => {
        await loadLookups();
        const response = await axios.get(`/api/PreparationChecklist/Get/${(route.params.id || '')}`);
        if (response.status != 200) return;
        model.value = response.data.model;
        shipperModel.value = model.value.shipperModelId ? shipperModels.values.filter(x => x.id == model.value.shipperModelId)[0] : null;
        facility.value = model.value.facilityId ? facilities.value.filter(x => x.id == model.value.facilityId)[0] : null;
        isShipmentComplete.value = response.data.isShipmentComplete;
    })
</script>

<template>
    <div class="container" v-if="model != null">        
        <div class="columns">
            <div class="column">
                <p class="title"><span class="material-icons mr-1">local_shipping</span>Dry Ice Shipper Package Preparation Checklist</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <router-link class="button" :to="{ name : 'PreparationChecklists' }" title="Return to List">
                        <i class="material-icons md-18">list</i>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="notification is-info is-warning" v-if="isShipmentComplete == true">
            <i class="material-icons">warning</i>
            The shipment associated with this record is <strong>Return Completed</strong>.
        </div>

        <div class="columns is-gapless">
            <div class="column">
                <div v-if="model && model.author">
                    Created By: {{model.author.fullName}} on {{utils.formatDate(model.created)}}
                </div>
                <div v-if="model && model.submitter">
                    Submitted By: {{model.submitter.fullName}} on {{utils.formatDate(model.submitted)}}
                </div>
            </div>
        </div>
        
        <div class="box" v-if="shipperModel">
            <h2 class="heading-md"><span class="material-icons mr-1">fact_check</span>1. Shipper Vessel Selection"</h2>

            <div class="field is-horizontal">
                <div class="field">
                    <div v-html='getFieldLabel("jdeOrderNumber")'></div>
                    <div>{{model.jdeOrderNumber}}</div>
                </div>
                <div class="field" v-if="facility">
                    <div v-html='getFieldLabel("facilityId")'></div>
                    <div>{{facility.name}}</div>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("customerAccountNumber")'></div>
                    <div>{{model.customerAccountNumber}} <span v-if="model.customerAccount">({{model.customerAccount.name}})</span></div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field">
                    <div v-html='getFieldLabel("shipperSentryNumber")'></div>
                    <div>{{model.shipperSentryNumber}}</div>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("shipperModelId")'></div>
                    <div v-if="shipperModel">{{shipperModel.name}}</div>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("shipperSerialNumber")'></div>
                    <div>{{model.shipperSerialNumber}}</div>
                </div>
            </div>

            <div class="box has-background-info-light">
                <div class="columns">
                    <div class="column is-narrow" v-if="shipperModel && shipperModel.images.length > 0">
                        <figure class="image">
                            <img v-bind:src="`/api/ShipperModel/Image/${shipperModel.images[0].id}`" style="max-width: 100%; height: auto; max-height: 200px" />
                        </figure>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Dry Ice Hold Time</label>
                            {{utils.formatNumber(shipperModel.dryIceHoldTime,2)}} days
                        </div>

                        <div class="field">
                            <label class="label">Maximum cut-off sublimation rate</label>
                            {{utils.formatNumber(shipperModel.cutOffSublimationRate,3)}} {{(isMetric ? "kg" : "lb")}}/day
                        </div>

                        <div class="field">
                            <label class="label">Dimensions</label>
                            {{shipperModel.packageLength}} {{(isMetric ? "cm" : "in")}} x
                            {{shipperModel.packageWidth}} {{(isMetric ? "cm" : "in")}} x
                            {{shipperModel.packageHeight}} {{(isMetric ? "cm" : "in")}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("hasDryIce")'></div>
                <div v-html="formatBool(model.hasDryIce)"></div>
            </div>


            <div class="field is-horizontal">
                <div class="field">
                    <div v-html='getFieldLabel("lastSublimationCheck")'></div>
                    <div>{{utils.formatDate(model.lastSublimationCheck)}}</div>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("lastSublimationRate")' class="is-pulled-left"></div>
                    <div>{{model.lastSublimationRate}} {{(isMetric ? "kg" : "lb")}}/day</div>
                </div>
            </div>
        </div>

        <div class="box">
            <h2 class="heading-md"><span class="material-icons mr-1">fact_check</span>2. Shipper Vessel Inspection</h2>

            <p><span v-html='getFieldLabel("isShipperAdequate")'></span><span v-html="formatBool(model.isShipperAdequate)"></span></p>

            <div class="field">
                <div v-html='getFieldLabel("isSerialNumberAdequate")'></div>
                <div v-html="formatBool(model.isSerialNumberAdequate)"></div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("isDryIceShoulderLabelAdequate")'></div>
                <div v-html="formatBool(model.isDryIceShoulderLabelAdequate)"></div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("isDryIceSideWallLabelAdequate")'></div>
                <div v-html="formatBool(model.isDryIceSideWallLabelAdequate)"></div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("isProductLabelAdequate")'></div>
                <div v-html="formatBool(model.isProductLabelAdequate)"></div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("isManufacturerLabelAdequate")'></div>
                <div v-html="formatBool(model.isManufacturerLabelAdequate)"></div>              
            </div>
            <div class="field">
                <div v-html='getFieldLabel("isBarrierTubeAdequate")'></div>
                <div v-html="formatBool(model.isBarrierTubeAdequate)"></div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("isCapAdequate")'></div>
                <div v-html="formatBool(model.isCapAdequate)"></div>
            </div>
        </div>

        <div class="box">
            <h2 class="heading-md"><span class="material-icons mr-1">fact_check</span>3. Accessories Preparation</h2>
            <div class="field">
                <div v-html='getFieldLabel("productHolder")'></div>
                <div>{{model.productHolder}}</div>
            </div>
            <div class="field" v-show="model.productHolder == 'Rack' || model.productHolder == 'Canister'">
                <div v-html='getFieldLabel("isProductHolderAdequate")'></div>
                <div v-html="formatBool(model.isProductHolderAdequate)"></div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("isProbeAttached")'></div>
                <div v-html="formatBool(model.isProbeAttached)"></div>
            </div>

            <div v-show="model.isProbeAttached == true">
                <div class="field">
                    <div v-html='getFieldLabel("probeSentryNumber")'></div>
                    <div>{{model.probeSentryNumber}}</div>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("probeCalibrationDate")'></div>
                    <div>{{utils.formatDate(model.probeCalibrationDate)}}</div>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("isProbePositionAdequate")'></div>
                    <div v-html="formatBool(model.isProbePositionAdequate)"></div>
                </div>
            </div>
        </div>

        <div class="box">
            <h2 class="heading-md"><span class="material-icons mr-1">fact_check</span>4. Dry Ice Filling</h2>
            <div class="field">
                <div v-html='getFieldLabel("shipperTareWeight")'></div>
                <div>{{model.shipperTareWeight}} {{(isMetric ? "kg" : "lb")}}</div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("shipperStartingWeight")'></div>
                <div>{{model.shipperStartingWeight}} {{(isMetric ? "kg" : "lb")}}</div>
            </div>
            <div class="field" v-if="shipperModel">
                <label class="label">Maximum Dry Ice Fill Weight:</label>
                <div>{{utils.formatNumber(shipperModel.maxDryIceFillWeight,2)}} {{(isMetric ? "kg" : "lb")}}</div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("dryIceAddedWeight")'></div>
                <div>{{model.dryIceAddedWeight}} {{(isMetric ? "kg" : "lb")}}</div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("shipperFillDate")'></div>
                <div>{{utils.formatDate(model.shipperFillDate)}}</div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("dryIceWeightInOtherApp")'></div>
                <div v-html="formatBool(model.dryIceWeightInOtherApp)"></div>
            </div>
            <div class="field">
                <div v-html='getFieldLabel("isCapInstalled")'></div>
                <div v-html="formatBool(model.isCapInstalled)"></div>
            </div>
        </div>

        <div class="box">
            <h2 class="heading-md"><span class="material-icons mr-1">fact_check</span>5. Package Preparation</h2>
            <div class="field">
                <div v-html='getFieldLabel("isBoxAssemblyAdequate")'></div>
                <div v-html="formatBool(model.isBoxAssemblyAdequate)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isBoxFoamInstalled")'></div>
                <div v-html="formatBool(model.isBoxFoamInstalled)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isCarrierPhoneNumberVisible")'></div>
                <div v-html="formatBool(model.isCarrierPhoneNumberVisible)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isQuickStartGuideAttached")'></div>
                <div v-html="formatBool(model.isQuickStartGuideAttached)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isDryIceLabelOnInteriorFlap")'></div>
                <div v-html="formatBool(model.isDryIceLabelOnInteriorFlap)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isUserGuideOnTopOfFoam")'></div>
                <div v-html="formatBool(model.isUserGuideOnTopOfFoam)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isShippingPouchAdequate")'></div>
                <div v-html="formatBool(model.isShippingPouchAdequate)"></div>
            </div>

            <div class="field" v-if="model.firstLegCarrier && model.firstLegTrackingNumber">
                <div class="label">5.8 First Destination</div>
                <span class="mr-2" v-html="utils.formatShippingCarrier(model.firstLegCarrier)"></span>
                <span class="mr-5">{{model.firstLegTrackingNumber}}</span>
                <span v-if="model.firstLegCity && model.firstLegStateProvince">
                    <i class="material-icons md-18">place</i>{{model.firstLegCity}}, {{model.firstLegStateProvince}}
                </span>
            </div>
            <div class="field" v-if="model.secondLegCarrier && model.secondLegTrackingNumber">
                <div class="label">5.9 Second Destination</div>
                <span class="mr-2" v-html="utils.formatShippingCarrier(model.secondLegCarrier)"></span>
                <span class="mr-5">{{model.secondLegTrackingNumber}}</span>
                <span v-if="model.secondLegCity && model.secondLegStateProvince">
                    <i class="material-icons md-18">place</i>{{model.secondLegCity}}, {{model.secondLegStateProvince}}
                </span>
            </div>
            <div class="field" v-if="model.returnLegCarrier && model.returnLegTrackingNumber">
                <div class="label">5.10 Return Destination</div>
                <span class="mr-2" v-html="utils.formatShippingCarrier(model.returnLegCarrier)"></span>
                <span class="mr-5">{{model.returnLegTrackingNumber}}</span>
                <span>
                    <i class="material-icons md-18">place</i> {{getReturnAddress()}}
                </span>
            </div>

            <div class="field" v-if="model.firstLegCarrier">
                <span v-if="model.firstLegCarrier != 'ups'">
                    <label class="label">5.11 UN1845 Dry Ice Class 9 diamond label attached to box exterior with dry ice amount (kg), shipper's name and address, and recipient's name and address recorded?</label>
                </span>
                <label class="label" v-if="model.firstLegCarrier == 'ups'">5.11 UPS Sticker &quot;Dry Ice for Diagnostic or Medical Purposes Only&quot; label attached box exterior with dry ice weight recorded in kg?</label>
                <div v-html="formatBool(model.isShippingPouchAdequate)"></div>
            </div>
        </div>

        <div class="box">
            <h2 class="heading-md"><span class="material-icons mr-1">fact_check</span>6. Package Completion</h2>
            <div class="field">
                <div v-html='getFieldLabel("isShipperOutboundInSentry")'></div>
                <div v-html="formatBool(model.isShipperOutboundInSentry)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isFoamAndInstructionsPresent")'></div>
                <div v-html="formatBool(model.isFoamAndInstructionsPresent)"></div>
            </div>

            <div v-show="model.isProbeAttached == true">
                <div class="field">
                    <div v-html='getFieldLabel("isDataLoggerRunning")'></div>
                    <div v-html="formatBool(model.isDataLoggerRunning)"></div>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isDataLoggerOutboundInSentry")'></div>
                    <div v-html="formatBool(model.isDataLoggerOutboundInSentry)"></div>
                </div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isBoxClosedAndTaped")'></div>
                <div v-html="formatBool(model.isBoxClosedAndTaped)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isOutboundShippingLabelAttached")'></div>
                <div v-html="formatBool(model.isOutboundShippingLabelAttached)"></div>
            </div>

            <div class="field">
                <div v-html='getFieldLabel("isFillDateAndInitialsOnExterior")'></div>
                <div v-html="formatBool(model.isFillDateAndInitialsOnExterior)"></div>
            </div>
        </div>
    </div>
</template>

<style>
    .field { margin-right: 20px; }
</style>

