import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const config = {
    auth: {        
        clientId: 'c2f74013-5009-4ce2-82af-3f6e2c456098', 
        authority: 'https://login.microsoftonline.com/1562f007-09a4-4fcb-936b-e79246571fc7',
        redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: '/' // Must be registered as a SPA redirectURI on your app registration
    },
    cache: {
        cacheLocation: 'localStorage'
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Verbose
        }
    }
};

export async function useMsalPopup() {        
    const client = new PublicClientApplication(config);
    await client.initialize();
    return await client.loginPopup({});
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};