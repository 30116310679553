<script setup>
    import * as yup from 'yup';
    import { ref } from 'vue';
    import axios from 'axios';
    import { useToast } from 'vue-toastification';
    
    const form = ref(null);
    const schema = yup.object({
        oldPassword: yup.string().required("Required."),
        password: yup.string().min(8, 'The password must be at least 8 characters').required(),
        confirmPassword: yup
            .string()
            .required()
            .oneOf([yup.ref('password')], 'Passwords do not match'),
    });

    const model = ref({ oldPassword: null, password: null, confirmPassword: null });

    async function onSubmit() {
        const response = await axios.post('/api/Users/ChangePassword', model.value);
        if (response.status !== 200) return;
        if (response.data.type == "success") {
            useToast().success(response.data.text);
            form.resetForm();
        }
        else {
            useToast().info(response.data.text);
        }
    }
</script>

<template>
    <div>
        <Form ref="form" @submit="onSubmit" :validation-schema="schema">
            <div class="container">                
                <div class="columns">
                    <div class="column is-3"></div>
                    <div class="column">
                        <p class="title">
                            <i class="material-icons">privacy_tip</i> Change Password
                        </p>                        

                        <div class="box">                            
                            <div class="field">
                                <label class="label">Old Password</label>
                                <div class="control has-icons-left">
                                    <Field class="input" type="password" placeholder="Old Password" name="oldPassword" v-model="model.oldPassword" />
                                    <span class="icon is-small is-left">
                                        <i class="material-icons">lock</i>
                                    </span>
                                    <ErrorMessage class="errors" name="oldPassword" />
                                </div>
                            </div>
                            <div class="field mt-6">
                                <label class="label">Password</label>
                                <div class="control has-icons-left">
                                    <Field class="input" type="password" placeholder="Password" name="password" v-model="model.password" />
                                    <span class="icon is-small is-left">
                                        <i class="material-icons">lock</i>
                                    </span>
                                    <ErrorMessage class="errors" name="password" />
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Confirm Password</label>
                                <div class="control has-icons-left">
                                    <Field class="input" type="password" placeholder="Confirm Password" name="confirmPassword" v-model="model.confirmPassword" />
                                    <span class="icon is-small is-left">
                                        <i class="material-icons">lock</i>
                                    </span>
                                    <ErrorMessage class="errors" name="confirmPassword" />
                                </div>
                            </div>

                            <div class="block mt-6 is-clearfix">
                                <button type="submit" class="button is-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="column is-3"></div>
                </div>
            </div>            
        </Form>
    </div>
</template>

