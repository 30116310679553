<script setup>
    import { computed } from 'vue';
    import Datepicker from '@vuepic/vue-datepicker';
    import moment from 'moment';

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        name: {
            type: String,
            required: true
        },
        maxDate: {
            type: [Date, String],
            required: false,
            default: null
        },
        minDate: {
            type: [Date, String],
            required: false,
            default: null
        },
        modelValue: {},
        showTime: {
            type: Boolean,
            required: false,
            default: false
        },
        allowedDates: {
            type: Array,
            default: () => []
        },
        disabledWeekDays: {
            type: Array,
            default: () => []
        }
    });

    const formatDate = (s) => moment(s).format(props.showTime ? "lll" : "ll");   
    const valueChanged = (newValue) => value.value = newValue;

    const value = computed({
        get() {
            return props.modelValue
        },        
        set(value) {
            emit('update:modelValue', value);
        }
    });
</script>

<template>
    <Datepicker placeholder="Select a Date"
                :enable-Time-Picker="showTime"
                :clearable="false"
                :allowedDates="allowedDates"
                autoApply
                :name="name"     
                :min-date="minDate"
                :max-date="maxDate"
                :model-value="modelValue"   
                :disabled-week-days="disabledWeekDays"
                @update:model-value="valueChanged"                
                :format="formatDate">
    </Datepicker>
</template>

