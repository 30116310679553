<script setup>
    import * as yup from 'yup';
    import axios from 'axios';
    import { ref } from 'vue';
    import { useToast } from 'vue-toastification';

    const props = defineProps(["user"]);      
    const schema = yup.object({
        email: yup.string().nullable().email().required()
    });
    const model = ref({ email: '' });
    const form = ref(null);

    async function onSubmit() {
        const response = await axios.post('/api/Users/ForgotPassword', model.value);
        if (response.status !== 200) return;
        if (response.data.type == "success") {
            useToast().success(response.data.text);
            form.resetForm();
        }
        else {
            useToast().info(response.data.text);
        }
    }
</script>

<template>
    <div>
        <Form ref="form" @submit="onSubmit" :validation-schema="schema">           
            <div class="container">                
                <div class="columns">
                    <div class="column is-3"></div>
                    <div class="column">
                        <p class="title">
                            <i class="material-icons">privacy_tip</i> Forgot Password
                        </p>

                        <div class="box">
                            <p class="mb-5">Please enter in your email address. If your account exists, we will reset your password and email you a link so you can log back in.</p>

                            <div class="field">
                                <label class="label">Email</label>
                                <div class="control has-icons-left">
                                    <Field class="input" type="text" placeholder="Email" name="email" v-model="model.email" />
                                    <span class="icon is-small is-left">
                                        <i class="material-icons">email</i>
                                    </span>
                                    <ErrorMessage class="errors" name="email" />
                                </div>
                            </div>

                            <div class="block mt-6 is-clearfix">
                                <button type="submit" class="button is-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="column is-3"></div>
                </div>

            </div>
        </Form>
    </div>
</template>

