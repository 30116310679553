<script setup>
    import { ref, computed, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import utils from '@/utils.js';    
    import { useToast } from 'vue-toastification';
    import ShipperSummary from './ShipperSummary.vue';
    import SublimationMeasurements from './SublimationMeasurements.vue';
    import VacuumMaintenance from './VacuumMaintenance.vue';
    import constants from '@/constants.js';
    import { useRouter } from 'vue-router';
    import axios from 'axios';

    const model = ref(null);
    const props = defineProps(["user"]);
    const router = useRouter();
    const confirmModal = ref(null);
    const measurements = ref(null);
    const vacuum = ref(null);
    const route = useRoute();

    async function load() {
        const response = await axios.get(`/api/Shipper/Get/${route.params.id || ""}`);

        if (response.status == 200) 
            model.value = response.data;
        else
            router.push({ name: "NotFound" });
    }

    async function resetShipper() {
        await confirmModal.value.open("Confirm Reset?", `Are you sure you want to proceed? Resetting this shipper will:<br/><br/>(1) Clear its history.<br/>(2) Set its status to Registered.<br/>(3) Designate its physical condition as acceptable for transfer to end-user.</br></br>Warning: This action is not reversible!`);          
        const response = await axios.post(`/api/Shipper/Reset/${model.value.id}`);
        if (response.status !== 200) return;
        useToast().success("The shipper was reset successfully.")

        //refresh all components
        load();
        measurements.value.search();
        vacuum.value.search();
    }

    const isAdmin = computed(() => props.user && props.user.securityRole == constants.SecurityRole.Admin);

    onMounted(() => load());
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="container" v-if="model">
            <div class="columns is-gapless is-marginless">
                <div class="column">
                    <p class="title mb-4">
                        <i class="material-icons md-24">propane_tank</i> Manage Shipper
                    </p>
                </div>
                <div class="column is-narrow">
                    <div class="buttons">
                        <button class="button is-danger is-light" type="button" title="Reset Shipper" @click="resetShipper" v-if="isAdmin == true">
                            <span class="material-icons md-18">restart_alt</span> Reset Shipper
                        </button>

                        <router-link class="button" :to="{ name : 'Shippers'}">
                            <span class="material-icons md-18">list</span>
                        </router-link>
                    </div>
                </div>
            </div>

            <h2 class="heading-md"><i class="material-icons">info</i> General Information</h2>
            <ShipperSummary :shipper="model" class="mb-4"></ShipperSummary>

            <h2 class="heading-md"><i class="material-icons">scale</i> Sublimation Measurements</h2>
            <SublimationMeasurements :shipper="model" :user="user" ref="measurements" @changed="load()"></SublimationMeasurements>

            <h2 class="heading-md"><i class="material-icons">history</i> Vacuum Maintenance</h2>
            <VacuumMaintenance ref="vacuum" :shipper="model" :user="user" @changed="load()"></VacuumMaintenance>
        </div>
    </div>
</template>