<script setup>
    import { ref, watch, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import axios from 'axios';
    import * as yup from 'yup';    
    import constants from '@/constants.js';
    import { useToast } from 'vue-toastification';

    const schema = yup.object({
        userType: yup.number().required().positive("required"),
        firstName: yup.string().typeError('First name is required.').required("First name is required."),
        lastName: yup.string().typeError('Last name is required.').required("Last name is required."),
        email: yup.string().email("Email must be valid.").required("Email is required."),
        accountNumbers: yup.string().when('userType', {
            is: constants.UserType.Customer,
            then: () => yup.string().typeError("Account # is required.").required("Account # is required.")
        }),
        password: yup.string().when('userType', {
            is: constants.UserType.Customer,
            then: () => yup.string().required("Password is required.").min(8, 'The password must be at least 8 characters')
        }),
        confirmPassword: yup.string().when('userType', {
            is: constants.UserType.Customer,
            then: () => yup.string().required("Confirm password is required.").oneOf([yup.ref('password')], 'Passwords do not match')
        })
    });

    const facilities = ref([]);
    const router = useRouter();
    const model = ref({
        userType: null,
        firstName: "",
        lastName: "",
        email: "",
        accountNumbers: "",
        password: "",
        confirmPassword: ""
    });

    watch(
        () => model.value.isCustomer,
        () => form.value.validate()
    );

    async function onSubmit() {
        const response = await axios.post("/api/Users/SignUp", model.value);
        if (response.status !== 200) return;
        if (response.data.type == "success") {
            router.push({ name: 'Home' });
            useToast().success(response.data.text);
        }
        else {
            useToast().info(response.data.text, response.data.type);
        }
    }

    onMounted( async () => {
        const response = await axios.get("/api/Facility/List");
        if (response.status !== 200) return;
        facilities.value = response.data;
    })
</script>

<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons md-24">draw</i> Sign Up!</p>
            </div>
        </div>

        <Form ref="form" @submit="onSubmit" :validation-schema="schema" v-if="!completed">
            <div class="field">
                <label class="label">Are you a customer or a Linde employee?</label>
                <div class="control">
                    <label class="radio">
                        <Field type="radio" name="userType" :value="constants.UserType.Customer" v-model="model.userType" /> Customer
                    </label>
                    <label class="radio">
                        <Field type="radio" name="userType" :value="constants.UserType.Employee" v-model="model.userType" /> Linde Employee
                    </label>
                </div>
            </div>
            <div v-if="model.userType">
                <div class="field is-grouped">
                    <div class="control">
                        <label class="label">First Name</label>
                        <Field class="input" type="text" name="firstName" placeholder="First Name" v-model="model.firstName" />
                        <ErrorMessage class="errors" name="firstName" />
                    </div>

                    <div class="control">
                        <label class="label">Last Name</label>
                        <Field class="input" type="text" name="lastName" placeholder="Last Name" v-model="model.lastName" />
                        <ErrorMessage class="errors" name="lastName" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">
                        Specify the account(s) you would like access to
                        <span class="material-icons md-18 ml-1 has-text-info" title="If you have an existing account number, please specify it. This information will help to expedite your account creation process.">info</span>
                    </label>
                    <Field class="input" type="text" name="accountNumbers" v-model="model.accountNumbers" />
                </div>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Email</label>
                            <div class="control has-icons-left">
                                <Field class="input" type="text" placeholder="Email" name="email" v-model="model.email" />
                                <span class="icon is-small is-left">
                                    <i class="material-icons">email</i>
                                </span>
                                <ErrorMessage class="errors" name="email" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Mobile Phone Number <span title="Please use E.164 formatting for your phone number. E.164 is an international standard, titled The international public telecommunication numbering plan, that defines a numbering plan for the worldwide public switched telephone network and some other data networks. E.164 defines a general format for international telephone numbers." class="ml-1 material-icons md-18 has-text-info-dark cursor-help">help</span></label>
                            <div class="control">
                                <Field class="input" type="text" placeholder="e.g +17168675309" name="mobilePhoneNumber" v-model="model.mobilePhoneNumber" />
                                <ErrorMessage class="errors" name="mobilePhoneNumber" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field" v-show="model.userType == constants.UserType.Employee">
                    <label class="label">Facility</label>
                    <div class="select control">
                        <Field as="select" name="facilityId" v-model="model.facilityId">
                            <option :value="null"></option>
                            <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                {{option.name}}
                            </option>
                        </Field>
                    </div>
                    <ErrorMessage class="errors" name="facilityId" />
                </div>

                <div class="field is-horizontal" v-show="model.userType == constants.UserType.Customer">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Password</label>
                            <div class="control has-icons-left">
                                <Field class="input" type="password" placeholder="Password" name="password" v-model="model.password" />
                                <span class="icon is-small is-left">
                                    <i class="material-icons">lock</i>
                                </span>
                                <ErrorMessage class="errors" name="password" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Confirm Password</label>
                            <div class="control has-icons-left">
                                <Field class="input" type="password" placeholder="Confirm Password" name="confirmPassword" v-model="model.confirmPassword" />
                                <span class="icon is-small is-left">
                                    <i class="material-icons">lock</i>
                                </span>
                                <ErrorMessage class="errors" name="confirmPassword" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-grouped mt-5">
                    <div class="control">
                        <button type="submit" class="button is-link"><i class="material-icons md-18 mr-1">save</i>Submit</button>
                    </div>
                    <div class="control">
                        <button class="button is-link is-light" type="reset">Cancel</button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

