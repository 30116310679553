const formatErrors = (schema, errors) => {
    let list = [];
    for (const [key, value] of Object.entries(errors)) {
        list.push({ field: key, message: value, meta: getFieldMeta(schema, key) });
    }

    return list.sort((a, b) => {
        if (a.meta.section === b.meta.section)
            return a.meta.number < b.meta.number ? -1 : 1
        else
            return a.meta.section < b.meta.section ? -1 : 1
    });
};

const getFieldMeta = (schema, fieldName) => {    
    let schemaItem = schema.fields[fieldName];
    let meta = { number: -1, section: -1, label: fieldName, required: false };
    if (schemaItem == null) return meta;    
    meta.label = schemaItem.spec?.label;
    meta.section = schemaItem?.spec?.meta?.section;
    meta.number = schemaItem?.spec?.meta?.number;
    meta.required = schemaItem.spec?.presence === "required";
    return meta;
};

const getFieldLabel = (schema, fieldName) => {
    let meta = getFieldMeta(schema, fieldName);
    return `<label class='label' id='S${meta.section}_Q${meta.number}'>${meta.section}.${meta.number} ${meta.label} ${(meta.required ? "<span class='has-text-danger md-16'>*</span>" : "")}</label>`;
};

const disableFormControls = (form) => {
    Array.from(form.elements).forEach(element => {
        let elementType = element.getAttribute("type");
        if (elementType == "button" || elementType == "submit") return;
        element.setAttribute("disabled", "disabled");
        element.classList.add("has-background-grey-light");
    })
}

export default {
    formatErrors,
    getFieldMeta,
    getFieldLabel,
    disableFormControls
}