<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <p class="heading-lg">
                    SECCURUS<sup>&reg;</sup> Dry Ice Shippers
                </p>
                <p class="heading-md">
                    Reduce costs, streamline operations and minimize risks to frozen biologicals
                </p>
            </div>
            <div class="column is-narrow">
                <router-link :to="{name: 'RequestInformation'}" class="button is-link mt-3">Request Information</router-link>
            </div>
        </div>

        <figure class="image">
            <img src="/img/product_shippers.png" title="Cryo Saver">
        </figure>

        <div class="columns mt-6">
            <div class="column">
                <p class="mb-2">Linde’s SECCURUS<sup>&reg;</sup> dry ice technology enables cold chain managers to reduce costs and streamline operations while minimizing risk to valuable frozen products and specimens.</p>
                <p class="mb-2">The high-performance, reusable SECCURUS shipper maintains its entire storage zone at or below -70 degrees Celsius and holds temperature longer, with less dry ice than conventional dry ice shippers. Some models may last as long as 20 days.</p>
                <p>This shipper comes conveniently preloaded with dry ice and ready to store products or specimens.</p>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <p class="heading-sm">SECCURUS Overview Video</p>
                <div style="width: 100%; min-width: 350px; max-width: 800px;">
                    <div style="position: relative; width: 100%; overflow: hidden; padding-top: 56.25%;">
                        <iframe style="position: absolute; top: 0; left: 0; right: 0; width: 100%; height: 100%; border: none;"
                                width="560" height="315"
                                src="https://www.youtube.com/embed/bAlU6RK6lnI?si=W8y70BKGOsg8yKO-"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div class="column"></div>
            <div class="column">
                <p class="heading-sm">SECCURUS Instructional Video</p>
                <div style="width: 100%; min-width: 350px; max-width: 800px;">
                    <div style="position: relative; width: 100%; overflow: hidden; padding-top: 56.25%;">
                        <iframe 
                            style="position: absolute; top: 0; left: 0; right: 0; width: 100%; height: 100%; border: none;" 
                            width="560" height="315" 
                            src="https://www.youtube.com/embed/QwUiuv2FspM?si=Ir9p2AS1rGW_BNmP"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>


        <p class="heading-lg mt-6">
            Applications
        </p>

        <div class="columns">
            <div class="column">
                <figure class="image is-3by2">
                    <img src="/img/biospecimens.jpg" title="Biospecimens">
                </figure>

                <p class="heading-lg">Biospecimens</p>
                <ul class="dashed">
                    <li>Reach difficult-to-serve locations</li>
                    <li>Collect and ship samples on any day of the week</li>
                    <li>Frozen storage and shipping within a single package</li>
                    <li>No dry ice sourcing or handling</li>
                    <li>Pool samples for reduced number of shipments</li>
                </ul>
            </div>
            <div class="column">
                <figure class="image is-3by2">
                    <img src="/img/therapy.jpg" title="Cell and Gene Therapy">
                </figure>

                <p class="heading-lg">Cell and Gene Therapy</p>

                <ul class="dashed">
                    <li>Freeze apheresis material to optimize cell therapy processing</li>
                    <li>Transport and store gene therapy products prior to administration</li>
                    <li>Superior quality with consistent temperature below -70°C for 10+ days</li>
                    <li>Cost-efficient and flexible scale-up for commercial operations</li>
                </ul>
            </div>
            <div class="column">
                <figure class="image is-3by2">
                    <img src="/img/vaccines.jpg" title="Drug Product">
                </figure>

                <p class="heading-lg">Drug Product</p>

                <ul class="dashed">
                    <li>Deliver and store frozen product (investigational, biologics, vaccines)</li>
                    <li>Enable clinical sites lacking adequate frozen storage</li>
                    <li>Avoid quality incidents and unnecessary costs from shipping delays</li>
                    <li>Improve sustainability by reducing waste and dry ice consumption</li>
                </ul>
            </div>
        </div>

        <p class="heading-lg mt-6">
            Available Models
        </p>

        <div class="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                <thead style="background-color:#005591">
                    <tr>
                        <th colspan="2"></th>
                        <th align="center" class="has-text-white">DI-1-90</th>
                        <th align="center" class="has-text-white">DI-6-90</th>
                        <th align="center" class="has-text-white">DI-9-152</th>
                        <th align="center" class="has-text-white">DI-15-216</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="2">Dry ice hold time</td>
                        <td align="center">7 days</td>
                        <td align="center">20 days</td>
                        <td align="center">10 days</td>
                        <td align="center">10 days</td>
                    </tr>
                    <tr>
                        <td colspan="2">Complete package dimensions, L x W x H (inch)</td>
                        <td align="center">10 x 10 x 16</td>
                        <td align="center">13 x 13 x 24</td>
                        <td align="center">13 x 13 x 24</td>
                        <td align="center">17 x 17 x 25</td>
                    </tr>
                    <tr>
                        <td colspan="2">Package weight with dry ice</td>
                        <td align="center">10.6 lbs</td>
                        <td align="center">23.0 lbs</td>
                        <td align="center">24.7 lbs</td>
                        <td align="center">41.4 lbs</td>
                    </tr>
                    <tr>
                        <td colspan="2">Typical storage capacity for 2 mL vials</td>
                        <td align="center" style="vertical-align:middle">20 (bag)</td>
                        <td align="center" style="vertical-align:middle">80 (canes)</td>
                        <td align="center">200 (boxes)<br />300 (canes)</td>
                        <td align="center">500 (boxes)<br />600 (canes)</td>
                    </tr>
                    <tr>
                        <td colspan="2">Typical storage capacity for 10 mL tubes</td>
                        <td align="center">11</td>
                        <td align="center">22</td>
                        <td align="center">80</td>
                        <td align="center">224</td>
                    </tr>
                    <tr>
                        <td rowspan="3">Storage space dimensions</td>
                        <td>Diameter (inch)</td>
                        <td align="center">3.2</td>
                        <td align="center">3.2</td>
                        <td align="center">5.6</td>
                        <td align="center">8.1</td>
                    </tr>
                    <tr>
                        <td>Height (inch)</td>
                        <td align="center">7.7</td>
                        <td align="center">12.6</td>
                        <td align="center">12.4</td>
                        <td align="center">13.1</td>
                    </tr>
                    <tr>
                        <td>Volume (L)</td>
                        <td align="center">1.0</td>
                        <td align="center">1.6</td>
                        <td align="center">5.0</td>
                        <td align="center">11.1</td>
                    </tr>
                    <tr>
                        <td colspan="2">Amount of dry ice required</td>
                        <td align="center">2.5 lbs</td>
                        <td align="center">8 lbs</td>
                        <td align="center">7 lbs</td>
                        <td align="center">12 lbs</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p>All models independently validated to meet ISTA 3A, ISTA 7D, and UN3373 Category B standards.<br />All models CE marked and MDD/MDR compliant.</p>

        <div class="columns mt-6">
            <div class="column">
                <p class="heading-lg">SECCURUS Dry Ice Shipper Advantages</p>
            </div>
        </div>

        <div class="columns">
            <div class="column is-6">
                <ul class="spaced">
                    <li>
                        <span class="has-text-weight-bold">Long duration</span>
                        <span> – Lasts up to 20 days</span>
                    </li>
                    <li>
                        <span class="has-text-weight-bold">Uniform payload temperature</span>
                        <span> – Below -70°C for entire duration</span>
                    </li>
                    <li>
                        <span class="has-text-weight-bold">Pre-filled with dry ice</span>
                        <span> – Arrives cold and ready to use</span>
                    </li>
                    <li>
                        <span class="has-text-weight-bold">Local frozen storage</span>
                        <span> – Ship and store in same unit</span>
                    </li>
                    <li>
                        <span class="has-text-weight-bold">Reusable</span>
                        <span> – Prepaid return label included</span>
                    </li>
                    <li>
                        <span class="has-text-weight-bold">Ship any day of the week</span>
                        <span> – Can last through the weekend</span>
                    </li>
                    <li>
                        <span class="has-text-weight-bold">Enable difficult applications</span>
                        <span> – Underserved regions, home-based trials</span>
                    </li>
                </ul>

                <div class="buttons is-justify-content-center">
                    <router-link :to="{name: 'RequestInformation'}" class="button is-link">Request Information</router-link>
                </div>
            </div>
            <div class="column">
                <figure class="image is-3by2">
                    <img src="/img/graph.jpg" title="Dry Ice Shipper Performance">
                </figure>
            </div>
        </div>

        <hr />
        <p>
            NOTE: End users are responsible for ensuring all biological products and specimens are packaged and/or placed in appropriate containers consistent with all applicable governmental and carrier requirements PRIOR TO placing such products/specimens in the SECCURUS Dry Ice Shipper. Such requirements may include, for example, the DOT and IATA requirements for leak-proof primary and secondary receptacles and any applicable FDA packaging, storage, or container requirements. End users are solely responsible for determining what governmental and carrier requirements apply to their biological products and specimens.
        </p>
    </div>
</template>

<style scoped>
    body, p, span, div, table {
        color: #002d54 !important;
    }

    .table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
        background-color: #d9edf7 !important;
    }

    ul.dashed {
        list-style-type: '- ';
        padding-left: 20px;
        padding-right: 20px;
    }

    ul.spaced > li {
        margin-bottom: 15px;
    }
</style>