<script setup>
    import { ref, computed } from 'vue';
    import * as yup from 'yup';
    import { useForm } from 'vee-validate';
    import axios from 'axios';
    import utils from '@/utils.js';

    const { handleSubmit } = useForm({
        validationSchema: yup.object({
            name: yup.string().nullable().required().label("Account Name"),
            number: yup.string().nullable().required().label("Account Number"),
            inventoryBranch: yup.string().nullable().required("Required").label("Inventory Branch"),
            orderCarriers: yup.string().nullable()
                .min(1, 'At least 1 carrier needs to be specified')         
                .test("is_filled_by_customer", "If the customer fills, only 1 carrier can be specified.", function (value, ctx) {
                    return !(model.value.areVesselsFilledByLinde == false && carrierCount.value > 1);
                })
                .label("Order carrier")
        })
    });

    const model = ref({});
    const root = ref(null);
    const carriers = ref([]);
    let onSave = null;

    const onSubmit = handleSubmit(async () => {        
        try {
            onSave(model.value);
        }
        finally {
            toggleDisplay(false)
        }
    });

    async function open(item) {        
        if (item != null)
            model.value = Object.assign({}, item);
        else {
            const response = await axios.get("/api/Customer/Account");
            model.value = (response.status === 200) ? response.data : null;
        }

        await loadLookups();

        return new Promise((resolve, reject) => {
            //assign callbacks
            onSave = (result) => resolve(Object.assign(item ?? {}, result));
            toggleDisplay(true);
        });
    };

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";

    async function getRandomCustomerNumber() {
        const response = await axios.post("/api/Customer/CreateRandomAccountNumber");
        if (response.status !== 200) return;
        model.value.number = response.data;
    }

    function hasCarrier(carrier) {
        if (model.value == null || model.value.orderCarriers == null) return false;
        let tokens = model.value.orderCarriers.split(",");
        return tokens.filter(x => x.toUpperCase() === carrier.toUpperCase()).length > 0;
    }

    const carrierCount = computed(() => (model.value.orderCarriers || "").split(",").filter(x => x.length > 0).length );

    function updateOrderCarriers(event) {
        if (model.value == null) return;

        var tokens = model.value.orderCarriers.split(",");

        var checked = event.target.checked;
        var carrier = event.target.value;

        if (carrier.length == 0) return;
        let index = tokens.indexOf(carrier);

        if (checked === true && index < 0) tokens.push(carrier);
        if (checked === false && index >= 0) tokens.splice(index, 1);

         model.value.orderCarriers = tokens.filter(x => x.length > 0).join(",");
    }

    async function loadLookups() {
        await Promise.all([
            axios.get('/api/Order/Carriers').then(x => carriers.value = x.data)
        ]);
    }

    defineExpose({
        open
    })
</script>

<template>
    <div class="modal" ref="root">
        <form @submit.prevent="onSubmit">
            <div class="modal-background"></div>
            <div class="modal-card mt-6" v-if="model != null">

                <header class="modal-card-head">
                    <p class="modal-card-title">{{model && model.id > 0 ? "Edit" : "Add"}} Account</p>
                    <button type="button" class="delete" aria-label="close" v-on:click="toggleDisplay(false)" title="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Account Name</label>
                        <div class="control">
                            <Field class="input" type="text" name="name" v-model="model.name" />
                            <ErrorMessage class="errors" name="name" />
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Account Number</label>

                        <div class="columns">
                            <div class="column">
                                <div class="control">
                                    <Field class="input" type="text" name="number" v-model="model['number']" />
                                    <ErrorMessage class="errors" name="number" />
                                </div>
                            </div>
                            <div class="column is-narrow">
                                <button type="button"
                                        v-bind:disabled="(model.number || '').length > 0"
                                        class="button is-info is-light" @click="getRandomCustomerNumber()">
                                    <i class="material-icons md-18 mr-2">casino</i> Generate
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="field is-grouped">
                        <div class="control">
                            <label class="label">Linde Inventory Branch *</label>
                            <div class="control">
                                <Field class="input" name="inventoryBranch" v-model="model.inventoryBranch"></Field>
                                <ErrorMessage class="errors" name="inventoryBranch" />
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <p class="label">Who is normally responsible for filling the shipping vessels?</p>
                        <div class="control" v-if="model">
                            <label class="radio">
                                <input type="radio" name="areVesselsFilledByLinde" v-model="model.areVesselsFilledByLinde" :value="true" /> Linde Fills
                            </label>
                            <label class="radio">
                                <input type="radio" name="areVesselsFilledByLinde" v-model="model.areVesselsFilledByLinde" :value="false" /> Customer Fills
                            </label>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Carriers Supported for Orders</label>
                        <div class="control">
                            <label class="checkbox mr-1" v-for="item in carriers">
                                <input type="checkbox" :value="item" v-bind:checked="hasCarrier(item)"
                                       @change="updateOrderCarriers($event)" />
                                <span class="ml-1" v-html="utils.formatShippingCarrier(item)"></span>
                            </label>
                        </div>
                    </div>

                    <Field type="hidden" v-model="model.orderCarriers" name="orderCarriers"></Field>
                    <ErrorMessage class="errors" name="orderCarriers"></ErrorMessage>                    
                </section>
                <footer class="modal-card-foot is-justify-content-right">
                    <div class="buttons">
                        <button type="submit" class="button is-success">OK</button>
                        <button type="button" class="button" @click="toggleDisplay(false)">Cancel</button>
                    </div>
                </footer>
            </div>
        </form>
    </div>
</template>

