<script setup>
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import { useToast } from 'vue-toastification';
    import constants from '@/constants.js';

    const rows = ref([]);
    const pageInfo = ref({ sortKey: 'Name', pageSize: 25 });
    const confirmModal = ref(null);

    async function search(parms) {
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);
        const response = await axios.post("/api/Facility/PagedList", pageInfo.value);
        if (response.status !== 200) return;
        rows.value = response.data.rows;
        pageInfo.value = response.data.pageInfo;
    }

    async function deleteRow(row) {             
        await confirmModal.value.open("Delete Facility?", `Are you sure you want to delete this facility?</br></br>Warning: This action is not reversible!`);
            
        const response = await axios.post(`/api/Facility/Delete/${row.id}`);

        if (response.status === 200) {
            search();
            useToast().success("The facility was deleted successfully.");
        }
        else {
            useToast().error("Unable to delete facility. Perhaps the record is in use?");
        }
    }

    const hasFlag = (item, flag) => (item.flags & flag) == flag;

    onMounted(() => search());
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">factory</i> Facilities</p>
            </div>
            <div class="column is-narrow">
                <router-link class="button" :to="{ name : 'AddFacility' }">
                    <i class="material-icons">add</i>
                </router-link>
            </div>
        </div>

        <div class="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow">
                <thead class="has-background-white-ter">
                    <tr>
                        <th class="is-narrow"></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="Name" v-on:sorted="search">Name</GridHeader></th>
                        <th>Capabilities</th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="AddressLine1" v-on:sorted="search">Address</GridHeader></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="City" v-on:sorted="search">City</GridHeader></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="StateProvince" v-on:sorted="search">State / Province</GridHeader></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="PostalCode" v-on:sorted="search">Postal Code</GridHeader></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="Country" v-on:sorted="search">Country</GridHeader></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in rows" v-bind:key="item.id">
                        <td>
                            <div class="nowrap">
                                <router-link class="has-text-black" :to="{ name : 'EditFacility', params: { id: item.id}}" title="Edit"><i class="material-icons md-18">edit</i></router-link>
                                <a class="has-text-black" title="Delete" v-on:click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>
                            </div>
                        </td>
                        <td class="nowrap">
                            {{item.name}}
                        </td>
                        <td>
                            <span class="tags are-small">
                                <span class="tag is-info" v-if="hasFlag(item, constants.FacilityFlags.FillStation)">Fill Station</span>
                                <span class="tag is-primary" v-if="hasFlag(item, constants.FacilityFlags.CentralOperations)">Central Operations</span>
                            </span>
                        </td>
                        <td>
                            <div>{{item.addressLine1}}</div>
                            <div v-if="item.addressLine2">{{item.addressLine2}}</div>
                            <div v-if="item.addressLine3">{{item.addressLine3}}</div>
                        </td>
                        <td>{{item.city}}</td>
                        <td>{{item.stateProvince}}</td>
                        <td>{{item.postalCode}}</td>
                        <td>{{item.country}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
    </div>
</template>