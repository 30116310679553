import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from './stores/user';
import { SecurityRole } from '@/constants';

import NotFound from './views/NotFound.vue';
import AccessDenied from './views/Shared/AccessDenied.vue';
import DemoMode from './views/DemoMode.vue';
import Alerts from './views/Alerts/AlertList.vue';
import CustomerList from './views/Customers/CustomerList.vue';
import CustomerEditor from './views/Customers/CustomerEditor.vue';
import CustomerServiceCenterList from './views/CustomerServiceCenters/CustomerServiceCenterList.vue';
import FacilityList from './views/Facilities/FacilityList.vue';
import FacilityEditor from './views/Facilities/FacilityEditor.vue';
import Home from './views/Home.vue';
import ManufacturingList from './views/Manufacturing/ManufacturingList.vue';
import ManufacturingImport from './views/Manufacturing/ManufacturingImport.vue';
import OrderList from './views/Orders/OrderList.vue';
import OrderEditor from './views/Orders/OrderEditor.vue';
import PackingLists from './views/PackingLists/PackingListList.vue';
import PackingListEditor from './views/PackingLists/PackingListEditor.vue';
import PreparationChecklists from './views/PreparationChecklists/PrepChecklistList.vue';
import PreparationChecklistViewer from './views/PreparationChecklists/PrepChecklistViewer.vue';
import PreparationChecklistEditor from './views/PreparationChecklists/PrepChecklistEditor.vue';
import ProcessShipper from './views/Shippers/ProcessShipper.vue';
import ReturnChecklists from './views/ReturnChecklists/ReturnChecklistList.vue';
import ReturnChecklistEditor from './views/ReturnChecklists/ReturnChecklistEditor.vue';
import ShipmentList from './views/Shipments/ShipmentList.vue';
import ShipmentEditor from './views/Shipments/ShipmentEditor.vue';
import ShipperEditor from './views/Shippers/ShipperEditor.vue';
import ShipperList from './views/Shippers/ShipperList.vue';
import ShipperModelList from './views/ShipperModels/ShipperModelList.vue';
import ShipperModelEditor from './views/ShipperModels/ShipperModelEditor.vue';
import TaskLogs from './views/Logs/TaskLogs.vue';
import TelemetryLogs from './views/Logs/TelemetryLogs.vue';
import TrackPackage from './views/Utils/TrackPackage.vue';
import UnitConversion from './views/Utils/UnitConversion.vue';
import UserList from './views/Users/UserList.vue';
import UserEditor from './views/Users/UserEditor.vue';
import ChangePassword from './views/Users/ChangePassword.vue';
import ForgotPassword from './views/Users/ForgotPassword.vue';
import Login from './views/Login.vue';
import SignUp from './views/SignUp.vue';
import RequestInformation from './views/RequestInformation.vue';

const routes = [
    { path: '/', name: 'Home', component: Home },

    { path: '/alerts', name: 'Alerts', component: Alerts, meta: { level: SecurityRole.User } },
    { path: '/demo-mode', name: 'DemoMode', component: DemoMode, meta: { level: SecurityRole.User } },

    { path: '/dashboard', name: 'Dashboard', component: ShipmentList, meta: { level: SecurityRole.User } },
    { path: '/shipment/:id', name: 'Shipment', component: ShipmentEditor, meta: { level: SecurityRole.User } },

    { path: '/checklist/preparation', name: 'PreparationChecklists', component: PreparationChecklists, meta: { level: SecurityRole.User } },
    { path: '/checklist/preparation/:id', name: 'ViewPreparationChecklist', component: PreparationChecklistViewer, meta: { level: SecurityRole.User } },
    { path: '/checklist/preparation/edit/:id', name: 'EditPreparationChecklist', component: PreparationChecklistEditor, meta: { level: SecurityRole.User } },
    { path: '/checklist/preparation/add', name: 'AddPreparationChecklist', component: PreparationChecklistEditor, meta: { level: SecurityRole.User } },

    { path: '/checklist/return', name: 'ReturnChecklists', component: ReturnChecklists, meta: { level: SecurityRole.User } },
    { path: '/checklist/return/:id', name: 'ViewReturnChecklist', component: ReturnChecklistEditor, meta: { level: SecurityRole.User } },
    { path: '/checklist/return/edit/:id', name: 'EditReturnChecklist', component: ReturnChecklistEditor, meta: { level: SecurityRole.User } },
    { path: '/checklist/return/add', name: 'AddReturnChecklist', component: ReturnChecklistEditor, meta: { level: SecurityRole.User } },

    { path: '/customers', name: 'Customers', component: CustomerList, meta: { level: SecurityRole.Admin } },
    { path: '/customer/edit/:id', name: 'EditCustomer', component: CustomerEditor, meta: { level: SecurityRole.Admin } },
    { path: '/customer/add', name: 'AddCustomer', component: CustomerEditor, meta: { level: SecurityRole.Admin } },

    { path: '/customer-service-centers', name: 'CustomerServiceCenters', component: CustomerServiceCenterList, meta: { level: SecurityRole.Admin } },

    { path: '/login', name: 'Login', component: Login },

    { path: '/logs/telemetry', name: 'TelemetryLogs', component: TelemetryLogs, meta: { level: SecurityRole.Admin } },    
    { path: '/logs/tasks', name: 'TaskLogs', component: TaskLogs, meta: { level: SecurityRole.Admin } },    

    { path: '/orders', name: 'Orders', component: OrderList, meta: { level: SecurityRole.User } },
    { path: '/order/edit/:id', name: 'EditOrder', component: OrderEditor, meta: { level: SecurityRole.User } },
    { path: '/order/add', name: 'AddOrder', component: OrderEditor, meta: { level: SecurityRole.User } },

    { path: '/packinglists', name: 'PackingLists', component: PackingLists, meta: { level: SecurityRole.User } },
    { path: '/packinglist/:id', name: 'EditPackingList', component: PackingListEditor, meta: { level: SecurityRole.User } },
    { path: '/packinglist/add', name: 'AddPackingList', component: PackingListEditor, meta: { level: SecurityRole.User } },

    { path: '/shippers', name: 'Shippers', component: ShipperList, meta: { level: SecurityRole.User } },    
    { path: '/shipper/process', name: 'ProcessShipper', component: ProcessShipper, meta: { level: SecurityRole.User } },
    { path: '/shipper/:id', name: 'EditShipper', component: ShipperEditor, meta: { level: SecurityRole.User } },

    { path: '/shipper/models', name: 'ShipperModels', component: ShipperModelList, meta: { level: SecurityRole.Admin } },
    { path: '/shipper/model/edit/:id', name: 'EditShipperModel', component: ShipperModelEditor, meta: { level: SecurityRole.Admin } },
    { path: '/shipper/model/add', name: 'AddShipperModel', component: ShipperModelEditor, meta: { level: SecurityRole.Admin } },

    { path: '/shipper/manufacturing', name: 'Manufacturing', component: ManufacturingList },
    { path: '/shipper/manufacturing/import', name: 'ManufacturingImport', component: ManufacturingImport },

    { path: '/facilities', name: 'Facilities', component: FacilityList, meta: { level: SecurityRole.Admin } },
    { path: '/facility/edit/:id', name: 'EditFacility', component: FacilityEditor, meta: { level: SecurityRole.Admin } },
    { path: '/facility/add', name: 'AddFacility', component: FacilityEditor, meta: { level: SecurityRole.Admin } },

    { path: '/signup', name: 'SignUp', component: SignUp },
    { path: '/request-information', name: 'RequestInformation', component: RequestInformation },

    { path: '/utils/track', name: 'Track', component: TrackPackage, meta: { level: SecurityRole.Admin } },
    { path: '/utils/unit-conversion', name: 'UnitConversion', component: UnitConversion },

    { path: '/users', name: 'Users', component: UserList, meta: { level: SecurityRole.Admin } },
    { path: '/user/edit/:id', name: 'EditUser', component: UserEditor, meta: { level: SecurityRole.User } },
    { path: '/user/add', name: 'AddUser', component: UserEditor, meta: { level: SecurityRole.Admin } },    
    { path: '/user/change-password', name: 'ChangePassword', component: ChangePassword, meta: { level: SecurityRole.User } },
    { path: '/user/forgot-password', name: 'ForgotPassword', component: ForgotPassword },    

    { path: '/not-found', name: 'NotFound', component: NotFound },
    { path: '/access-denied', name: 'AccessDenied', component: AccessDenied }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

router.beforeEach((to, from, next) => {         
    var store = useUserStore();            
    var userLevel = store?.user?.securityRole || SecurityRole.None;
    var routeLevel = ( to.meta && to.meta.level ) ? to.meta.level : 0;        

    if (store.isAuthenticated) {
        store.keepAlive();
        if (routeLevel > userLevel) return next({ name: 'AccessDenied' });
    }        
    else 
        if (routeLevel > 0) return next({ name: 'Login' });

    next();        
});

export default router;