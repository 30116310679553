<script setup>
    import { ref, watch, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import axios from 'axios';
    import * as yup from 'yup';    
    import constants from '@/constants.js';
    import { useToast } from 'vue-toastification';

    const schema = yup.object({
        firstName: yup.string().typeError('required').required().label("First name"),
        lastName: yup.string().typeError('required').required().label("Last name"),
        email: yup.string().email("Email must be valid.").required().label("Email"),
        phone: yup.string().typeError('required').required().label("Phone"),
        address: yup.string().typeError("required").required().label("Address"),
        city: yup.string().typeError("required").required().label("City"),
        stateProvince: yup.string().typeError("required").required().label("State / Province"),
        postalCode: yup.string().typeError("required").required().label("Postal Code")        
    });

    const stateProvinces = ref([]);
    const router = useRouter();
    const model = ref({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        address: "",
        city: "",
        stateProvince: "",
        message: ""
    });

    async function onSubmit() {
        const response = await axios.post("/api/Users/RequestInformation", model.value);
        if (response.status !== 200) return;
        if (response.data.type == "success") {
            router.push({ name: 'Home' });
            useToast().success(response.data.text);
        }
        else {
            useToast().info(response.data.text, response.data.type);
        }
    }

    async function loadLookups() {
        await axios.get('/api/Tracking/StateProvinces/usa').then(x => stateProvinces.value = x.data);
    }

    onMounted(() => {
        loadLookups()
    })
</script>

<template>
    <div class="container">
        <h1 class="title">
            Request Information
        </h1>
        <h2 class="subtitle">
            SECCURUS &reg; - Dry Ice Shipping & Storage System
        </h2>

        <Form ref="form" @submit="onSubmit" :validation-schema="schema">

            <div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">First Name</label>
                            <div class="control">
                                <Field class="input" name="firstName" placeholder="First Name" v-model="model.firstName" />
                                <ErrorMessage class="errors" name="firstName" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Last Name</label>
                            <div class="control">
                                <Field class="input" name="lastName" placeholder="Last Name" v-model="model.lastName" />
                                <ErrorMessage class="errors" name="lastName" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Email</label>
                            <div class="control has-icons-left">
                                <Field class="input" placeholder="Email" name="email" v-model="model.email" />
                                <span class="icon is-small is-left">
                                    <i class="material-icons">email</i>
                                </span>
                                <ErrorMessage class="errors" name="email" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Phone <span title="Please use E.164 formatting for your phone number. E.164 is an international standard, titled The international public telecommunication numbering plan, that defines a numbering plan for the worldwide public switched telephone network and some other data networks. E.164 defines a general format for international telephone numbers." class="ml-1 material-icons md-18 has-text-info-dark cursor-help">help</span></label>
                            <div class="control">
                                <Field class="input" placeholder="e.g +17168675309" name="phone" v-model="model.phone" />
                                <ErrorMessage class="errors" name="phone" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Company Name</label>
                            <div class="control">
                                <Field class="input" name="companyName" v-model="model.companyName" />
                                <ErrorMessage class="errors" name="companyName" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Address</label>
                            <div class="control">
                                <Field class="input" name="address" v-model="model.address" />
                                <ErrorMessage class="errors" name="address" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">City</label>
                            <div class="control">
                                <Field class="input" name="city" placeholder="City" v-model="model.city" />
                                <ErrorMessage class="errors" name="city" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">State / Province</label>
                            <div class="select control">
                                <Field as="select" name="stateProvince" v-model="model.stateProvince">
                                    <option :value="null"></option>
                                    <option v-for="option in stateProvinces" v-bind:key="option.abbreviation" v-bind:value="option.abbreviation">
                                        {{option.abbreviation}} - {{option.name}}
                                    </option>
                                </Field>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Postal Code</label>
                            <div class="control">
                                <Field class="input" name="postalCode" v-model="model.postalCode" />
                                <ErrorMessage class="errors" name="postalCode" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Message</label>
                            <div class="control">
                                <Field class="textarea" as="textarea" name="message" v-model="model.message" />
                                <ErrorMessage class="errors" name="message" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="buttons">
                    <button class="button" type="reset">Clear</button>
                    <button type="submit" class="button is-link">Submit</button>
                </div>
            </div>
        </Form>
    </div>
</template>

