<script setup>
    import { ref, computed, onMounted } from 'vue';
    import SublimationMeasurementModal from './SublimationMeasurementModal.vue';
    import moment from 'moment';
    import utils from '@/utils.js';
    import { useToast } from 'vue-toastification';
    import constants from '@/constants.js';
    import axios from 'axios';

    const props = defineProps(["user", "shipper"]);
    const emit = defineEmits(["changed"]);
    const items = ref([]);
    const pagingInfo = ref({ sortKey: "FirstWeightTaken", sortOrder: "desc", pageNumber: 1, pageSize: 10 });
    const measurementModal = ref(null);
    const confirmModal = ref(null);

    async function add() {
        const measurement = await measurementModal.value.open(null);
        items.value.push(measurement);
        emit("changed");
        useToast().success("The sublimation measurement was added successfully.");
    }

    async function edit(item) {
        const measurement = await measurementModal.value.open(item.id);
        Object.assign(item, measurement);
        emit("changed");
        useToast().success("The sublimation measurement was saved successfully.");
    }

    async function remove(item) {        
        await confirmModal.value.open("Delete Sublimation Measurement?", `Are you sure you want to delete the sublimation measurement started on ${utils.formatDate(item.firstWeightTaken)}?</br></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/Shipper/DeleteSublimationMeasurement/${item.id}`);
        if (response.status !== 200) return;
        let index = items.value.indexOf(item);
        items.value.splice(index, 1);
        emit("changed");
        useToast().success("The sublimation measurement was deleted successfully.");
    }

    async function search(parms) {
        if (parms) pagingInfo.value = Object.assign(pagingInfo.value, parms);
        const response = await axios.post(`/api/Shipper/SublimationMeasurementsPagedList/${props.shipper.id || ""}`, pagingInfo.value);
        if (response.status !== 200) return;
        items.value = response.data.rows;
        pagingInfo.value = response.data.pagingInfo;
    }

    function isFirstMeasurement(measurement) {
        if (items.value == null) return false;

        let weights = items.value.map(x => x.firstWeightTaken).sort(function (a, b) {
            return moment(a).diff(moment(b))
        });

        let earliest = weights.length > 0 ? weights[0] : null;
        return measurement.firstWeightTaken == earliest;
    }

    const isMetric = computed(() => props.user.unitSystem === constants.UnitSystem.Metric);

    onMounted(() => search());
</script>

<template>
    <ConfirmModal ref="confirmModal"></ConfirmModal>
    <SublimationMeasurementModal ref="measurementModal" :shipper="shipper" :user="user"></SublimationMeasurementModal>

    <div class="box">
        <p>
            Dry ice sublimation rate is measured to determine (1) whether a shipper requires vacuum maintenance or warranty return to the manufacturer and (2) 
            whether vacuum maintenance has been performed successfully.
        </p>

        <div class="columns mb-2 is-gapless">
            <div class="column"></div>
            <div class="column is-narrow">
                <button class="button" type="button" @click="add()">
                    <span class="material-icons md-18">add</span>
                </button>
            </div>
        </div>

        <div class="table-container" id="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                <thead class="has-background-white-ter">
                    <tr>
                        <th rowspan="2" class="is-narrow"></th>
                        <th colspan="2">First Sample</th>
                        <th colspan="2">Second Sample</th>
                        <th rowspan="2" width="150">Sublimation Rate ({{(isMetric ? "kg" : "lb")}}/day)</th>
                    </tr>
                    <tr class="has-background-info-light">
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="FirstWeightTaken" v-on:sorted="search">Date Taken</GridHeader></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="FirstWeight" v-on:sorted="search">Weight ({{(isMetric ? "kg" : "lb")}})</GridHeader></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="SecondWeightTaken" v-on:sorted="search">Date Taken</GridHeader></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="SecondWeight" v-on:sorted="search">Weight ({{(isMetric ? "kg" : "lb")}})</GridHeader></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td class="nowrap">                            
                            <a class="has-text-black" @click="edit(item)" title="Edit...">
                                <span class="material-icons md-18">edit</span>
                            </a>
                            <a class="has-text-black" @click="remove(item)" title="Delete..." v-show="isFirstMeasurement(item) == false">
                                <span class="material-icons md-18">delete</span>
                            </a>
                        </td>
                        <td align="right">{{utils.formatDateTime(item.firstWeightTaken)}}</td>
                        <td align="right">{{utils.formatNumber(item.firstWeight,2)}}</td>
                        <td align="right">{{utils.formatDateTime(item.secondWeightTaken)}}</td>
                        <td align="right">{{utils.formatNumber(item.secondWeight,2)}}</td>
                        <td align="right">{{utils.formatNumber(item.sublimationRate,3)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <GridPager :pageInfo="pagingInfo" v-on:paged="search"></GridPager>
    </div>
</template>