<script setup>
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import utils from '@/utils.js';

    const returnAlerts = ref([]);
    const orderAlerts = ref([]);

    async function loadAlerts() {
        await Promise.all( [
            axios.get('/api/Alerts/ReturnAlerts').then(x => returnAlerts.value = x.data),
            axios.get('/api/Alerts/OrderAlerts').then(x => orderAlerts.value = x.data)           
        ]);
    }

    onMounted(() => loadAlerts());
</script>

<template>
    <div>
        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">notifications_active</i> Alerts</p>
            </div>
        </div>
       
        <p class="subtitle has-text-link">Return Alerts</p>
        <p v-if="returnAlerts.length == 0">There currently are no return alerts.</p>
        <div v-else class="table-container">
            <table class="table is-hoverable is-bordered is-narrow">
                <thead class="has-background-white-ter">
                    <tr>
                        <th>Order #</th>
                        <th>State</th>
                        <th>Shipper Model</th>
                        <th>Shipper Serial #</th>
                        <th>Delivery Date</th>
                    </tr>
                </thead>
                <tbody v-for="item in returnAlerts" v-bind:key="item.shipmentId">
                    <tr v-bind:class="{ 'has-background-grey-ter' : item.shipmentId % 2 == 0 }">
                        <td class="nowrap">
                            <router-link :to="{ name : 'Shipment', params: { id: item.shipmentId}}" title="View Shipment" target="_blank">
                                <i class="material-icons md-18">open_in_new</i> {{item.orderNumber}}
                            </router-link>
                        </td>
                        <td class="is-narrow"><span v-html="utils.formatShipmentState(item.state)"></span></td>
                        <td>{{item.shipperModel}}</td>
                        <td>{{item.shipperSerialNumber}}</td>
                        <td>{{utils.formatDateTime(item.deliveryDate)}}</td>
                    </tr>
                    <tr v-bind:class="{ 'has-background-grey-ter' : item.shipmentId % 2 == 0 }" v-for="(msg, msgIdx) in item.messages" v-bind:key="msgIdx">
                        <td colspan="5">                                                   
                            <article class="message is-warning">
                                <div class="message-header p-1" v-on:click="msg.expanded = !msg.expanded">                                                          
                                    <a>
                                        <i class="material-icons is-pulled-left mr-2">add_circle_outline</i>
                                        <span v-html="msg.subject" class="is-pulled-left pt-1"></span>
                                    </a>
                                </div>
                                <div class="message-body" v-html="msg.body" v-show="msg.expanded == true"></div>
                            </article>                       
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <hr/>

        <p class="subtitle has-text-link">Order Alerts</p>
        <p v-if="orderAlerts.length == 0">There currently are no order alerts.</p>
        <div v-else class="table-container">
            <table class="table is-hoverable is-bordered is-narrow">
                <thead class="has-background-white-ter">
                    <tr>
                        <th>Order #</th>
                        <th class="nowrap">Order Date</th>
                        <th>Customer</th>
                    </tr>
                </thead>
                <tbody v-for="item in orderAlerts">
                    <tr>
                        <td class="nowrap">{{item.orderNumber}}</td>
                        <td class="is-narrow"><span v-html="utils.formatDate(item.orderDate)"></span></td>
                        <td>{{item.customerName}}</td>
                    </tr>
                    <tr v-for="(msg, msgIdx) in item.messages" v-bind:key="msgIdx">
                        <td colspan="5">
                            <article class="message is-warning">
                                <div class="message-header p-1" v-on:click="msg.expanded = !msg.expanded">
                                    <a>
                                        <i class="material-icons is-pulled-left mr-2">add_circle_outline</i>
                                        <span v-html="msg.subject" class="is-pulled-left pt-1"></span>
                                    </a>
                                </div>
                                <div class="message-body" v-html="msg.body" v-show="msg.expanded == true"></div>
                            </article>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

