<script setup>
    import { ref, computed, onMounted, watch } from 'vue';
    import axios from 'axios';

    const unitFamily = ref(null);
    const unitFamilies = ref([]);
    const fromUnit = ref(null);
    const fromValue = ref(1);
    const toUnit = ref(null);
    const toValue = ref(null);

    function switchFamily() {
        fromUnit.value = unitFamily.value.units[0];
        toUnit.value = unitFamily.value.units[1];
        convert();
    }

    async function convert() {
        if (fromValue.value == null || fromUnit.value == null || toUnit.value == null) return;

        let data = { unitFamilyId: unitFamily.value.id, fromUnits: fromUnit.value.id, fromValue: fromValue.value, toUnits: toUnit.value.id };

        const response = await axios.post('/api/UnitConversion/Convert', data);
        if (response.status !== 200) return;
        toValue.value = response.data.value;
    }

    function swap() {
        let fUnit = fromUnit.value;
        let fValue = fromValue.value;

        fromUnit.value = toUnit.value;
        fromValue.value = toValue.value;

        toUnit.value = fUnit;
        toValue.value = fValue;
        convert();
    }

    const units = computed(() => {
        if (!unitFamily.value) return [];
        return unitFamily.value.units;
    })

    watch(fromValue, (newVal, oldVal) => {
        if (newVal != oldVal) convert();
    });

    onMounted( async () => {
        const response = await axios.get('/api/UnitConversion/List');
        if (response.status !== 200) return;
        unitFamilies.value = response.data;
        unitFamily.value = unitFamilies.value[0];
        fromUnit.value = unitFamily.value.units[0];
        toUnit.value = unitFamily.value.units[1];
        convert();
    });
</script>

<template>
    <div class="container box">
        <div class="columns">
            <div class="column">
                <p class="title">
                    <i class="material-icons mr-1">transform</i>Unit Conversion
                </p>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <label class="label">Unit Family</label>
                <div class="select">
                    <select v-model="unitFamily" v-on:change="switchFamily">
                        <option v-for="(family,index) in unitFamilies" v-bind:key="index" v-bind:value="family">{{family.name}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <label class="label">From Value</label>
                <div class="field has-addons">
                    <div class="control is-expanded">
                        <input class="input" type="text" placeholder="From Value" v-model="fromValue" v-on:change="convert">
                    </div>
                    <div class="control">
                        <a class="button is-static">{{fromUnit ? fromUnit.abbreviation : ""}}</a>
                    </div>
                </div>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model="fromUnit" v-on:change="convert">
                            <option v-for="(unit,index) in units" v-bind:key="index" v-bind:value="unit">{{unit.name}} {{unit ? "(" + unit.abbreviation + ")" : ""}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="column is-narrow">
                <div class="is-centered">
                    <div class="mt-6 mb-6 is-size-4 has-text-centered">=</div>
                    <div class="button is-info" title="Swap" v-on:click="swap">
                        <span class="material-icons">swap_horiz</span>
                    </div>
                </div>
            </div>
            <div class="column">
                <label class="label">To Value</label>
                <div class="field has-addons">
                    <div class="control is-expanded">
                        <input class="input" type="text" placeholder="To Value" v-model="toValue" disabled>
                    </div>
                    <div class="control">
                        <a class="button is-static">{{toUnit ? toUnit.abbreviation : ""}}</a>
                    </div>
                </div>
                <div class="control is-expanded">
                    <div class="select is-fullwidth">
                        <select v-model="toUnit" v-on:change="convert">
                            <option v-for="(unit,index) in units" v-bind:key="index" v-bind:value="unit">{{unit.name}} {{unit ? "(" + unit.abbreviation + ")" : ""}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

