<script setup>
    import { ref, computed, watch, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import axios from 'axios';
    import * as yup from 'yup';
    import helpers from '@/checklist-helpers';
    import utils from '@/utils.js';
    import constants from '@/constants.js';
    import AttachmentBlock from './AttachmentBlock.vue';
    import Section from "@/components/ChecklistSection.vue";
    import { useToast } from 'vue-toastification';

    const props = defineProps(["user"]);

    const schema = yup.object({
        //***** 1. General Information *****
        shipperSentryNumber: yup.string().nullable().required().label('Shipper SENTRY #').meta({ section: 1, number: 1 }),
        facilityId: yup.number().nullable().required().label('Facility').meta({ section: 1, number: 2 }),
        shipperModel: yup.number().nullable().label('Shipper model').meta({ section: 1, number: 3 }),
        shipperSerialNumber: yup.string().nullable().label('Shipper serial #').meta({ section: 1, number: 4 }),
        jdeOutboundOrderNumber: yup.string().nullable().label('JDE outbound order #').meta({ section: 1, number: 5 }),
        customer: yup.string().nullable().label('Customer').meta({ section: 1, number: 6 }),

        //***** 2. Shipper Cap Information *****
        capCondition: yup.number().required().min(1, "Please specify the condition of the cap")
            .label('Cap condition').meta({ section: 2, number: 1 }),
        capImpurities: yup.string().nullable()
            .when('capCondition', { is: (x) => isGoodOrDamaged(x), then: () => yup.string().nullable().required() })
            .label('Any dirt, spills, or contamination observed on shipper cap?').meta({ section: 2, number: 2 }),
        capObservations: yup.string().nullable()
            .when(['capCondition', 'capImpurities'], { is: (x, y) => isObservationRequired(x, y), then: () => yup.string().nullable().required() })
            .label('Document observations for shipper cap').meta({ section: 2, number: 3 }),

        //***** 3. Temperature Data Logger and Probe *****
        dataLoggerCondition: yup.number().nullable()
            .when('hasProbe', { is: (x) => x == true, then: () => yup.number().required().min(1, "Please specify the condition of the data logger") })
            .label('Temperature data logger condition').meta({ section: 3, number: 1 }),
        isDataLoggerStopped: yup.boolean().nullable()
            .when('hasDataLogger', { is: (x) => x == true, then: () => yup.boolean().nullable().required() })
            .label('Temperature data logger RESET?').meta({ section: 3, number: 2 }),
        probeSentryNumber: yup.string().nullable()
            .when(['dataLoggerCondition', 'hasProbe'], { is: (x, y) => y == true && !isMissing(x), then: () => yup.string().nullable().required().oneOf([yup.ref('originalProbeSentryNumber')], 'The temperature data logger and probe SENTRY # does not match the value in the dry ice shipping preparation checklist') })
            .label('Temperature data logger and probe SENTRY #?').meta({ section: 3, number: 3 }),
        probeCondition: yup.number().nullable()
            .when('hasProbe', { is: (x) => x == true, then: () => yup.number().required().min(1, "Please specify the condition of the temperature probe") })
            .label('Temperature probe condition?').meta({ section: 3, number: 4 }),
        probeImpurities: yup.string().nullable()
            .when(['probeCondition', 'hasProbe'], { is: (x, y) => y == true && isGoodOrDamaged(x), then: () => yup.string().nullable().required() })
            .label('Any dirt, spills, or contamination observed on temperature probe?').meta({ section: 3, number: 5 }),
        probeObservations: yup.string().nullable()
            .when(['probeCondition', 'probeImpurities', 'hasProbe'], { is: (x, y, z) => z == true && isObservationRequired(x, y), then: () => yup.string().nullable().required() })
            .label('Document observations for temperature data logger and probe').meta({ section: 3, number: 6 }),

        //***** 4. Product Holder *****
        productHolder: yup.string().nullable().label('Product holder type').meta({ section: 4, number: 1 }),
        productHolderCondition: yup.number().nullable()
            .when('hasProductHolder', { is: (x) => x == true, then: () => yup.number().required().min(1, "Please specify the condition of the product holder") })
            .label('Product holder condition').meta({ section: 4, number: 2 }),
        productHolderImpurities: yup.string().nullable()
            .when(['productHolderCondition', 'hasProductHolder'], { is: (x, y) => y == true && isGoodOrDamaged(x), then: () => yup.string().nullable().required() })
            .label('Any dirt, spills, or contamination observed on product holder?').meta({ section: 4, number: 3 }),
        productHolderObservations: yup.string().nullable()
            .when(['productHolderCondition', 'productHolderImpurities', 'hasProductHolder'], { is: (x, y, z) => z == true && isObservationRequired(x, y), then: () => yup.string().nullable().required() })
            .label('Document observations for product holder').meta({ section: 4, number: 4 }),

        //***** 5. Shipper Vessel *****
        shipperCondition: yup.number().nullable().required().min(1, "Please specify the condition of the shipper vessel")
            .label('Shipper vessel condition').meta({ section: 5, number: 1 }),
        shipperImpurities: yup.string().nullable()
            .when('shipperCondition', { is: (x) => isGoodOrDamaged(x), then: () => yup.string().required().min(1, "Please specify the condition of the shipper") })
            .label('Any dirt, spills, or contamination observed on shipper vessel?').meta({ section: 5, number: 2 }),
        isShipperLabelingAdequate: yup.boolean().nullable()
            .when(['shipperCondition', 'hasShipper'], { is: (x, y) => y == true && isGoodOrDamaged(x), then: () => yup.boolean().nullable().required() })
            .label('Labels inspected and replaced as necessary?').meta({ section: 5, number: 3 }),
        shipperObservations: yup.string().nullable()
            .when(['shipperCondition', 'shipperImpurities'], { is: (x, y) => !isMissing(x) && isObservationRequired(x, y), then: () => yup.string().nullable().required() })
            .label('Document observations for shipper vessel').meta({ section: 5, number: 4 }),

        //***** 6. JDE and SENTRY *****
        jdeOrderNumber: yup.string().nullable().required()
            .label('JDE return order #').meta({ section: 6, number: 1 }),
        isJdeReturnAmended: yup.boolean().nullable().required()
            .label('Requested JDE return ticket to be final amended?').meta({ section: 6, number: 2 }),
        isSentryReturnRegistered: yup.boolean().nullable().required()
            .label('Shipper registered as returned with SENTRY scanner?').meta({ section: 6, number: 3 }),
        isDataLoggerReturnedInSentry: yup.boolean().nullable()
            .when(['dataLoggerCondition', 'probeCondition'], { is: (x, y) => isGood(x) && isGood(y), then: () => yup.boolean().nullable().required() })
            .label('Temperature data logger and/or probe registered as returned with SENTRY scanner?').meta({ section: 6, number: 4 })
    });
    let watchers = [];
    let debounce = utils.createDebounce();
    const cleaningOptions = [
        "no cleaning required",
        "segregate for cleaning and disinfection by Linde",
        "mark \"biohazard\" and segregate for cleaning / disposal by 3rd party"
    ];

    const sections = ref([]);
    const facilities = ref([]);
    const modified = ref(false);
    const submitted = ref(false);
    const isReadonly = ref(false);
    const loading = ref(false);                
    const selectedShipper = ref(null);    
    const shipperModels = ref(null);
    const shipperModel = ref(null);
    const shipment = ref(null);
    const showReturnWarning = ref(false);
    const returnWarningAcknowledged = ref(false);
    const showAdminOnlyWarning = ref(false);
    const preparationChecklist = ref(null);
    const router = useRouter();
    const route = useRoute();
    const model = ref({
        created: Date(),
        files: []
    });

    const formatErrors = (errors) => helpers.formatErrors(schema, errors);
    const getFieldMeta = (fieldName) => helpers.getFieldMeta(schema, fieldName);
    const getFieldLabel = (fieldName) => helpers.getFieldLabel(schema, fieldName);
    const toggleSections = (value) => sections.value.forEach(el => el.expanded = value);
    const setSectionRef = (el) => { if (el) sections.value.push(el); };    
    const scrollToErrors = () => window.scrollTo(0, 25);
    const uploadPhoto = (file) => model.value.files.push(file);
    const removePhoto = (item) => model.value.files.splice(model.value.files.indexOf(item), 1);
    const getFiles = (category) => model.value.files.filter(x => x.category == category);

    async function saveAsPublished() {        
        model.value.submitterId = props.user.id;
        model.value.submitted = utils.getIsoDate();
        await save();
        await router.push({ name: 'ReturnChecklists' });
        useToast().success("The checklist was published successfully.");
    }

    async function saveAsDraft() {
        model.value.submitterId = null;
        model.value.submitted = null;
        await save();
        router.push({ name: 'ReturnChecklists' });
        await useToast().success("The checklist was saved as draft successfully.");
    }

    async function save() {
        return new Promise(async (resolve) => {
            const response = await axios.post('/api/ReturnChecklist/Save', model.value);
            if (response.status !== 200) return;            
            model.value = response.data;            
            resetWatch();
            resolve();
        })  
    }

    function onSubmit() {
        submitted.value = true;
        saveAsPublished();
    }    

    function onInvalidSubmit() {
        scrollToErrors();
        submitted.value = true;
    }

    function resetWatch() {
        //kill the watch if its already running
        watchers.forEach(x => x());
        watchers = [];

        modified.value = false;

        watchers.push(
            watch(
                () => model.value,
                () => modified.value = true,
                { deep: true }
            )
        );

        watchers.push(
            watch(
                () => model.value.shipperSentryNumber,
                (newVal, oldVal) => {
                    if (isReadonly.value == true) return;
                    if (newVal != oldVal) {
                        debounce(findPreparationChecklist);
                    }
                }
            )
        );
    }

    async function findPreparationChecklist() {
        preparationChecklist.value = null;
        shipment.value = null;
        returnWarningAcknowledged.value = false;
        showReturnWarning.value = false;
        console.log("test")
        const response = await axios.post('/api/PreparationChecklist/Find', model.value);                
        if (response.status !== 200) return;        
        
        preparationChecklist.value = response.data && response.data.id > 0 ? response.data : null;
        model.value.shipmentId = preparationChecklist.value == null ? null : preparationChecklist.value.shipmentId;
        showReturnWarning.value = preparationChecklist.value != null && preparationChecklist.value.isReturnLabelRequired == false;
        if (model.value.shipmentId == null) return;

        const shipmentResponse = await axios.get(`/api/Shipment/Get/${model.value.shipmentId}`);
        if (shipmentResponse.status === 200) return;
        shipment.value = shipmentResponse.data.model;
    }

    const isMissing = (condition) => condition == constants.Condition.Missing;    
    const isGood = (condition) => condition == constants.Condition.Good;
    const isMissingOrDamaged = (condition) => condition == constants.Condition.Missing || condition == constants.Condition.Damaged;
    const isGoodOrDamaged = (condition) => condition == constants.Condition.Good || condition == constants.Condition.Damaged;
    const isObservationRequired = (condition, impurities) => (condition == constants.Condition.Damaged || (impurities || "").indexOf("segregate") >= 0);    
    const hasProbe = computed(() => preparationChecklist.value?.isProbeAttached == true );
    const hasDataLogger = computed(() => hasProbe.value == true && model.value?.dataLoggerCondition != constants.Condition.Missing);
    const hasShipper = computed(() => preparationChecklist.value !== null && model.value?.shipperCondition != constants.Condition.Missing);
    const hasProductHolder = computed(() => preparationChecklist.value?.productHolder !== 'N/A');
    const originalProbeSentryNumber = computed(() => preparationChecklist.value?.probeSentryNumber || "");
    const isAdmin = computed(() => props.user && props.user.securityRole == constants.SecurityRole.Admin);
    const isMetric = computed(() => model.value?.unitSystem === constants.UnitSystem.Metric);

    onMounted( async () => {
        loading.value = true;
        try {
            const response = await axios.get(`/api/ReturnChecklist/Get/${(route.params.id || '')}`);
            if (response.status !== 200) return;
            model.value = response.data.model;
            shipperModels.value = response.data.shipperModels;
            shipperModel.value = shipperModels.value.filter(x => x.id == model.value.shipperModelId)[0];
            facilities.value = response.data.facilities;
            findPreparationChecklist();
            isReadonly.value = model.value.permissions.indexOf("w") < 0;

            if (model.value.submitted != null) {
                showAdminOnlyWarning.value = true;
                if (!isAdmin.value) setTimeout( helpers.disableFormControls(document.forms[0]), 1000);
            }

            resetWatch();
        }
        finally {
            loading.value = false;
        }
    })
</script>

<template>
    <div class="container">
        <ConfirmModal ref="confirmModal" />

        <div class="notification is-info" v-show="showAdminOnlyWarning == true">
            <i class="material-icons is-pulled-left mr-2">info</i>
            <h2>This checklist has been submitted, only an admin can update it.</h2>
        </div>

        <div class="columns">
            <div class="column">
                <p class="title"><span class="material-icons mr-1">assignment_return</span>Return Dry Ice Shipper Checklist</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <router-link class="button" :to="{ name : 'ReturnChecklists' }" title="Return to List">
                        <i class="material-icons md-18">list</i>
                    </router-link>
                </div>
            </div>
        </div>

        <div v-show="loading == true">
            Loading, please wait...
        </div>

        <div v-show="loading == false">
            <div class="columns is-gapless">
                <div class="column">
                    <div v-if="model && model.author">
                        Created By: {{model.author.fullName}} on {{utils.formatDate(model.created)}}
                    </div>
                    <div v-if="model && model.submitter">
                        Submitted By: {{model.submitter.fullName}} on {{utils.formatDate(model.submitted)}}
                    </div>
                </div>
                <div class="column is-narrow">
                    <span class="has-text-link cursor-pointer" title="Expand sections" @click="toggleSections(true)">Expand All</span> |
                    <span class="has-text-link cursor-pointer" title="Collapse sections" @click="toggleSections(false)">Collapse All</span>
                </div>
            </div>


            <Form v-slot="{errors}" @invalid-submit="onInvalidSubmit" @submit="onSubmit" :validation-schema="schema" @keydown.enter="$event.preventDefault()">
                <template v-if="Object.keys(errors).length">
                    <div class="notification is-warning" v-if="submitted == true">
                        <p>Please correct the following errors</p>
                        <ul>
                            <li v-for="item in formatErrors(errors)" :key="item">
                                <a :href="`#S${item.meta.section}_Q${item.meta.number}`">
                                    {{item.meta.section}}.{{item.meta.number}} - <span v-html="item.message"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </template>

                <Section title="1. General Information" :ref="setSectionRef">
                    <div class="notification is-light" v-bind:class="preparationChecklist == null ? 'is-info':'is-success'">
                        <div v-if="preparationChecklist == null">
                            <p><i class="material-icons md-18">information</i> Please specify the <strong>Shipper SENTRY #</strong> so we can locate the <router-link target="_blank" :to="{ name : 'PreparationChecklists' }">Dry Ice Shipper Preparation Checklist</router-link> used for this shipment. </p>
                        </div>
                        <div v-else>
                            <p>
                                The <router-link :to="{ name : 'ViewPreparationChecklist', params: { id: preparationChecklist.id}}" target="_blank">Dry Ice Shipper Preparation Checklist</router-link>
                                was completed by {{preparationChecklist.submitter.fullName}} on {{utils.formatDate(preparationChecklist.submitted)}}.
                            </p>
                            <p v-if="shipment">
                                The <router-link :to="{ name : 'Shipment', params: { id: shipment.id}}" target="_blank">Shipment</router-link> status is <span v-html="utils.formatShipmentState(shipment.state)"></span>
                            </p>
                        </div>

                        <div v-show="showReturnWarning == true" class="mt-5">
                            <hr style="border: 1px solid #eee" />
                            <i class="material-icons md-48 is-pulled-left mr-2">notification_important</i>
                            It appears that the customer used an unexpected shipping label to return this shipper.  It is OK to complete shipper return.
                            Please add carrier name and tracking number or attach a photo of the shipping label to the return checklist notes.

                            <div class="mt-5" v-if="returnWarningAcknowledged != true">
                                <button class="button is-success" type="button" @click="returnWarningAcknowledged = true">
                                    <span>Proceed</span>
                                    <span class="material-icons ml-3">forward</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="is-hidden">
                        <!--- workaround so I can do required checks using yup 'when' logic -->
                        Probe <Field name="hasProbe" type="checkbox" v-model="hasProbe"></Field>
                        Data Logger <Field name="hasDataLogger" type="checkbox" v-model="hasDataLogger"></Field>
                        Product Holder <Field name="hasProductHolder" type="checkbox" v-model="hasProductHolder"></Field>
                        Probe Sentry Number <Field name="originalProbeSentryNumber" v-model="originalProbeSentryNumber"></Field>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <div v-html='getFieldLabel("shipperSentryNumber")'></div>
                                <div class="control has-icons-right" title="scan barcode">
                                    <Field class="input" type="text" placeholder="Shipper SENTRY #" name="shipperSentryNumber" v-model="model.shipperSentryNumber" />
                                    <span class="icon is-small is-right">
                                        <span class="material-icons md-18">barcode_reader</span>
                                    </span>
                                </div>
                            </div>

                            <div class="field">
                                <div v-html='getFieldLabel("facilityId")'></div>
                                <div class="select control is-fullwidth">
                                    <Field as="select" name="facilityId" v-model="model.facilityId">
                                        <option :value="null"></option>
                                        <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                            {{option.name}}
                                        </option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="preparationChecklist != null">
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <div class="field">
                                        <div v-html='getFieldLabel("shipperModel")'></div>
                                        <div class="control">
                                            <input class="input has-background-grey-light" type="text" :value="preparationChecklist.shipperModel.name" readonly>
                                        </div>
                                    </div>
                                </div>

                                <div class="field">
                                    <div v-html='getFieldLabel("shipperSerialNumber")'></div>
                                    <div class="control">
                                        <input class="input has-background-grey-light" type="text" :value="preparationChecklist.shipperSerialNumber" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <div v-html='getFieldLabel("jdeOutboundOrderNumber")'></div>
                                    <div class="control">
                                        <input class="input has-background-grey-light" type="text" :value="preparationChecklist.jdeOrderNumber" readonly>
                                    </div>
                                </div>

                                <div class="field">
                                    <div v-html='getFieldLabel("customer")'></div>
                                    <div class="control">
                                        <div v-if="preparationChecklist && preparationChecklist.customerAccount">
                                            <input class="input has-background-grey-light" type="text" :value="`${preparationChecklist.customerAccount.customerName} (${preparationChecklist.customerAccount.number})`" readonly>
                                        </div>
                                        <div v-else>
                                            <input class="input has-background-grey-light" type="text" value="?" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box has-background-info-light mt-3 is-clearfix" v-if="shipperModel">
                        <div class="columns">
                            <div class="column is-narrow" v-if="shipperModel.images.length > 0">
                                <figure class="image">
                                    <img v-bind:src="`/api/ShipperModel/Image/${shipperModel.images[0].id}`" style="max-width: 100%; height: auto; max-height: 200px" />
                                </figure>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Dry Ice Hold Time</label>
                                    {{utils.formatNumber(shipperModel.dryIceHoldTime,2)}} days
                                </div>

                                <div class="field">
                                    <label class="label">Maximum cut-off sublimation rate</label>
                                    {{utils.formatNumber(shipperModel.cutOffSublimationRate,3)}} {{(isMetric ? "kg" : "lb")}}/day
                                </div>

                                <div class="field">
                                    <label class="label">Dimensions</label>
                                    {{shipperModel.packageLength}} {{(isMetric ? "cm" : "in")}} x
                                    {{shipperModel.packageWidth}} {{(isMetric ? "cm" : "in")}} x
                                    {{shipperModel.packageHeight}} {{(isMetric ? "cm" : "in")}}
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>

                <div v-show="showReturnWarning == false || ( showReturnWarning == true && returnWarningAcknowledged == true )">
                    <div v-if="preparationChecklist != null">
                        <Section title="2. Shipper Cap" :ref="setSectionRef">
                            <div class="field">
                                <div v-html='getFieldLabel("capCondition")'></div>
                                <div class="control">
                                    <label class="radio">
                                        <Field type="radio" name="capCondition" v-model="model.capCondition" :value="constants.Condition.Good" /> Good
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="capCondition" v-model="model.capCondition" :value="constants.Condition.Missing" /> Missing
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="capCondition" v-model="model.capCondition" :value="constants.Condition.Damaged" /> Damaged
                                    </label>
                                </div>
                            </div>

                            <div class="field" v-show="isGoodOrDamaged(model.capCondition)">
                                <div v-html='getFieldLabel("capImpurities")'></div>
                                <div class="select control">
                                    <Field as="select" name="capImpurities" v-model="model.capImpurities">
                                        <option :value="null"></option>
                                        <option v-for="(option,index) in cleaningOptions" v-bind:key="index" v-bind:value="option">
                                            {{option}}
                                        </option>
                                    </Field>
                                </div>
                            </div>

                            <div class="field">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("capObservations")'></span>
                                    <span class="has-text-danger md-16 ml-1" v-show="isObservationRequired(model.capCondition, model.capImpurities) == true">*</span>
                                </div>
                                <div class="control">
                                    <Field class="textarea" as="textarea" name="capObservations" v-model="model.capObservations" />
                                </div>
                            </div>

                            <AttachmentBlock :canUpload="!isReadonly" category="ShipperCap" @upload="uploadPhoto" @remove="removePhoto" :files="getFiles('ShipperCap')" />
                        </Section>

                        <Section title="3. Temperature Data Logger and Probe" :ref="setSectionRef" v-show="hasProbe == true">
                            <div class="field">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("dataLoggerCondition")'></span>
                                    <span class="has-text-danger md-16 ml-1">*</span>
                                </div>
                                <div class="control">
                                    <label class="radio">
                                        <Field type="radio" name="dataLoggerCondition" v-model="model.dataLoggerCondition" :value="constants.Condition.Good" /> Good
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="dataLoggerCondition" v-model="model.dataLoggerCondition" :value="constants.Condition.Missing" /> Missing
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="dataLoggerCondition" v-model="model.dataLoggerCondition" :value="constants.Condition.Damaged" /> Damaged
                                    </label>
                                </div>
                            </div>

                            <div class="field" v-show="hasDataLogger">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("isDataLoggerStopped")'></span>
                                    <span class="has-text-danger md-16 ml-1">*</span>
                                </div>

                                <div class="control">
                                    <label class="checkbox">
                                        <Field type="checkbox" name="isDataLoggerStopped" :value="true" v-model="model.isDataLoggerStopped" /> Yes
                                    </label>
                                </div>
                            </div>

                            <div class="field" v-show="!isMissing(model.dataLoggerCondition)">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("probeSentryNumber")'></span>
                                    <span class="has-text-danger md-16 ml-1">*</span>
                                </div>

                                <div class="control has-icons-right" title="scan barcode">
                                    <Field class="input" type="text" name="probeSentryNumber" v-model="model.probeSentryNumber" />
                                    <span class="icon is-small is-right">
                                        <span class="material-icons md-18">barcode_reader</span>
                                    </span>
                                </div>
                            </div>

                            <div class="field">
                                <div>
                                    <div class="is-pulled-left" v-html='getFieldLabel("probeCondition")'></div>
                                    <span class="has-text-danger md-16 ml-1">*</span>
                                </div>

                                <div class="control">
                                    <label class="radio">
                                        <Field type="radio" name="probeCondition" v-model="model.probeCondition" :value="constants.Condition.Good" /> Good
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="probeCondition" v-model="model.probeCondition" :value="constants.Condition.Missing" /> Missing
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="probeCondition" v-model="model.probeCondition" :value="constants.Condition.Damaged" /> Damaged
                                    </label>
                                </div>
                            </div>

                            <div class="field" v-show="isGoodOrDamaged(model.probeCondition)">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("probeImpurities")'></span>
                                    <span class="has-text-danger md-16 ml-1">*</span>
                                </div>

                                <div class="select control">
                                    <Field as="select" name="probeImpurities" v-model="model.probeImpurities">
                                        <option :value="null"></option>
                                        <option v-for="(option,index) in cleaningOptions" v-bind:key="index" v-bind:value="option">
                                            {{option}}
                                        </option>
                                    </Field>
                                </div>
                            </div>

                            <div class="field">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("probeObservations")'></span>
                                    <span class="has-text-danger md-16 ml-1" v-show="isObservationRequired(model.probeCondition, model.probeImpurities) == true">*</span>
                                </div>
                                <div class="control">
                                    <Field class="textarea" as="textarea" name="probeObservations" v-model="model.probeObservations" />
                                </div>
                            </div>

                            <AttachmentBlock :canUpload="!isReadonly" category="Sensors" @upload="uploadPhoto" @remove="removePhoto" :files="getFiles('Sensors')" />
                        </Section>

                        <Section title="4. Product Holder" :ref="setSectionRef" v-show="hasProductHolder">
                            <div class="field">
                                <div v-html='getFieldLabel("productHolder")' />

                                <div class="control">
                                    <input class="input has-background-grey-light" type="text" :value="preparationChecklist.productHolder" readonly>
                                </div>
                            </div>

                            <div class="field">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("productHolderCondition")'></span>
                                    <span class="has-text-danger md-16 ml-1">*</span>
                                </div>

                                <div class="control">
                                    <label class="radio">
                                        <Field type="radio" name="productHolderCondition" v-model="model.productHolderCondition" :value="constants.Condition.Good" /> Good
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="productHolderCondition" v-model="model.productHolderCondition" :value="constants.Condition.Missing" /> Missing
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="productHolderCondition" v-model="model.productHolderCondition" :value="constants.Condition.Damaged" /> Damaged
                                    </label>
                                </div>
                            </div>

                            <div class="field" v-show="isGoodOrDamaged(model.productHolderCondition)">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("productHolderImpurities")'></span>
                                    <span class="has-text-danger md-16 ml-1">*</span>
                                </div>
                                <div class="select control">
                                    <Field as="select" name="productHolderImpurities" v-model="model.productHolderImpurities">
                                        <option :value="null"></option>
                                        <option v-for="(option,index) in cleaningOptions" v-bind:key="index" v-bind:value="option">
                                            {{option}}
                                        </option>
                                    </Field>
                                </div>
                            </div>

                            <div class="field">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("productHolderObservations")'></span>
                                    <span class="has-text-danger md-16 ml-1" v-show="isObservationRequired(model.productHolderCondition, model.productHolderImpurities) == true">*</span>
                                </div>
                                <div class="control">
                                    <Field class="textarea" as="textarea" name="productHolderObservations" v-model="model.productHolderObservations" />
                                </div>
                            </div>

                            <AttachmentBlock :canUpload="!isReadonly" category="ProductHolder" @upload="uploadPhoto" @remove="removePhoto" :files="getFiles('ProductHolder')" />
                        </Section>

                        <Section title="5. Shipper Vessel" :ref="setSectionRef">
                            <div class="field">
                                <div v-html='getFieldLabel("shipperCondition")'></div>
                                <div class="control">
                                    <label class="radio">
                                        <Field type="radio" name="shipperCondition" v-model="model.shipperCondition" :value="constants.Condition.Good" /> Good
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="shipperCondition" v-model="model.shipperCondition" :value="constants.Condition.Missing" /> Missing
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="shipperCondition" v-model="model.shipperCondition" :value="constants.Condition.Damaged" /> Damaged
                                    </label>
                                </div>
                            </div>

                            <div class="field" v-show="isGoodOrDamaged(model.shipperCondition)">
                                <div v-html='getFieldLabel("shipperImpurities")'></div>
                                <div class="select control">
                                    <Field as="select" name="shipperImpurities" v-model="model.shipperImpurities">
                                        <option :value="null"></option>
                                        <option v-for="(option,index) in cleaningOptions" v-bind:key="index" v-bind:value="option">
                                            {{option}}
                                        </option>
                                    </Field>
                                </div>
                            </div>

                            <div class="field" v-show="isGoodOrDamaged(model.shipperCondition)">
                                <div v-html='getFieldLabel("isShipperLabelingAdequate")'></div>
                                <div class="control">
                                    <label class="checkbox">
                                        <Field type="checkbox" name="isShipperLabelingAdequate" :value="true" v-model="model.isShipperLabelingAdequate" /> Yes
                                    </label>
                                </div>
                            </div>

                            <div class="field">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("shipperObservations")'></span>
                                    <span class="has-text-danger md-16 ml-1" v-show="isObservationRequired(model.shipperCondition, model.shipperImpurities) == true">*</span>
                                </div>
                                <div class="control">
                                    <Field class="textarea" as="textarea" name="shipperObservations" v-model="model.shipperObservations" />
                                </div>
                            </div>

                            <AttachmentBlock :canUpload="!isReadonly" category="Shipper" @upload="uploadPhoto" @remove="removePhoto" :files="getFiles('Shipper')" />
                        </Section>

                        <Section title="6. JDE and SENTRY" :ref="setSectionRef">

                            <div class="field">
                                <div v-html='getFieldLabel("jdeOrderNumber")'></div>
                                <div class="control has-icons-right" title="scan ticket">
                                    <Field class="input" type="text" placeholder="JDE Order #" name="jdeOrderNumber" v-model="model.jdeOrderNumber" />
                                    <span class="icon is-small is-right">
                                        <span class="material-icons md-18">barcode_reader</span>
                                    </span>
                                </div>
                            </div>

                            <div class="field">
                                <div v-html='getFieldLabel("isJdeReturnAmended")'></div>
                                <div class="control">
                                    <label class="checkbox">
                                        <Field type="checkbox" name="isJdeReturnAmended" :value="true" v-model="model.isJdeReturnAmended" /> Yes
                                    </label>
                                </div>
                            </div>

                            <div class="field">
                                <div v-html='getFieldLabel("isSentryReturnRegistered")'></div>
                                <div class="control">
                                    <label class="checkbox">
                                        <Field type="checkbox" name="isSentryReturnRegistered" :value="true" v-model="model.isSentryReturnRegistered" /> Yes
                                    </label>
                                </div>
                            </div>

                            <div class="field" v-show="isGood(model.dataLoggerCondition) && isGood(model.probeCondition)">
                                <div>
                                    <span class="is-pulled-left" v-html='getFieldLabel("isDataLoggerReturnedInSentry")'></span>
                                    <span class="has-text-danger md-16 ml-1">*</span>
                                </div>
                                <div class="control">
                                    <label class="checkbox">
                                        <Field type="checkbox" name="isDataLoggerReturnedInSentry" :value="true" v-model="model.isDataLoggerReturnedInSentry" /> Yes
                                    </label>
                                </div>
                            </div>
                        </Section>

                        <div class="is-hidden">
                            <Field type="checkbox" name="hasProbe" :value="true" v-model="hasProbe" />
                            <Field type="checkbox" name="hasShipper" :value="true" v-model="hasShipper" />
                            <Field type="checkbox" name="hasDataLogger" :value="true" v-model="hasDataLogger" />
                            <Field type="checkbox" name="hasProductHolder" :value="true" v-model="hasProductHolder" />
                        </div>

                        <div class="buttons" v-if="!isReadonly">
                            <button class="button is-primary" type="button" @click="saveAsDraft" v-if="model.submitted == null">Save As Draft</button>
                            <button class="button is-success" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<style scoped>
    .errors {
        display: block;
        margin-bottom: 5px;
    }
</style>

