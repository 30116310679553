<script setup>
    import { ref, computed, onMounted } from 'vue';
    import axios from 'axios';
    import utils from '@/utils.js';
    import constants from '@/constants.js';

    const props = defineProps(["shipper"]);
    const shipperModel = ref(null);

    async function load() {
        const response = await axios.get(`/api/ShipperModel/Get/${props.shipper.shipperModelId}`);
        if (response.status !== 200) return;
        shipperModel.value = response.data
    }
     
    const formatBool = (value) => {
        if (value == null) return "";
        if (value == true) return "<div class='has-text-success-dark'>Yes</div>";
        return "<div class='has-text-danger-dark'>No</div>";
    }

    const isMetric = computed(() => props.shipper.unitSystem === constants.UnitSystem.Metric);
    const manufacturing = computed(() => props.shipper?.shipperManufacturing);

    onMounted(() => load());
</script>

<template>
    <div>
        <div class="box" v-if="shipper != null">

            <div class="is-pulled-left mr-3" v-if="shipperModel && shipperModel.images.length > 0">
                <figure class="image">
                    <img v-bind:src="`/api/ShipperModel/Image/${shipperModel.images[0].id}`" style="max-width: 100%; height: auto; max-height: 200px" />
                </figure>
            </div>

            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <span class="label">Serial #</span>
                        <div>{{shipper.serialNumber}}</div>
                    </div>

                    <div class="field">
                        <span class="label">SENTRY Number</span>
                        <div>{{shipper.sentryNumber}}</div>
                    </div>

                    <div class="field" v-if="shipperModel">
                        <label class="label">Shipper Model</label>
                        <div>
                            <router-link :to="{ name : 'EditShipperModel', params: { id: shipperModel.id}}" title="Edit">{{shipperModel.name}}</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field">
                <span class="label">Status</span>
                <div v-html="utils.formatShipperState(shipper.state)"></div>
            </div>

            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <label class="label">Shipper physical condition acceptable for transfer to end-user?</label>
                        <div v-html="formatBool(shipper.isConditionAcceptable)"></div>
                    </div>

                    <div class="field">
                        <label class="label">Tare weight for completely empty shipper without cap.</label>
                        <div>{{utils.formatNumber(shipper.tareWeight,2)}} <span class="units">{{(isMetric ? "kg" : "lb")}}</span></div>
                    </div>
                </div>
            </div>

            <div class="field" v-show="shipper.isConditionAcceptable == false">
                <div>
                    <label class="label">Document observations.<span class="has-text-danger md-16 ml-1">*</span></label>
                </div>
                <div>{{shipper.conditionObservations}}</div>
            </div>

            <div class="field" v-if="manufacturing != null">
                <label class="label">Manufacturing Summary</label>
                <div>
                    Order #{{manufacturing.jdeOrderNumber}} received from {{manufacturing.manufacturer}} on {{utils.formatDate(manufacturing.received)}} via facility
                    <span v-if="manufacturing.facility != null">
                        <router-link :to="{ name : 'EditFacility', params: { id: manufacturing.facilityId}}" title="Edit">{{manufacturing.facility.name}}</router-link>
                    </span>
                    <span v-else>
                        ?
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
