import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import progressBar from '/plugins/progress-bar';

//Styles
import './assets/main.scss'
import 'material-icons/iconfont/material-icons.css';
import '@vuepic/vue-datepicker/dist/main.css';
import "vue-toastification/dist/index.css"

import router from './router';

//Custom components
import App from './App.vue';
import ConfirmModal from './components/ConfirmModal.vue';
import DatePicker from './components/DatePicker.vue';
import MonthPicker from './components/MonthPicker.vue';
import GridPager from './components/GridPager.vue';
import GridHeader from './components/GridHeader.vue';
import UnitEditor from './components/UnitEditor.vue';

//3rd Party Validation with Vee-Validate
import { Form, Field, ErrorMessage } from "vee-validate";
import Toast, { POSITION } from "vue-toastification";
import { useUserStore } from './stores/user'

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);

app.component('ConfirmModal', ConfirmModal);
app.component('GridPager', GridPager);
app.component('GridHeader', GridHeader);
app.component('DatePicker', DatePicker);
app.component('MonthPicker', MonthPicker);
app.component('UnitEditor', UnitEditor);
app.component('Form', Form);
app.component('Field', Field);
app.component('ErrorMessage', ErrorMessage);

app.use(pinia);
app.use(router);
app.use(progressBar, { router });
app.use(Toast, { position: POSITION.TOP_CENTER });

const store = useUserStore();
store.initSession();
app.mount("#app");
