<script setup>
    import { ref, onMounted } from 'vue';
    import utils from '@/utils.js';
    import { useToast } from 'vue-toastification';
    import axios from 'axios';

    const items = ref([]);
    const pagingInfo = ref({ sortKey: "Created", sortOrder: "desc", pageNumber: 1, pageSize: 10 });
    const confirmModal = ref(null);

    async function search(parms) {                
        if (parms) pagingInfo.value = Object.assign(pagingInfo.value, parms);
        const response = await axios.post(`/api/PackingList/PagedList`, pagingInfo.value);            
        if (response.status !== 200) return;
        items.value = response.data.rows;
        pagingInfo.value = response.data.pagingInfo;
    }

    async function deleteRow(row) {
        await confirmModal.value.open("Delete Packing List?", `Are you sure you want to delete packing list #${row.id}?</br></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/PackingList/Delete/${row.id}`);

        if (response.status === 200) {
            let index = items.value.indexOf(row);
            items.value.splice(index, 1);
            useToast().success("The packing list was deleted successfully.");
        }
        else
            useToast().error("Unable to delete, perhaps the record is in use?");
    }

    onMounted(() => search());
</script>

<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">pallet</i> Packing Lists</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <router-link class="button" :to="{ name : 'AddPackingList' }">
                        <i class="material-icons">add</i>
                    </router-link>
                </div>
            </div>
        </div>

        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="table-container" id="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                <thead class="has-background-white-ter">
                    <tr>
                        <th class="is-narrow"></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="Id" v-on:sorted="search">#</GridHeader></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="Facility.Name" v-on:sorted="search">Destination</GridHeader></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="Created" v-on:sorted="search">Created</GridHeader></th>
                        <th><GridHeader :pageInfo="pagingInfo" sort-key="LastModified" v-on:sorted="search">Last Modified</GridHeader></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td class="nowrap">
                            <router-link class="has-text-black" :to="{ name : 'EditPackingList', params: { id: item.id}}" title="Edit">
                                <i class="material-icons md-18">edit</i>
                            </router-link>
                            <a class="has-text-black" title="Delete" v-on:click="deleteRow(item)">
                                <i class="material-icons md-18">delete</i>
                            </a>
                            <a class="has-text-black ml-3" title="Export to excel"
                               v-bind:href="`/api/PackingList/Export/${item.id}`" target="_parent"><i class="material-icons md-18">download</i></a>
                        </td>
                        <td class="is-narrow" align="right">{{item.id}}</td>
                        <td><span v-if="item.facility">{{item.facility.name}}</span></td>
                        <td align="right" width="180px">{{utils.formatDate(item.created)}}</td>
                        <td align="right" width="150px"><span v-if="item.lastModified">{{utils.formatDate(item.lastModified)}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <GridPager :pageInfo="pagingInfo" v-on:paged="search"></GridPager>
    </div>
</template>

