<script setup>
    import { ref, computed } from 'vue';
    import { useUserStore } from '@/stores/user'    

    const store = useUserStore();
    const demoMode = computed(() => store.demoMode);
    const confirmModal = ref(null);

    async function toggleDemoMode() {
        if (demoMode.value == false) {
            await confirmModal.value.open("Activate Demo Mode?", "You are about to active demo mode. Demo mode will hide customer sensitive data. Are you sure you want to proceed?");
            store.setDemoMode(true);
        }
        else
            store.setDemoMode(false);
    }
</script>

<template>
    <div class="container box">
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <p class="title mb-2">Demo Mode</p>
        <p>Demo mode will hide sensitive data. This allows you to demo the capabilities of the site to external parties without exposing any customer information.</p>

        
        <button type="button"
                v-bind:class="{'is-success' : demoMode == false, 'is-danger': demoMode == true }"
                @click="toggleDemoMode" 
                class="button mt-4">{{(demoMode == true ? "Exit" : "Start")}} Demo Mode</button>
    </div>
</template>