<script setup>
    import { ref, watch, computed, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import axios from 'axios';
    import * as yup from 'yup';
    import utils from '@/utils.js';
    import constants from '@/constants.js';
    import { useToast } from 'vue-toastification';

    const schema = yup.object({
        model: yup.string().nullable().required(),
        productCode: yup.string().nullable().required().label("product code"),
        withoutHolderProductCode: yup.string().nullable().required().label("without holder code"),
        fillServiceProductCode: yup.string().nullable().required().label("fill service code"),
        temperatureProbeProductCode: yup.string().nullable().required().label("temperature probe code"),
        nextDayAirShippingProductCode: yup.string().nullable().required().label("next day air shipping code"),
        groundShippingProductCode: yup.string().nullable().required().label("ground shipping code"),
        cutOffSublimationRate: yup.number().nullable().required().label("cut off sublimation rate"),
        maxDryIceFillWeight: yup.number().nullable().required().label("max day ice fill weight"),
        dryIceHoldTime: yup.number().typeError("required").required("required"),
        packageLength: yup.number().typeError("required").required("required"),
        packageWidth: yup.number().typeError("required").required("required"),
        packageHeight: yup.number().typeError("required").required("required"),
        totalPackageWeight: yup.number().typeError("required").required("required"),
        temperatureProbeCalibrationRequirement: yup.number().typeError("required").required("required"),                                                                
        warrantyReplacementMaxSublimationDeteriorationRate: yup.number().typeError("required").required("required"),
        warrantyReVacMaxSublimationRate: yup.number().typeError("required").required("required"),
        postRevacMaxSublimationRate: yup.number().typeError("required").required("required"),
        minEmptyWeightWithoutCap: yup.number().typeError("required").required("required"),
        maxEmptyWeightWithoutCap: yup.number().typeError("required").required("required"),
        minEmptyWeightWithCap: yup.number().typeError("required").required("required"),
        maxEmptyWeightWithCap: yup.number().typeError("required").required("required")
    });

    const model = ref(null);
    const modified = ref(false);
    const router = useRouter();
    const route = useRoute();
    const confirmModal = ref(null);

    async function onSubmit() {
        const response = await axios.post('/api/ShipperModel/Save', model.value);
        if (response.status !== 200) return;
        modified.value = false;
        model.value = response.data;
        await router.push({ name: 'ShipperModels' });
        useToast().success("The shipper model was saved successfully.");
    }

    async function upload(event) {
        model.value.images = []; //"clear" the existing images first
        let formData = new FormData();
        for (let i = 0; i < event.target.files.length; i++)
            formData.append('file', event.target.files[i]);

        axios.post('/api/ShipperModel/Upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (response.status !== 200) return;
        response.data.forEach((x, index) => {
            x.objectUri = URL.createObjectURL(event.target.files[index]);
            model.value.images.push(x);
        });
    }

    async function switchUnits(event) {        
        let targetUnits = parseInt(event.currentTarget.value);

        if (model.value.unitSystem == targetUnits) return;

        //if the case is new, don't prompt with the modal dialog, just switch the units and do not convert
        if (model.value.id <= 0) {
            model.value.unitSystem = targetUnits;
            return;
        }

        event.preventDefault();

        await confirmModal.value.open("Switch Units?", `Are you sure you want to switch the units?<br/><br/>Please note that this will not convert the values entered for this shipper model.`);            
        model.value.unitSystem = model.value.unitSystem == 0 ? 1 : 0;
        event.target.checked = true;
    }

    const isMetric = computed(() => model.value.unitSystem === constants.UnitSystem.Metric);

    onMounted( async () => {
        const response = await axios.get(`/api/ShipperModel/Get/${route.params.id || ""}`);
            
        if (response.status === 204) {
            useToast().info(`No shipper model found with id #${route.params.id}.`);
            return;
        }

        if (response.status !== 200) return;
        model.value = response.data;

        watch( () => model.value, () => modified.value = true );
    })
</script>

<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <p class="title">
                    <i class="material-icons">category</i>&nbsp; {{$route.params.id ? "Edit" : "Add"}} Shipper Model
                </p>
            </div>
            <div class="column">
                <router-link class="button is-pulled-right" :to="{ name : 'ShipperModels' }" title="Return to List">
                    <i class="material-icons">list</i>
                </router-link>
            </div>
        </div>

        <ConfirmModal ref="confirmModal" />

        <Form @submit="onSubmit" :validation-schema="schema" v-if="model">            
            <div class="box">
                <div class="columns">
                    <div class="column is-6">
                        <div class="field">
                            <label class="label">Unit System</label>
                            <div class="control">
                                <label class="radio">
                                    <input type="radio" name="unitSystem" :value="constants.UnitSystem.Metric" v-model="model.unitSystem" @click="switchUnits($event)"> Metric
                                </label>
                                <label class="radio">
                                    <input type="radio" name="unitSystem" :value="constants.UnitSystem.Imperial" v-model="model.unitSystem" @click="switchUnits($event)"> Imperial
                                </label>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">Model</label>
                                    <div class="control">
                                        <Field class="input" type="text" name="model" v-model="model.name" />
                                        <ErrorMessage class="errors" name="model" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="title is-5"><i class="material-icons">inventory</i> Packaging</p>

                        <div class="field is-horizontal">
                            <div class="field">
                                <label class="label">Length</label>
                                <div class="control">
                                    <UnitEditor name="packageLength" v-model.number="model.packageLength">{{isMetric ? "cm" : "in"}}</UnitEditor>
                                    <ErrorMessage class="errors" name="packageLength" />
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Width</label>
                                <div class="control">
                                    <UnitEditor name="packageWidth" v-model.number="model.packageWidth">{{isMetric ? "cm" : "in"}}</UnitEditor>
                                    <ErrorMessage class="errors" name="packageWidth" />
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field">
                                <label class="label">Height</label>
                                <div class="control">
                                    <UnitEditor name="packageHeight" v-model.number="model.packageHeight">{{isMetric ? "cm" : "in"}}</UnitEditor>
                                    <ErrorMessage class="errors" name="packageHeight" />
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Total Weight with Dry Ice</label>
                                <div class="control">
                                    <UnitEditor name="totalPackageWeight" v-model.number="model.totalPackageWeight">{{isMetric ? "kg" : "lb"}}</UnitEditor>
                                    <ErrorMessage class="errors" name="totalPackageWeight" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-for="(image,idx) in model.images" v-bind:key="idx">
                            <figure class="image">
                                <img v-bind:src="image.objectUri ? image.objectUri : `/api/ShipperModel/Image/${image.id}`"
                                     style="max-width: 100%; height: auto; max-height: 310px" />
                            </figure>
                        </div>

                        <div class="field">
                            <div class="file has-name is-fullwidth">
                                <label class="file-label">
                                    <input class="file-input" type="file" id="file" ref="file" accept=".gif,.jpg,.jpeg,.png" v-on:change="upload($event)" />
                                    <span class="file-cta">
                                        <span class="file-icon"><i class="material-icons">photo</i></span>
                                    </span>
                                    <span id="photo-filename" class="file-name"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <p class="title is-5"><i class="material-icons">qr_code</i> Product Codes</p>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Product Code</label>
                            <div class="control">
                                <Field class="input" type="text" name="productCode" v-model="model.productCode" />
                                <ErrorMessage class="errors" name="productCode" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Product Code (without holder)</label>
                            <div class="control">
                                <Field class="input" type="text" name="withoutHolderProductCode" v-model="model.withoutHolderProductCode" />
                                <ErrorMessage class="errors" name="withoutHolderProductCode" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Temperature Probe Code</label>
                            <div class="control">
                                <Field class="input" type="text" name="temperatureProbeProductCode" v-model="model.temperatureProbeProductCode" />
                                <ErrorMessage class="errors" name="temperatureProbeProductCode" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Fill Service Code</label>
                            <div class="control">
                                <Field class="input" type="text" name="fillServiceProductCode" v-model="model.fillServiceProductCode" />
                                <ErrorMessage class="errors" name="fillServiceProductCode" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Ground Shipping Code</label>
                            <div class="control">
                                <Field class="input" type="text" name="groundShippingProductCode" v-model="model.groundShippingProductCode" />
                                <ErrorMessage class="errors" name="groundShippingProductCode" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Next Day Air Shipping Code</label>
                            <div class="control">
                                <Field class="input" type="text" name="nextDayAirShippingProductCode" v-model="model.nextDayAirShippingProductCode" />
                                <ErrorMessage class="errors" name="nextDayAirShippingProductCode" />
                            </div>
                        </div>
                    </div>
                </div>

                <p class="title is-5"><i class="material-icons">rule</i> Guidelines</p>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">
                                Temperature Probe Calibration Requirement
                            </label>
                            <div class="control">
                                <UnitEditor name="temperatureProbeCalibrationRequirement" v-model.number="model.temperatureProbeCalibrationRequirement">days</UnitEditor>
                                <ErrorMessage class="errors" name="temperatureProbeCalibrationRequirement" />
                            </div>
                        </div>
                    </div>
                </div>

                <p class="title is-5"><i class="material-icons">ac_unit</i> Dry Ice</p>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Max Dry Ice Fill Weight</label>
                            <div class="control">
                                <UnitEditor name="maxDryIceFillWeight" v-model.number="model.maxDryIceFillWeight">{{isMetric ? "kg" : "lb"}}/day</UnitEditor>
                                <ErrorMessage class="errors" name="maxDryIceFillWeight" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">
                                Dry Ice Hold Time
                                <span class="material-icons md-18 ml-1 p-0" title="The minimum number of days the shipper will keep the contents frozen if the shipper is filled with dry ice">info</span>
                            </label>
                            <div class="control">
                                <UnitEditor name="dryIceHoldTime" v-model.number="model.dryIceHoldTime">days</UnitEditor>
                                <ErrorMessage class="errors" name="dryIceHoldTime" />
                            </div>
                        </div>
                    </div>
                </div>

                <p class="title is-5"><i class="material-icons">speed</i>  Sublimation Rates</p>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Cut-off<br />Sublimation Rate</label>
                            <div class="control">
                                <UnitEditor name="cutOffSublimationRate" v-model.number="model.cutOffSublimationRate">{{isMetric ? "kg" : "lb"}}/day</UnitEditor>
                                <ErrorMessage class="errors" name="cutOffSublimationRate" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Post Revac. Max<br />Sublimation Rate</label>
                            <div class="control">
                                <UnitEditor name="postRevacMaxSublimationRate" v-model.number="model.postRevacMaxSublimationRate">
                                    {{isMetric ? "kg" : "lb"}}/day
                                </UnitEditor>
                                <ErrorMessage class="errors" name="postRevacMaxSublimationRate" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Warranty Revac. Max<br />Sublimation Rate</label>
                            <div class="control">
                                <UnitEditor name="warrantyReVacMaxSublimationRate" v-model.number="model.warrantyReVacMaxSublimationRate">
                                    {{isMetric ? "kg" : "lb"}}/day
                                </UnitEditor>
                                <ErrorMessage class="errors" name="warrantyReVacMaxSublimationRate" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">
                                Max Sublimation Rate<br />for Qualification
                            </label>
                            <div class="control">
                                <UnitEditor name="maxSublimationRateForQualification" v-model.number="model.maxSublimationRateForQualification">
                                    {{isMetric ? "kg" : "lb"}}/day
                                </UnitEditor>
                                <ErrorMessage class="errors" name="maxSublimationRateForQualification" />
                            </div>
                        </div>
                    </div>
                </div>

                <p class="title is-5"><i class="material-icons">speed</i> Sublimation Deterioration Rates</p>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Customer Max Sublimation Deterioration Rate</label>
                            <div class="control">
                                <UnitEditor name="customerMaxSublimationDeteriorationRate" v-model.number="model.customerMaxSublimationDeteriorationRate">
                                    {{isMetric ? "kg" : "lb"}}/day/day
                                </UnitEditor>
                                <ErrorMessage class="errors" name="customerMaxSublimationDeteriorationRate" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Warranty Replacement Max Sublimation Deterioration Rate</label>
                            <div class="control">
                                <UnitEditor name="warrantyReplacementMaxSublimationDeteriorationRate" v-model.number="model.warrantyReplacementMaxSublimationDeteriorationRate">
                                    {{isMetric ? "kg" : "lb"}}/day/day
                                </UnitEditor>
                                <ErrorMessage class="errors" name="warrantyReplacementMaxSublimationDeteriorationRate" />
                            </div>
                        </div>
                    </div>
                </div>

                <p class="title is-5"><i class="material-icons">scale</i> Shipper Weights</p>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Shipper Min Empty Weight <em class="has-text-weight-normal">Without</em> Cap</label>
                            <div class="control">
                                <UnitEditor name="minEmptyWeightWithoutCap" v-model.number="model.minEmptyWeightWithoutCap">
                                    {{isMetric ? "kg" : "lb"}}
                                </UnitEditor>
                                <ErrorMessage class="errors" name="minEmptyWeightWithoutCap" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Shipper Max Empty Weight <em class="has-text-weight-normal">Without</em> Cap</label>
                            <div class="control">
                                <UnitEditor name="maxEmptyWeightWithoutCap" v-model.number="model.maxEmptyWeightWithoutCap">
                                    {{isMetric ? "kg" : "lb"}}
                                </UnitEditor>
                                <ErrorMessage class="errors" name="maxEmptyWeightWithoutCap" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Shipper Min Empty Weight <em class="has-text-weight-normal">With</em> Cap</label>
                            <div class="control">
                                <UnitEditor name="minEmptyWeightWithCap" v-model.number="model.minEmptyWeightWithCap">
                                    {{isMetric ? "kg" : "lb"}}
                                </UnitEditor>
                                <ErrorMessage class="errors" name="minEmptyWeightWithCap" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Shipper Max Empty Weight <em class="has-text-weight-normal">With</em> Cap</label>
                            <div class="control">
                                <UnitEditor name="maxEmptyWeightWithCap" v-model.number="model.maxEmptyWeightWithCap">
                                    {{isMetric ? "kg" : "lb"}}
                                </UnitEditor>
                                <ErrorMessage class="errors" name="maxEmptyWeightWithCap" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns mt-2">
                    <div class="column">
                        <div class="buttons is-pulled-right">
                            <button type="submit" class="button is-info"><i class="material-icons md-18 mr-1">save</i>Save</button>
                            <router-link class="button" :to="{ name : 'ShipperModels' }"><i class="material-icons md-18 mr-1">cancel</i>Cancel</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

<style scoped>
    .box .title {
        color: #005591;
        margin-top: 25px;
    }
</style>