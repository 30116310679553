import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import axios from 'axios';

export default { 
    install: (app, options) => {
        const { router } = options;

        //Wire up vue router
        router.beforeResolve((to, from, next) => {
            // If this isn't an initial page load.
            if (to.name) NProgress.start()            
            next()
        })

        router.afterEach(() => {
            NProgress.done();
        })

        //Wire up axios interceptor
        axios.interceptors.request.use((config) => {
            NProgress.start();
            return config;
        });

        axios.interceptors.response.use(
            (response) => {
                NProgress.done();
                return response;
            },
            (error) => {
                NProgress.done();
                return error;
            }
        );
    }
}