<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        icon: {
            type: String,
            default: 'question_mark'
        },
        title: {
            type: String,
            default: 'Prompt'
        },
        caption: {
            type: String,
            default: 'Enter a value:'
        }
    });

    const text = ref("");
    var onSubmit = null;
    const root = ref(null);

    function open( initialValue ) {
        text.value = initialValue || '';

        //return promise
        return new Promise((resolve) => {
            //assign callbacks
            onSubmit = () => {
                resolve(text.value);
                toggleDisplay(false);
            };
            toggleDisplay(true);
        })
    }

    function toggleDisplay(value) {
        root.value.style.display = value ? "block" : "none";
    }

    defineExpose({
        open
    });
</script>

<template>
    <div class="modal" ref="root">
        <div class="modal-background"></div>
        <div class="modal-card mt-6">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <i class="material-icons" style="font-size:24px">{{icon}}</i> {{title}}
                </p>
                <button type="button" class="delete" aria-label="close" v-on:click="toggleDisplay(false)"></button>
            </header>
            <section class="modal-card-body">
                <div class="field">
                    <div class="control">
                        <label class="label">{{caption}}</label>
                        <input type="text" class="input" v-model="text" />
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot" style="justify-content:flex-end">
                <div class="buttons">
                    <button type="submit" class="button is-success" @click="onSubmit()"><i class="material-icons mr-1">check</i>OK</button>
                    <button type="button" class="button is-danger" @click="toggleDisplay(false)"><i class="material-icons mr-1">cancel</i>Cancel</button>
                </div>
            </footer>
        </div>
    </div>
</template>

