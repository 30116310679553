<script setup>
    import { ref, getCurrentInstance } from 'vue';
    import axios from 'axios';
    import * as yup from 'yup';
    import moment from 'moment';    
    import constants from '@/constants.js';
    import utils from '@/utils.js';

    const props = defineProps(["user", "shipper"]);

    const schema = yup.object({
        isExternal: yup.boolean().required().nullable().label("responsible party"),
        facilityId: yup.number().nullable()
            .when('isExternal', { is: (x) => x == false, then: () => yup.number().nullable().required() })
            .label("facility"),
        performed: yup.date().nullable()
            .test("value_in_past",
                "The date must be in the past.",
                function (value) {
                    if (value == null) return true;
                    return moment(value) <= moment();
                }
            )
            .label("date performed"),
        comments: yup.string().nullable().label("comments")
    });

    const model = ref(null);
    const facilities = ref([]);
    const root = ref(null);
    const action = ref("");
    const parent = null;
    let resolver = null;        
        
    async function loadLookups() {
        const response = await axios.get(`/api/Facility/List/${constants.FacilityFlags.CentralOperations}`);
        if (response.status !== 200) return;
        facilities.value = response.data;
    }

    async function load(id) {
        loadLookups();
        const response = await axios.get(`/api/Shipper/GetVacuumMaintenance/${id || ""}`);
        if (response.status !== 200) return;
        model.value = response.data;
    }
              
    async function onSubmit() {
        model.value.shipperId = props.shipper.id;
        if (model.value.isExternal == true) model.value.facilityId = null;
        const response = await axios.post('/api/Shipper/SaveVacuumMaintenance', model.value);
        if (response.status !== 200) return;
        model.value = response.data;            
        toggleDisplay(false);
        resolver(model.value);
    }

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";

    function open(id) {        
        action.value = id == null ? "Add" : "Edit";
        load();

        return new Promise((resolve) => {
            resolver = resolve;
            toggleDisplay(true);
        })
    }

    defineExpose({
        open
    })
</script>

<template>
    <div class="modal" ref="root">
        <Form @submit="onSubmit" :validation-schema="schema" @keydown.enter="$event.preventDefault()" @keydown.escape="toggleDisplay(false)">            
            
            <div class="modal-background"></div>
            <div class="modal-card mt-2">
                <header class="modal-card-head">
                    <p class="modal-card-title"><span class="material-icons">history</span>{{action}} Vacuum Maintenance</p>
                    <button type="button" class="delete" aria-label="close" v-on:click="toggleDisplay(false)" title="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="model != null">
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <label class="label">Responsible Party</label>
                                    <div class="control">
                                        <label class="radio">
                                            <Field type="radio" name="isExternal" v-model="model.isExternal" :value="false" />
                                            Linde
                                        </label>
                                        <label class="radio">
                                            <Field type="radio" name="isExternal" v-model="model.isExternal" :value="true"/>
                                            Manufacturer
                                        </label>
                                    </div>
                                    <ErrorMessage class="errors" name="isExternal" />
                                </div>

                                <div class="field">
                                    <label class="label">Facility where maintenance was performed</label>
                                    <div class="select control is-fullwidth">
                                        <Field as="select" name="facilityId" v-model="model.facilityId" v-bind:disabled="model.isExternal == true">
                                            <option :value="null"></option>
                                            <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                                {{option.name}}
                                            </option>
                                        </Field>
                                    </div>
                                    <ErrorMessage class="errors" name="facilityId" />
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Date Performed</label>
                            <div class="control">
                                <DatePicker v-model="model.performed" name="performed"></DatePicker>
                                <ErrorMessage class="errors" name="performed" />
                            </div>
                        </div>

                        <div class="field">
                            <div class="control">
                                <label class="label">Comments</label>
                                <Field class="textarea" as="textarea" name="comments" placeholder="Enter comments" v-model="model.comments" />
                                <ErrorMessage class="errors" name="comments" />
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot is-justify-content-right">
                    <div class="buttons">
                        <button type="submit" class="button is-success">OK</button>
                        <button type="button" class="button" @click="toggleDisplay(false)">Cancel</button>
                    </div>
                </footer>
            </div>
        </Form>
    </div>
</template>