import { defineStore } from 'pinia'
import axios from 'axios';
import { useMsalPopup } from "@/services/msal";
const environment = process.env.NODE_ENV;

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            _user: null,
            _impersonatedUser: null,
            _alertCount: 0,
            _idleTimeout: null,
            _expiresIn: null,
            _demoMode: false
        }
    },
    getters: {
        isAuthenticated: (state) => state._user != null,
        user: (state) => state._user,
        alertCount: (state) => state._alertCount,
        impersonatedUser: (state) => state._impersonatedUser,
        expiresIn: (state) => state._expiresIn,
        demoMode: (state) => state._demoMode
    },
    actions: {
        async initSession() {
            if (this._user == null) return;            
            const response = await axios.post("/api/Users/InitSession", [this._user, this._impersonatedUser]);
            if (response.status !== 200) return;                        
            this._processAuthResponse(response);
        },

        async singleSignOn() {                   
            var useAzure = window.location.href.indexOf('seccurus.linde.com') >= 0;
            if (useAzure) {
                const credentials = await useMsalPopup(); //authenticate
                const response = await axios.post("/api/Users/AzureAuth", credentials); //authorize
                return this._processAuthResponse(response);
            }
            
            const response = await axios.post("/api/Users/InternalAuth");
            return this._processAuthResponse(response);            
        },

        async formsAuthenticate(email, password) {
            const response = await axios.post("/api/Users/Authenticate", { "email": email, "password": password });
            return this._processAuthResponse(response);
        },                  

        async keepAlive() {
            this._expiresIn = this._idleTimeout;
        },

        async setDemoMode(val) {            
            this._demoMode = val;
        },
        
        async impersonate(id) {            
            const response = await axios.post(`/api/Users/Impersonate/${id}`);
            if (response.status !== 200) return;
            this._impersonatedUser = response.data.user;
        },

        async exitImpersonation() {
            const response = await axios.post("/api/Users/ExitImpersonation");
            if (response.status !== 200) return;
            this._impersonatedUser = null;
        },

        async logOut() {
            const response = await axios.post("/api/Users/LogOut");
            if (response.status !== 200) return;            
            this._idleTimeout = null;
            this._alertCount = 0;
            this._expiresIn = null;
            this._user = null;
            this._impersonatedUser = null;  
            this._demoMode = false;
        },

        //************************* private methods *************************
        async _monitorAlerts() {
            const notificationChecker = async () => {
                if (this._user === null) return;
                const response = await axios.get("/api/Alerts/Count");
                if (response.status !== 200) return;
                this._alertCount = response.data;
            };

            //setup a timer to check for notifications
            notificationChecker();
            setInterval(notificationChecker, 60 * 5 * 1000); //check every 5 minutes  
        },

        async _monitorSession() {
            const sessionChecker = async () => {
                if (this._user === null) return;

                this._expiresIn = this._expiresIn - 1;

                if (this._expiresIn <= 0) {
                    clearInterval(intervalId);
                    await this.logOut();
                    alert("You session has expired due to inactivity. You have been automatically logged out of the system.");
                }
            };

            var intervalId = setInterval(sessionChecker, 60 * 1 * 1000); //timer runs once a minute
        },

        async _processAuthResponse(response) {
            if (response.status !== 200 || response?.data?.authenticated == false)
                throw new Error(response?.data?.message || "Unable to login. Please contact a system adminstrator.");

            this._user = response.data.user;
            this._impersonatedUser = response.data.impersonatedUser;
            this._idleTimeout = response.data.expiresIn;
            this._expiresIn = response.data.expiresIn;

            this._monitorAlerts();
            this._monitorSession();
        }
    },
    persist: true
})