<script setup>
    import { ref } from 'vue';
    import * as yup from 'yup';
    import { useToast } from 'vue-toastification';
    import axios from 'axios';

    const schema = yup.object().shape({
        filename: yup.string().nullable().required("required")
    });

    const fileControl = ref(null);
    const log = ref(null);
    const state = ref({ text: "", busy: false });
    const filename = ref("");

    function onFileChange(event) {
        let fileData = event.target.files[0];
        filename.value = fileData.name;
    }

    async function onSubmit() {
        let formData = new FormData();

        formData.append('file', fileControl.value.files[0]);

        state.value.busy = true;
        state.value.text = "Importing, this may take a while depending on the size of your record set. Go grab a coffee and come back in a few minutes...";

        try {
            const response = await axios.post('/api/Manufacturing/Import', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.status !== 200) {
                log.value = null;
                useToast().error(response);
            }

            log.value = response.data.log;
            let warningText = "Warnings generally indicate validation issues or a misalignment between the spreadsheet and the database.";

            if (log.value.errors > 0)
                useToast().error(`Data imported with ${log.value.errors} error(s) and ${log.value.warnings} warnings. Please review the log below for more details. ${warningText}`);

            if (log.value.errors == 0 && log.value.warnings > 0)
                useToast().warning(`Data imported with ${log.value.warnings} warnings. ${warningText}`);

            if (log.value.errors == 0 && log.value.warnings == 0)
                useToast().success("Data imported successfully.");            
        }
        finally {
            state.value.busy = false;
            fileControl.value = null;
            filename.value = "";
        }
    }
</script>

<template>
    <div class="container box">
        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons mr-1">storage</i> Import Shipper Manufacturing Data</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons is-pulled-right">                    
                    <router-link class="button is-pulled-right" :to="{ name : 'Manufacturing' }" title="Return to List">
                        <i class="material-icons sm">keyboard_backspace</i>
                    </router-link>
                </div>
            </div>
        </div>
        <p class="subtitle">Import data from an Excel workbook...</p>

        <Form :validation-schema="schema" @submit="onSubmit">
            <div class="field mt-2">
                <label class="label">Excel Workbook</label>
                <div class="file has-name is-fullwidth is-right">
                    <label class="file-label">
                        <Field name="file">
                            <input class="file-input" type="file" ref="fileControl" @change="onFileChange"/>
                        </Field>

                        <span class="file-cta">
                            <span class="file-icon">
                                <i class="material-icons">text_snippet</i>
                            </span>
                            <span class="file-label">
                                Browse for file…
                            </span>
                        </span>
                        <span class="file-name">
                            <Field type="hidden" v-model="filename" name="filename"></Field>
                            {{filename}}
                        </span>
                    </label>
                </div>

                <ErrorMessage class="errors" name="filename" />
            </div>

            <div class="columns" v-if="state.busy == false">
                <div class="column">
                    <div class="buttons mt-3">
                        <button type="submit" class="button is-info"><i class="material-icons">publish</i>&nbsp;Import</button>
                    </div>
                </div>
            </div>
        </Form>

        <div class="columns" v-if="state.busy == true">
            <div class="column">
                <div class="column heading-md">{{state.text}}</div>
                <progress class="column progress is-large is-info" style="clear:both" max="100">60%</progress>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="control">
                    <textarea class="textarea" rows="20" v-if="log && log.contents" v-model="log.contents"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
