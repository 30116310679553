<script setup>
    import { ref, onMounted, computed } from 'vue';
    import axios from 'axios';
    import utils from '@/utils.js';
    import { useToast } from 'vue-toastification';
    import constants from '@/constants.js';

    const props = defineProps(["user"]);
    const rows = ref([]);
    const pageInfo = ref({ sortKey: 'Name', pageSize: 25 });
    const confirmModal = ref(null);

    async function search(parms) {        
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);
        const response = await axios.post("/api/ShipperModel/PagedList", pageInfo.value);
        if (response.status !== 200) return;        
        rows.value= response.data.rows;
        pageInfo.value = response.data.pageInfo;
    }

    async function deleteRow(row) {
        await confirmModal.value.open("Delete Shipper Model?", `Are you sure you want to delete this shipper model?</br></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/ShipperModel/Delete/${row.id}`);

        if (response.status !== 200) {
            useToast().error("Unable to delete, perhaps the record is in use?");
            return;
        }

        rows.value.splice(rows.value.indexOf(row), 1);
        useToast().success("The record was deleted successfully.");
    }

    const isMetric = computed(() => props.user.unitSystem === constants.UnitSystem.Metric);

    onMounted(() => search());
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">category</i> Shipper Models</p>
            </div>
            <div class="column is-narrow">
                <router-link class="button" :to="{ name : 'AddShipperModel' }">
                    <i class="material-icons">add</i>
                </router-link>
            </div>
        </div>

        <div class="table-container">
            <table class="table is-striped is-hoverable is-bordered is-fullwidth">
                <thead class="has-background-white-ter">
                    <tr>
                        <th rowspan="2" class="is-narrow"></th>
                        <th rowspan="2"><GridHeader :pageInfo="pageInfo" sort-key="Name" v-on:sorted="search">Name</GridHeader></th>
                        <th rowspan="2"><GridHeader :pageInfo="pageInfo" sort-key="CutOffSublimationRate" v-on:sorted="search">Cut-Off<br />Sublimation Rate<br />({{isMetric ? "kg/day" : "lb/day"}})</GridHeader></th>
                        <th rowspan="2"><GridHeader :pageInfo="pageInfo" sort-key="MaxDryIceFillWeight" v-on:sorted="search">Maximum Dry<br />Ice Fill Weight<br />({{isMetric ? "kg" : "lb"}})</GridHeader></th>
                        <th rowspan="2"><GridHeader :pageInfo="pageInfo" sort-key="DryIceHoldTime" v-on:sorted="search">Dry Ice<br />Hold Time<br />(days)</GridHeader></th>
                        <th colspan="3"><GridHeader :pageInfo="pageInfo" sort-key="PackageLength" v-on:sorted="search">Package Dimensions ({{isMetric ? "cm" : "in"}})</GridHeader></th>
                        <th colspan="2">Empty Weight<br />with Cap ({{isMetric ? "kg" : "lb"}})</th>
                        <th colspan="2">Empty Weight<br />without Cap ({{isMetric ? "kg" : "lb"}})</th>
                        <th rowspan="2"><GridHeader :pageInfo="pageInfo" sort-key="TotalPackageWeight" v-on:sorted="search">Total Package Weight<br />with Dry Ice<br />({{isMetric ? "kg" : "lb"}})</GridHeader></th>
                    </tr>
                    <tr>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Min</th>
                        <th>Max</th>
                        <th>Min</th>
                        <th>Max</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in rows" v-bind:key="item.id">
                        <td>
                            <div class="nowrap">
                                <router-link class="has-text-black" :to="{ name : 'EditShipperModel', params: { id: item.id}}" title="Edit"><i class="material-icons md-18">edit</i></router-link>
                                <a class="has-text-black" title="Delete" v-on:click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>
                            </div>
                        </td>
                        <td class="nowrap">{{item.name}}</td>
                        <td align="right">{{utils.formatNumber(item.cutOffSublimationRate,3)}}</td>
                        <td align="right">{{utils.formatNumber(item.maxDryIceFillWeight,2)}}</td>
                        <td align="right">{{item.dryIceHoldTime}}</td>
                        <td align="right">{{utils.formatNumber(item.packageLength,2)}}</td>
                        <td align="right">{{utils.formatNumber(item.packageWidth,2)}}</td>
                        <td align="right">{{utils.formatNumber(item.packageHeight,2)}}</td>
                        <td>{{utils.formatNumber(item.minEmptyWeightWithCap,2)}}</td>
                        <td>{{utils.formatNumber(item.maxEmptyWeightWithCap,2)}}</td>
                        <td>{{utils.formatNumber(item.minEmptyWeightWithoutCap,2)}}</td>
                        <td>{{utils.formatNumber(item.maxEmptyWeightWithoutCap,2)}}</td>
                        <td align="right">{{utils.formatNumber(item.totalPackageWeight,2)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
    </div>
</template>

