<script setup>
    import { computed } from 'vue';

    const emit = defineEmits(["paged"]);

    const props = defineProps({
        pageInfo: {
            type: Object,
            default: () => ({
                pageSize: 10,
                search: ""
            })
        },
        pageSizes: {
            type: Array,
            default: () => [
                5,
                10,
                15,
                20,
                25,
                50,
                100,
                250,
                500,
                1000
            ]
        }
    });

    function firstPage() {
        emit("paged", { "pageNumber": 1 });
    }

    function lastPage() {
        emit("paged", { "pageNumber": props.pageInfo.pageCount || 1 });
    }

    function nextPage() {
        if (!hasNextPage) return;
        emit("paged", { "pageNumber": props.pageInfo.pageNumber + 1 });
    }

    function previousPage() {
        if (!hasPreviousPage) return;        
        emit("paged", { "pageNumber": props.pageInfo.pageNumber - 1 });
    };

    const pageSize = computed({
        get() { return props.pageInfo.pageSize },
        set(value) { emit("paged", { "pageNumber": 1, "pageSize": value }) }
    });

    const pageNumber = computed({
        get() { return props.pageInfo.pageNumber },
        set(value) { emit("paged", { "pageNumber": value, "pageSize": props.pageInfo.pageSize }) }
    });            

    const hasNextPage = computed(() => props.pageInfo.pageNumber < props.pageInfo.pageSize);
    const hasPreviousPage = computed(() => props.pageInfo.pageNumber > 1);
    const startingRowNumber = computed(() => props.pageInfo.pageNumber * props.pageInfo.pageSize - (props.pageInfo.pageSize - 1));
    const endingRowNumber = computed(() => (props.pageInfo.pageNumber * props.pageInfo.pageSize) > props.pageInfo.rowCount
        ? props.pageInfo.rowCount
        : (props.pageInfo.pageNumber * props.pageInfo.pageSize));
</script>

<template>
    <div class="columns">
        <div class="column is-narrow is-hidden-touch">
            <div class="field is-horizontal">
                <div class="field-label is-normal nowrap" style="min-width:70px">Page Size</div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select">
                                <select v-model="pageSize">
                                    <option v-for="(option,index) in pageSizes" v-bind:key="index" v-bind:value="option">{{option}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <span class="buttons is-centered">
                <a class="button" @click="firstPage()" title="First Page"><i class="material-icons">first_page</i></a>
                <a class="button" @click="previousPage()" title="Previous Page"><i class="material-icons">keyboard_arrow_left</i></a>
                <span class="mr-1">Page {{pageInfo.pageNumber || 0}} of {{pageInfo.pageCount || 0}}</span>
                <a class="button" @click="nextPage()" title="Next Page"><i class="material-icons">keyboard_arrow_right</i></a>
                <a class="button" @click="lastPage()" title="Last Page"><i class="material-icons">last_page</i></a>
            </span>
        </div>
        <div class="column is-narrow is-hidden-touch">
            <div class="field is-horizontal is-pulled-right">
                <div class="field-label is-normal nowrap mr-0">
                    Showing {{startingRowNumber || 0}} - {{endingRowNumber || 0}} of {{pageInfo.rowCount || 0}}
                </div>
            </div>
        </div>
    </div>
</template>


