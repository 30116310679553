<script setup>
    import * as yup from 'yup';
    import { ref, onMounted, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useToast } from 'vue-toastification';
    import axios from 'axios';
    import constants from '@/constants.js';

    const schema = yup.object({
        name: yup.string().nullable().required().label("Name"),
        code: yup.string().nullable().required().label("Code"),
        addressLine1: yup.string().nullable().required().label("Address"),
        city: yup.string().nullable().required().label("City"),
        stateProvince: yup.string().nullable().required().label("State / Province"),
        postalCode: yup.string().nullable().required().label("Postal Code"),
        country: yup.string().nullable().required().label("Country")
    });

    const model = ref(null);
    const modified = ref(false);
    const router = useRouter();
    const route = useRoute();

    async function onSubmit() {       
        const response = await axios.post('/api/Facility/Save', model.value);
        if (response.status !== 200) return;
        modified.value = false;
        await router.push({ name: 'Facilities' });
        useToast().success("The facility was saved successfully!");
    }

    const hasFlag = (flag) => (model.value.flags & flag) == flag;

    function setFlags(event) {
        let checkbox = event.currentTarget;
        model.value.flags += parseInt(checkbox.value) * (checkbox.checked ? 1 : -1);
    }

    onMounted(async () => {
        const response = await axios.get(`/api/Facility/Get/${route.params.id || ""}`);
        if (response.status !== 200) return;
        model.value = response.data;

        //watch for changes
        watch(model.value, () => modified.value = true, { deep: true });
    })
</script>

<template>
    <div class="container">        
        <div class="columns">
            <div class="column">
                <p class="title">
                    <i class="material-icons">factory</i>&nbsp; {{route.params.id ? "Edit" : "Add"}} Facility
                </p>
            </div>
            <div class="column">
                <router-link class="button is-pulled-right" :to="{ name : 'Facilities' }" title="Return to List">
                    <i class="material-icons md-18">list</i>
                </router-link>
            </div>
        </div>

        <Form @submit="onSubmit" :validation-schema="schema" v-if="model">
            <div class="box">
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Name</label>
                            <div class="control">
                                <Field class="input" type="text" name="name" v-model="model.name" />
                                <ErrorMessage class="errors" name="name" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Code</label>
                            <div class="control">
                                <Field class="input" type="text" name="code" v-model="model.code" />
                                <ErrorMessage class="errors" name="code" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Address</label>
                    <div class="control">
                        <Field class="input" type="text" name="addressLine1" v-model="model.addressLine1" />
                        <ErrorMessage class="errors" name="addressLine1" />
                        <Field class="input mt-1" type="text" name="addressLine2" v-model="model.addressLine2" />
                        <ErrorMessage class="errors" name="addressLine2" />
                        <Field class="input mt-1" type="text" name="addressLine3" v-model="model.addressLine3" />
                        <ErrorMessage class="errors" name="addressLine3" />
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">City</label>
                            <div class="control">
                                <Field class="input" type="text" name="city" v-model="model.city" />
                                <ErrorMessage class="errors" name="city" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">State / Province</label>
                            <div class="control">
                                <Field class="input" type="text" name="stateProvince" v-model="model.stateProvince" />
                                <ErrorMessage class="errors" name="stateProvince" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="label">Postal Code</label>
                            <div class="control">
                                <Field class="input" type="text" name="postalCode" v-model="model.postalCode" />
                                <ErrorMessage class="errors" name="postalCode" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Country</label>
                            <div class="control">
                                <Field class="input" type="text" name="country" v-model="model.country" />
                                <ErrorMessage class="errors" name="country" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Capabilities</label>
                    <div class="control">
                        <label class="checkbox mr-4">
                            <input type="checkbox" class="mr-1" :value="constants.FacilityFlags.FillStation" 
                                   v-on:click="setFlags($event)" v-bind:checked="hasFlag(constants.FacilityFlags.FillStation)" />
                            <span title="A location where shippers are prepared for customers (where preparation and return checklists are completed).">
                                <span>Fill Station</span>
                                <span class="material-icons md-18 cursor-help">help</span>
                            </span>                            
                        </label>
                        <label class="checkbox mr-4">
                            <input type="checkbox" class="mr-1" :value="constants.FacilityFlags.CentralOperations" v-on:click="setFlags($event)" v-bind:checked="hasFlag(constants.FacilityFlags.CentralOperations)" />
                            <span title="A location that receives shippers from the manufacturer, performs quality inspections, and performs vacuum maintenance.">
                                <span>Central Operations</span>
                                <span class="material-icons md-18 cursor-help">help</span>
                            </span>                            
                        </label>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div class="buttons is-pulled-right">
                        <button type="submit" class="button is-info"><i class="material-icons md-18 mr-1">save</i>Save</button>
                        <router-link class="button" :to="{ name : 'Facilities' }"><i class="material-icons md-18 mr-1">cancel</i>Cancel</router-link>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>