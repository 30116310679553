<script setup>
    import { ref, onMounted } from 'vue';
    import { useToast } from 'vue-toastification';
    import axios from 'axios';
    import CustomerServiceCenterModal from './CustomerServiceCenterModal.vue';

    const pageInfo = ref({ sortKey: 'Name', pageSize: 25 });
    const rows = ref([]);
    const confirmModal = ref(null);
    const customerServiceCenterModal = ref(null);

    async function search(parms) {
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);

        const response = await axios.post("/api/CustomerServiceCenter/PagedList", pageInfo.value);
        if (response.status !== 200) return;            
        rows.value = response.data.rows;
        pageInfo.value = response.data.pageInfo;            
    }

    function splitEmail( value ) {
        if (value == null || value.trim().length == 0) return [];
        return value.split(",").map( x=> x.trim() )
    }

    async function edit(item) {
        const result = await customerServiceCenterModal.value.open(item);
        useToast().success(`The customer service center \"${result.name}\" has been updated successfully!`);
    }

    async function add() {
        const result = await customerServiceCenterModal.value.open();                
        useToast().success(`The customer service center \"${result.name}\" has been added successfully!`);
        search();
    }

    async function deleteRow(row) {
        await confirmModal.value.open("Delete Customer Service Center?", `Are you sure you want to delete the customer service center named \"${row.name}\"?</br></br>Warning: This action is not reversible!`);

        const response = await axios.post(`/api/CustomerServiceCenter/Delete/${row.id}`)
        
        if (response.status === 200) {
            search();
            useToast().success("The customer service center was deleted successfully.");
        }
        else
            useToast().error("The customer service center could not be deleted. Most likely this is due to the fact that the record is in use?");
    }

    const hasFlag = (item, flag) => (item.flags & flag) == flag;

    onMounted(() => search());
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>
        <CustomerServiceCenterModal ref="customerServiceCenterModal"></CustomerServiceCenterModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">support_agent</i> Customer Service Centers</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <a class="button is-link" @click="add"><i class="material-icons">add</i></a>
                </div>
            </div>
        </div>

        <div class="table-container">
            <table class="table is-striped is-hoverable is-bordered is-narrow">
                <thead class="has-background-white-ter">
                    <tr>
                        <th class="is-narrow"></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="Name" v-on:sorted="search">Name</GridHeader></th>                        
                        <th><GridHeader :pageInfo="pageInfo" sort-key="Email" v-on:sorted="search">Email</GridHeader></th>
                        <th><GridHeader :pageInfo="pageInfo" sort-key="Description" v-on:sorted="search">Description</GridHeader></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in rows" v-bind:key="item.id">
                        <td>
                            <div class="nowrap">
                                <a title="Edit" @click="edit(item)"><i class="material-icons md-18">edit</i></a>                                
                                <a title="Delete" v-on:click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>
                            </div>
                        </td>
                        <td class="nowrap">{{item.name}}</td>
                        <td>
                            <ul>
                                <li v-for="email in splitEmail(item.email)">
                                    {{email}}
                                </li>
                            </ul>                            
                        </td>
                        <td>{{item.description}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
    </div>
</template>

