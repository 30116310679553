<script setup>
    import { ref, onMounted } from 'vue';
    import utils from '@/utils.js';
    import { useToast } from 'vue-toastification';
    import axios from 'axios';

    const rows = ref([]);
    const loading = ref(false);
    const showFilters = ref(false);
    const pageInfo = ref({ sortKey: 'Created', sortOrder: 'Descending', pageSize: 25 });
    const confirmModal = ref(null);

    async function search(parms) {        
        loading.value = true;

        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);

        try {
            const response = await axios.post("/api/ReturnChecklist/PagedList", pageInfo.value);
            if (response.status !== 200) return;
            rows.value = response.data.rows;
            pageInfo.value = response.data.pageInfo;
        }
        finally {
            loading.value = false;
        }
    }

    async function deleteRow(row) {
        if (row.submitted != null) {            
            useToast().warning(`This checklist has been submitted and can only be deleted by removing the shipment record in the dashboard associated with JDE order #${row.jdeOrderNumber}.`);
            return;
        }

        await confirmModal.value.open("Delete Return Checklist?", `Are you sure you want to delete this checklist created by ${row.authorFullName} on ${utils.formatDate(row.created)}?</br></br>Warning: This action is not reversible!`);            
        const response = await axios.post(`/api/ReturnChecklist/Delete/${row.id}`);
        if (response.status !== 200) return;                    
        rows.value.splice(rows.value.indexOf(row), 1);
        useToast().success("The record was deleted successfully.");
    }

    onMounted(() => search());
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons mr-1">assignment_return</i>Return Dry Ice Shipper Checklists</p>
            </div>
            <div class="column is-narrow">
                <router-link class="button" :to="{ name : 'AddReturnChecklist' }">
                    <i class="material-icons">add</i>
                </router-link>
            </div>
        </div>

        <div v-if="loading">
            <div class="heading-md">Loading data, please wait...</div>
            <progress class="progress is-large is-info" max="100">60%</progress>
        </div>
        <div v-else>
            <div class="columns">
                <div class="column">
                    <div title="Search" class="control has-icons-right">
                        <input class="input" type="text" placeholder="Search" v-on:keyup.enter="search({pageNumber:1})" v-model="pageInfo.search">
                        <span class="icon is-right"><i class="material-icons">search</i></span>
                    </div>
                </div>
                <div class="column is-narrow is-hidden-mobile">
                    <button class="button" title="Hide/Show Filters" v-bind:class="{'is-light is-info' : showFilters == true}" v-on:click="showFilters =!showFilters">
                        <i class="material-icons">filter_list</i>
                    </button>
                </div>
            </div>

            <div class="columns">
                <div class="column is-narrow" v-show="showFilters">
                    <div class="field">
                        <label class="label">State</label>
                        <div class="control is-size-7">
                            <input type="checkbox" v-model="pageInfo.showCompleted" @change="search({pageNumber:1})"> <span class="tag"> Submitted</span>
                        </div>
                        <div class="control is-size-7">
                            <input type="checkbox" v-model="pageInfo.showDrafts" @change="search({pageNumber:1})"> <span class="tag"> Draft</span>
                        </div>
                    </div>
                </div>
                <div class="column table-container">


                    <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                        <thead class="has-background-white-ter">
                            <tr>
                                <th class="is-narrow"></th>
                                <th class="nowrap"><GridHeader :pageInfo="pageInfo" sort-key="Created" v-on:sorted="search">Started On</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="Author.LastName" v-on:sorted="search">Started By</GridHeader></th>
                                <th class="nowrap"><GridHeader :pageInfo="pageInfo" sort-key="Submitted" v-on:sorted="search">Completed On</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="Submitter.LastName" v-on:sorted="search">Completed By</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="JdeOrderNumber" v-on:sorted="search">JDE Order #</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="ShipperSentryNumber" v-on:sorted="search">Shipper<br />SENTRY #</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="ProbeSentryNumber" v-on:sorted="search">Data Logger &amp;<br />Probe SENTRY #</GridHeader></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in rows" v-bind:key="item.id">
                                <td>
                                    <div class="nowrap">
                                        <router-link class="has-text-black" :to="{ name : 'EditReturnChecklist', params: { id: item.id}}" title="Edit"><i class="material-icons md-18">edit</i></router-link>
                                        <a class="has-text-black" title="Delete" v-on:click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>

                                        <span class="ml-3">
                                            <span v-if="item.submitted != null">
                                                <span class="tag is-success">Submitted</span>
                                            </span>
                                            <span v-else>
                                                <span class="tag is-info">Draft</span>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td class="is-narrow" align="right">{{utils.formatDate(item.created)}}</td>
                                <td>
                                    <span v-if="item.authorId">
                                        <router-link :to="{ name : 'EditUser', params: { id: item.authorId}}">{{item.authorFullName}}</router-link>
                                    </span>
                                </td>
                                <td class="is-narrow" align="right">
                                    <span v-if="item.submitterId != null">
                                        {{utils.formatDate(item.submitted)}}
                                    </span>
                                    <span v-else>
                                        -
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.submitterId">
                                        <router-link :to="{ name : 'EditUser', params: { id: item.submitterId}}">{{item.submitterFullName}}</router-link>
                                    </span>
                                </td>
                                <td>{{item.jdeOrderNumber}}</td>
                                <td>{{item.shipperSentryNumber}}</td>
                                <td>{{item.probeSentryNumber}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
        </div>
    </div>
</template>

