<script setup>
    import { ref, computed, watch, onMounted } from 'vue';
    import axios from 'axios';
    import * as yup from 'yup';
    import utils from '@/utils.js';
    import constants from '@/constants.js';
    import { useToast } from 'vue-toastification';
    import SublimationMeasurementModal from './SublimationMeasurementModal.vue';
    import RegistrationModal from './RegistrationModal.vue';
    import VacuumMaintenanceModal from './VacuumMaintenanceModal.vue';
    
    const schema = yup.object({
        code: yup.string().nullable().required()
    });

    const props = defineProps(["user"]);
    const loading = ref(false);
    const code = ref("");
    const measurementModal = ref(null);
    const registrationModal = ref(null);
    const vacuumModal = ref(null);
    let debounce = utils.createDebounce();
    const shipper = ref(null);

    watch(
        () => code.value,
        (newVal, oldVal) => {
            if (newVal == null || newVal.trim().length == 0) startOver();
            if (newVal != oldVal) search();
        }
    );

    async function loadShipper(q, attrs) {        
        if (q == null || q.length == 0) return;
        shipper.value = null;
        loading.value = true;
        try {
            q = formatCode(q);
            const searchParams = new URLSearchParams();
            Object.keys(attrs).forEach(key => searchParams.append("attrs", attrs[key]));
            const response = await axios.get(`/api/Shipper/Process?q=${q}&${searchParams.toString()}`);
            if (response.status == 200) shipper.value = response.data;
            if (response.status == 204) statusMessage.value = "No results found! Try again...";
        }
        finally {
            loading.value = false;
        }
    }

    const search = () => debounce(() => loadShipper(code.value, ["SerialNumber", "SentryNumber"]));

    function startOver() {
        code.value = "";
        shipper.value = null;
        document.getElementById("code").focus();
    }

    function addMeasurement() {        
        measurementModal.value.open(null, (x) => {
            useToast().success(`The sublimation measurement for shipper serial #${shipper.value.serialNumber} was added successfully.`);
            loadShipper(shipper.value.id, ["Id"]);
        });
    }

    function editMeasurement() {        
        measurementModal.value.open(activeMeasurement.value.id, (x) => {
            useToast().success(`The sublimation measurement for shipper serial #${shipper.value.serialNumber} was saved successfully.`);
            loadShipper(shipper.value.id, ["Id"]);
        });
    }

    async function register() {        
        let serial = formatCode(code.value);
        let record = await registrationModal.value.open(serial);
        useToast().success(`The shipper with serial #${record.serialNumber} was registered successfully.`);
        loadShipper(x.id, ["Id"]);
    }

    async function addVacuumMaintenance() {        
        const record = await vacuumModal.value.open(null);
        loadShipper(shipper.value.id, ["Id"]);
    }

    function formatCode(code) {
        if (typeof code == 'number') return code;

        if (code != null && code.trim().startsWith("http")) {
            let tokens = code.trim().split("/");
            if (tokens.length > 0) return tokens[tokens.length - 1];
        }

        return code;
    }

    const activeMeasurement = computed(() => {        
        if (shipper.value == null) return null;
        let matches = shipper.value.measurements.filter(x => x.secondWeight == null);
        return matches.length > 0 ? matches[0] : null;
    });

    const lastCompletedMeasurement = computed(() => {        
        if (shipper.value == null) return null;
        let matches = shipper.value.measurements.filter(x => x.sublimationRate != null);
        return matches.length > 0 ? matches[matches.length - 1] : null;
    });

    const shipperModel = computed(() => shipper.value?.model );

    const isRegistered = computed(() => {
        if (shipper.value == null) return false;
        return (shipper.value.state & constants.ShipperState.Registered) == constants.ShipperState.Registered;
    });

    const needsWarrantyReplacement = computed(() => {
        if (shipper.value == null) return false;
        return (shipper.value.state & constants.ShipperState.WarrantyReplacement) == constants.ShipperState.WarrantyReplacement;
    });

    const needsVacuumMaintenance = computed(() => {
        if (shipper.value == null) return false;
        return ((shipper.value.state & constants.ShipperState.LindeVacuumMaintenance) == constants.ShipperState.LindeVacuumMaintenance)
            || ((shipper.value.state & constants.ShipperState.WarrantyVacuumMaintenance) == constants.ShipperState.WarrantyVacuumMaintenance);
    });

    const hadVacuumMaintenance = computed(() => {
        if (shipper.value == null) return false;
        return shipper.value.lastVacuumMaintenance != null;
    });

    onMounted(() => startOver());    
</script>

<template>
    <div>
        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons">auto_fix_high</i> Process Shipper</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <router-link class="button" :to="{ name : 'Shippers'}">
                        <span class="material-icons md-18">list</span>
                    </router-link>
                </div>
            </div>
        </div>

        <SublimationMeasurementModal ref="measurementModal" :shipperModel="shipperModel" :shipper="shipper" :user="user"></SublimationMeasurementModal>
        <RegistrationModal ref="registrationModal"></RegistrationModal>
        <VacuumMaintenanceModal ref="vacuumModal" :shipper="shipper" :user="user"></VacuumMaintenanceModal>

        <Form :validation-schema="schema" @keydown.enter="$event.preventDefault()" @keydown.escape="startOver()">

            <p class="mb-4">Scan shipper QR code or Sentry barcode (after registration) to initiate the next step in the quality inspection and vacuum maintenance process.</p>

            <div class="field has-addons">
                <div class="control has-icons-right" title="scan barcode">
                    <Field class="input" type="text" name="code" v-model="code" @keyup.enter="search" id="code" />
                    
                    <span class="icon is-small is-right">
                        <span class="material-icons md-18">barcode_reader</span>
                    </span>
                </div>
                <p class="control">
                    <button class="button is-primary" type="button" @click="find">
                        <span class="material-icons">search</span> Search
                    </button>
                </p>
            </div>

            <div v-show="loading == true">
                <p class="heading-lg">Searching inventory, please wait....</p>
                <progress class="progress is-large is-info" max="100">60%</progress>
            </div>

            <div v-if="shipper != null">
                <hr />
                <div class="table-container">
                    <table class="box table is-hoverable is-narrow is-fullwidth">
                        <thead>
                            <tr>
                                <th class="is-narrow">Status</th>
                                <th class="is-narrow">Category</th>
                                <th>Explanation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span class="material-icons has-text-success" v-if="isRegistered == true">check_circle</span>
                                    <span class="material-icons has-text-danger-dark" v-else>error</span>
                                </td>
                                <td class="nowrap">General</td>
                                <td>
                                    <div v-if="shipper && shipper.model">
                                        The shipper model is <router-link class="action-link" target="_blank" :to="{ name : 'EditShipperModel', params: { id: shipper.model.id }}">{{shipper.model.name}}</router-link>
                                    </div>

                                    <div v-if="needsWarrantyReplacement == true">
                                        <p>
                                            The shipper with the serial #
                                            <router-link class="action-link" :to="{ name : 'EditShipper', params: { id: shipper.id }}">{{shipper.serialNumber}}</router-link>
                                            has been marked as <span v-html="utils.formatShipperState(shipper.state)" style="display:inline-block"></span>.
                                        </p>
                                        <p class="is-italic mt-2">
                                            Inspect shipper vessel interior and exterior, shipper cap, and product canister/rack for damage, defects, or poor workmanship.
                                            If this shipper has been repaired and its physical condition is acceptable for transfer to end-user, go to the
                                            <router-link class="action-link" :to="{ name : 'EditShipper', params: { id: shipper.id}}">Manage Shipper page</router-link>
                                            and click the &quot;Reset Shipper&quot; button.
                                        </p>

                                        <div v-if="shipper.conditionObservations" class="mt-2">
                                            <label class="label">Condition Observations:</label> {{shipper.conditionObservations}}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="isRegistered == true">
                                            <div>
                                                <div>
                                                    The shipper with the serial #
                                                    <router-link class="action-link" target="_blank" :to="{ name : 'EditShipper', params: { id: shipper.id }}">{{shipper.serialNumber}}</router-link>
                                                    was registered on {{utils.formatDate(shipper.created)}}.
                                                </div>
                                            </div>
                                            <div class="columns">
                                                <div class="column is-narrow">Current state:</div>
                                                <div class="column" v-html="utils.formatShipperState(shipper.state)"></div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div>No shipper has been registered with the serial #{{formatCode(code)}}.</div>

                                            <a class="action-link" @click="register" v-if="shipper.manufacturing != null">
                                                Register Now!
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span class="material-icons has-text-danger-dark" v-if="shipper.manufacturing == null">error</span>
                                    <span class="material-icons has-text-success" v-else>check_circle</span>
                                </td>
                                <td class="nowrap">Manufacturing</td>
                                <td>
                                    <div v-if="shipper.manufacturing != null">
                                        The shipper was received on {{utils.formatDate(shipper.manufacturing.received)}} from {{shipper.manufacturing.manufacturer}}.
                                    </div>
                                    <div v-else>
                                        <div>The manufacturing data is missing! It must be entered prior to registration.</div>
                                        <div>
                                            <router-link class="action-link" :to="{ name : 'Manufacturing'}" target="_blank">
                                                Manage Manufacturing Data
                                            </router-link>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="shipper.id != null">
                                <td>
                                    <span class="material-icons has-text-success" v-if="shipper.isInSentry == true">check_circle</span>
                                    <span class="material-icons has-text-danger-dark" v-else>error</span>
                                </td>
                                <td class="nowrap">SENTRY</td>
                                <td>
                                    <div v-if="shipper.isInSentry == true">The shipper was uploaded to SENTRY and assigned #{{shipper.sentryNumber}}.</div>
                                    <div v-else>The shipper has NOT been uploaded to SENTRY.</div>
                                </td>
                            </tr>

                            <tr v-if="isRegistered == true">
                                <td>
                                    <span class="material-icons has-text-warning-dark" v-if="shipper.id != null && shipper.measurements.length == 0">warning</span>
                                    <span class="material-icons has-text-info-dark" v-else>info</span>
                                </td>
                                <td class="nowrap">Sublimation Testing</td>
                                <td>
                                    <div v-if="shipper.measurements.length == 0">
                                        <div>No measurements have been submitted yet.</div>
                                        <div>
                                            <a class="action-link" @click="addMeasurement()">
                                                Add initial measurement.
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="activeMeasurement != null">
                                            <a class="action-link" @click="editMeasurement()">
                                                Continue measurement started on {{utils.formatDateTime(activeMeasurement.firstWeightTaken)}}
                                            </a>
                                        </div>
                                        <div v-else>
                                            <a class="action-link" @click="addMeasurement()">
                                                Add {{(shipper.measurements.length == 0 ? "Initial" : "")}} sublimation measurement
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="needsVacuumMaintenance == true || hadVacuumMaintenance == true">
                                <td>
                                    <span class="material-icons has-text-warning-dark" v-if="needsVacuumMaintenance">warning</span>
                                    <span class="material-icons has-text-info-dark" v-else>info</span>
                                </td>
                                <td class="nowrap">Vacuum Maintenance</td>
                                <td>
                                    <div v-if="hadVacuumMaintenance == true">
                                        The last vacuum maintenance was performed on {{utils.formatDate(shipper?.lastVacuumMaintenance?.performed)}}.
                                    </div>

                                    <div v-if="needsVacuumMaintenance == true">
                                        <div>The shipper requires vacuum maintenance. </div>
                                        <div>
                                            <a class="action-link" @click="addVacuumMaintenance()">
                                                Record vacuum maintenance date.
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="buttons mt-5">
                    <button class="button is-primary" type="button" @click="startOver()">
                        <span class="material-icons">search</span> Find a different shipper
                    </button>
                </div>
            </div>
        </Form>
    </div>
</template>

<style scoped>
    .action-link {
        font-weight: 600;
        color: #005591;
    }

    .action-link:hover {
        text-decoration: underline;
    }
</style>