<script setup>
    import { ref, computed } from 'vue';
    import axios from 'axios';
    import utils from '@/utils.js';
    import constants from '@/constants.js';

    const props = defineProps(["id", "summary"]);

    const rows = ref([]);
    const selectedRows = ref([]);
    const pagingInfo = ref({ pageSize: 25, sortKey: "SerialNumber", filters: {} });
    const root = ref(null);    
    const checkAll = ref(null);
    let resolver = null;

    async function search(parms) {
        if (parms) pagingInfo.value = Object.assign(pagingInfo.value, parms);
        const response = await axios.post("/api/PackingList/ShippersPagedList", pagingInfo.value);
        if (response.status !== 200) return;
        rows.value = response.data.rows;
        pagingInfo.value = response.data.pagingInfo;
    }

    const clearFilters = () => pagingInfo.value.filters = { showUsed: true, sentryNumber: '', serialNumber: '', packingListId: null };
    
    function toggleUsed() {
        pagingInfo.value.filters.showUsed = !pagingInfo.value.filters.showUsed;
        search();
    }

    function onSubmit() {
        toggleDisplay(false);        
        resolver(selectedRows.value);
    }    

    function open() {     
        pagingInfo.value.packingListId = props.id;

        clearFilters();
        checkAll.value.checked = false;
        selectedRows.value = [];

        return new Promise((resolve) => {
            resolver = resolve;
            toggleDisplay(true);
            search();
        })
    }

    const toggleDisplay = (value) => root.value.style.display = value ? "block" : "none";

    const toggleSelection = (event, item) => toggleRow(item, event.target.checked);

    function toggleAll(event) {
        selectedRows.value = [];
        let checked = event.target.checked;
        rows.value.forEach(x => toggleRow(x, checked));
    }

    function toggleRow(row, checked) {        
        if (checked) {
            selectedRows.value.push(row);
        }
        else {
            let matches = selectedRows.value.filter(x => x.shipperId == row.shipperId)
            matches.forEach(x => selectedRows.value.splice(selectedRows.value.indexOf(x), 1));
        }
    }

    const isAvailable = (item) => item.packingListId == null || (props.id == item.packingListId);
    const addedToParent = (item) => props.summary.filter(x => x.shipperId == item.shipperId).length > 0;
    const isSelected = (item) => selectedRows.value.filter(x => x.shipperId == item.shipperId).length > 0;
    const shipperStates = computed(() => Object.entries(constants.ShipperState).map(([key, val]) => { return { name: (val == 0 ? "" : key).split(/(?=[A-Z])/).join(" "), value: val }; }));

    defineExpose({
        open
    })
</script>

<template>
    <div class="modal" ref="root">
        <Form @submit="onSubmit" @keydown.enter="$event.preventDefault()" @keydown.escape="toggleDisplay(false)">
            <div class="modal-background"></div>
            <div class="modal-card mt-2">
                <header class="modal-card-head">
                    <p class="modal-card-title"><span class="material-icons mr-1">search</span>Add Shipper(s)</p>
                    <button type="button" class="delete" aria-label="close" v-on:click="toggleDisplay(false)" title="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <label class="label">
                                    <input type="checkbox" v-model="pagingInfo.filters.showUsed" @click="toggleUsed" />
                                    Show shippers on other lists?
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="table-container">
                        <table class="table is-striped is-hoverable is-bordered is-narrow">
                            <thead class="has-background-white-ter">
                                <tr>
                                    <th class="is-narrow">
                                        <input type="checkbox" v-on:click="toggleAll($event)" ref="checkAll" />
                                    </th>
                                    <th>
                                        <GridHeader :pageInfo="pagingInfo" sort-key="Status" v-on:sorted="search">Status</GridHeader>
                                        <div class="select control is-fullwidth is-small">
                                            <select v-model="pagingInfo.filters.status" @change="search()">
                                                <option v-for="(option,idx) in shipperStates" v-bind:key="idx" v-bind:value="option.value">
                                                    {{option.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </th>
                                    <th>
                                        <GridHeader :pageInfo="pagingInfo" sort-key="SerialNumber" v-on:sorted="search">Serial #</GridHeader>
                                        <div class="control">
                                            <input class="input is-small" type="text" v-model="pagingInfo.filters.serialNumber"
                                                   v-on:keyup.enter="search()"
                                                   title="Search by Serial #">
                                        </div>
                                    </th>
                                    <th>
                                        <GridHeader :pageInfo="pagingInfo" sort-key="SentryNumber" v-on:sorted="search">SENTRY #</GridHeader>
                                        <div class="control">
                                            <input class="input is-small" type="text" v-model="pagingInfo.filters.sentryNumber"
                                                   v-on:keyup.enter="search()"
                                                   title="Search by Sentry #">
                                        </div>
                                    </th>
                                    <th>
                                        <GridHeader :pageInfo="pagingInfo" sort-key="ShipperModelName" v-on:sorted="search">Model</GridHeader>
                                        <div class="control">
                                            <input class="input is-small" type="text" v-model="pagingInfo.filters.model"
                                                   v-on:keyup.enter="search()"
                                                   title="Search by Model">
                                        </div>
                                    </th>
                                    <th>
                                        <GridHeader :pageInfo="pagingInfo" sort-key="PackingListId" v-on:sorted="search">Packing List</GridHeader>
                                        <div class="control">
                                            <input class="input is-small" type="text" v-model.number="pagingInfo.filters.packingListId"
                                                   v-on:keyup.enter="search()">
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="rows">
                                <tr v-for="item in rows" v-bind:key="item.id" v-bind:class="{'has-background-grey-lighter has-text-grey': !isAvailable(item)}">
                                    <td>
                                        <span v-if="isAvailable(item)">
                                            <span v-if="addedToParent(item) == true">
                                                <span class="material-icons md-18 has-text-success" title="This item is already included in the packing list.">check</span>
                                            </span>
                                            <span v-else>
                                                <input type="checkbox" v-on:click="toggleSelection($event,item)" v-bind:checked="isSelected(item)" />
                                            </span>
                                        </span>
                                        <span v-else>
                                            <span class="material-icons md-18" title="This item is already included in another packing list.">block</span>
                                        </span>
                                    </td>
                                    <td><div v-html="utils.formatShipperState(item.status)"></div></td>
                                    <td class="nowrap">{{item.serialNumber}}</td>
                                    <td class="nowrap">{{item.sentryNumber}}</td>
                                    <td>{{item.shipperModelName}}</td>
                                    <td>
                                        <span v-if="addedToParent(item) == true && item.packingListName == null" class="has-text-warning-dark">
                                            {{id}}
                                        </span>
                                        <span v-else>
                                            {{item.packingListId}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <GridPager :pageInfo="pagingInfo" v-on:paged="search"></GridPager>
                </section>
                <footer class="modal-card-foot" style="display:table">
                    <div class="columns">
                        <div class="column is-narrow">
                            {{selectedRows.length}} item(s) selected
                        </div>
                        <div class="column">
                            <div class="buttons is-pulled-right">
                                <button type="submit" class="button is-success">OK</button>
                                <button type="button" class="button" @click="toggleDisplay(false)">Cancel</button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </Form>
    </div>
</template>



<style scoped>
    .modal-card {
        width: 80%;
    }
</style>