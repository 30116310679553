<script setup>
    import { computed, onMounted } from 'vue';
    import { useUserStore } from '@/stores/user' 
    import constants from '@/constants.js';
    import { useRouter } from 'vue-router';
    import { useToast } from 'vue-toastification';

    const store = useUserStore();
    const props = defineProps(["user", "impersonatedUser"]);
    const router = useRouter();

    async function logOut() {
        await store.logOut();
        router.push({ name: 'Home' });
        useToast().success("You have been logged out of the system.");      
    }

    async function exitImpersonation() {
        await store.exitImpersonation();
        await router.push({ name: 'Home' });
        useToast().success("Impersonation has now ended. You have been returned to your active session.");      
    }

    function setupMenu() {
        var burger = document.querySelector(".navbar-burger");
        var menu = document.querySelector(".navbar-menu");

        //toggle the entire menu
        burger.addEventListener("click", () => {
            burger.classList.toggle('is-active');
            var menus = Array.prototype.slice.call(document.querySelectorAll('.navbar-menu'), 0);
            menus.forEach(el => el.classList.toggle('is-active'));
        });

        //toggle the sub-menus when you click on expander icon
        var menuLinks = Array.prototype.slice.call(document.querySelectorAll('.navbar-link'), 0);
        menuLinks.forEach(el => {
            el.addEventListener("click", () => {
                var children = Array.prototype.slice.call(el.parentElement.querySelectorAll('.navbar-dropdown'), 0);
                children.forEach(child => child.classList.toggle("is-hidden"));
            });
        });

        //hide the menu when you click on a link
        var dropdowns = Array.prototype.slice.call(document.querySelectorAll('.navbar-dropdown'), 0);
        dropdowns.forEach(el => el.classList.toggle('is-hidden'));   //start the menu with the submenus collapsed

        var topLevels = Array.prototype.slice.call(document.querySelectorAll('a.navbar-item'), 0);
        topLevels.forEach(link => {
            link.addEventListener("click", () => {
                burger.classList.remove("is-active");
                menu.classList.remove("is-active");
            });
        });
    }

    const activeUser = computed(() => props.impersonatedUser || props.user || null);
    const alertCount = computed(() => store.alertCount);


    onMounted(() => {
        setupMenu();
    });
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <nav class="navbar is-link" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <img src="/img/logo.png" style="width:104px; height: 32px; margin: 6px;">

                <router-link class="navbar-item" :to="{ name : 'Home' }"><span class="material-icons">home</span></router-link>

                <router-link v-if="!user" class="navbar-item" :to="{ name : 'Login' }">Login</router-link>

                <a role="button" class="navbar-burger" data-target="navMenu" aria-label="menu" aria-expanded="false" v-show="user">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div class="navbar-menu">
                <div class="navbar-start">
                    <router-link v-show="user" class="navbar-item" :to="{ name : 'Dashboard' }">Dashboard</router-link>
                    <router-link v-show="user" class="navbar-item" :to="{ name : 'Orders' }">Orders</router-link>

                    <div class="navbar-item has-dropdown is-hoverable" v-show="user && user.userType == constants.UserType.Employee">
                        <a class="navbar-link">
                            Employee
                        </a>

                        <div class="navbar-dropdown ml-3">
                            <span class="m-3 has-text-info nowrap">Shippers</span>
                            <router-link class="navbar-item" :to="{ name : 'Shippers' }"><span class="material-icons is-size-6 mr-1">propane_tank</span>Shippers</router-link>
                            <router-link class="navbar-item" :to="{ name : 'Manufacturing' }"><span class="material-icons is-size-6 mr-1">receipt_long</span>Shipper Manufacturing Data</router-link>
                            <router-link class="navbar-item" :to="{ name : 'PackingLists' }"><span class="material-icons is-size-6 mr-1">pallet</span>Packing Lists</router-link>
                            <span class="m-3 has-text-info nowrap">Checklists</span>
                            <router-link class="navbar-item" :to="{ name : 'PreparationChecklists' }"><span class="material-icons is-size-6 mr-1">local_shipping</span>Dry Ice Shipper Package Preparation</router-link>
                            <router-link class="navbar-item" :to="{ name : 'ReturnChecklists' }"><span class="material-icons is-size-6 mr-1">assignment_return</span>Return Dry Ice Shipper</router-link>
                            <span class="m-3 has-text-info nowrap">Miscellaneous</span>
                            <router-link class="navbar-item" :to="{ name : 'DemoMode' }"><span class="material-icons is-size-6 mr-1">settings</span>Demo Mode</router-link>
                        </div>
                    </div>

                    <div class="navbar-item has-dropdown is-hoverable" v-show="user && user.securityRole == constants.SecurityRole.Admin">
                        <a class="navbar-link">Admin</a>

                        <div class="navbar-dropdown ml-3">
                            <span class="m-3 has-text-info nowrap">Data Management</span>
                            <router-link class="navbar-item" :to="{ name : 'Customers' }"><span class="material-icons is-size-6 mr-1">business</span>Customers</router-link>
                            <router-link class="navbar-item" :to="{ name : 'CustomerServiceCenters' }"><span class="material-icons is-size-6 mr-1">support_agent</span>Customer Service Centers</router-link>
                            <router-link class="navbar-item" :to="{ name : 'Facilities' }"><span class="material-icons is-size-6 mr-1">factory</span>Facilities</router-link>
                            <router-link class="navbar-item" :to="{ name : 'ShipperModels' }"><span class="material-icons is-size-6 mr-1">category</span>Shipper Models</router-link>
                            <router-link class="navbar-item" :to="{ name : 'Users' }"><span class="material-icons is-size-6 mr-1">group</span>Users</router-link>
                            <span class="m-3 has-text-info nowrap">System Diagnostics</span>
                            <router-link class="navbar-item" :to="{ name : 'TelemetryLogs' }"><span class="material-icons is-size-6 mr-1">query_stats</span>Telemetry Log</router-link>
                            <router-link class="navbar-item" :to="{ name : 'TaskLogs' }"><span class="material-icons is-size-6 mr-1">query_stats</span>Task Log</router-link>
                            <span class="m-3 has-text-info nowrap">Utilities</span>
                            <router-link class="navbar-item" :to="{ name : 'Track' }"><span class="material-icons is-size-6 mr-1">travel_explore</span>Track Package</router-link>
                            <router-link class="navbar-item" :to="{ name : 'UnitConversion' }"><span class="material-icons is-size-6 mr-1">transform</span>Unit Conversion</router-link>
                        </div>
                    </div>
                </div>

                <div class="navbar-end">
                    <router-link class="navbar-item" :to="{ name : 'Alerts' }" v-bind:class="{'has-text-warning': alertCount > 0}" v-show="alertCount > 0">
                        <span class="is-size-7">{{alertCount}}</span>
                        <i class="material-icons">notifications{{(alertCount > 0 ? "_active" : "")}}</i>
                    </router-link>

                    <div class="navbar-item has-dropdown is-hoverable" v-if="activeUser != null">
                        <a class="navbar-link">
                            <i class="material-icons mr-1">account_circle</i>
                            <div v-if="impersonatedUser == null">{{user.fullName}}</div>
                            <div class="is-size-6 ml-1" v-else>
                                <div class="has-text-warning">{{impersonatedUser.fullName}}</div>
                                <div class="is-size-7">({{user.fullName}})</div>
                            </div>
                        </a>

                        <div class="navbar-dropdown ml-3">
                            <div class="navbar-item">
                                <span class="label is-italic" title="Security role">
                                    <span class="material-icons md-18 mr-1">security</span>
                                    <span v-if="activeUser.securityRole === constants.SecurityRole.Admin">Admin</span>
                                    <span v-if="activeUser.securityRole === constants.SecurityRole.User">User</span>
                                </span>
                            </div>
                            <router-link class="navbar-item" :to="{name: 'EditUser', params: { id: activeUser.id }}">
                                <span class="material-icons md-18 mr-1">edit</span> Edit Profile
                            </router-link>
                            <hr class="navbar-divider" />                            
                            <a class="navbar-item" v-on:click="exitImpersonation" v-if="impersonatedUser">
                                <span class="material-icons md-18 mr-1 has-text-danger">logout</span> Exit Impersonation
                            </a>

                            <a class="navbar-item" v-on:click="logOut"><span class="material-icons md-18 mr-1">logout</span> Log Out</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>