<script setup>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import * as yup from 'yup';
    import { useToast } from 'vue-toastification';
    import { useUserStore } from '@/stores/user';

    const props = defineProps(["user"]);

    const schema = yup.object({
        email: yup.string().typeError("required").required("required"),
        password: yup.string().typeError("required").required("required")
    });

    const store = useUserStore();
    const router = useRouter();
    const email = ref(null);
    const password = ref(null);
        
    async function login(useSso) {            
        try {
            if (useSso)
                await store.singleSignOn()
            else
                await store.formsAuthenticate(email.value, password.value);
            
            goToDashboard();
        }
        catch (error) {            
            useToast().error(error.message);
        }
    }

    const goToDashboard = () => router.push({ name: 'Dashboard' });

    onMounted(() => {
        if (props.user != null) goToDashboard();
    });
</script>

<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <h1 class="title">
                    SECCURUS<sup>&reg;</sup>
                </h1>
                <h2 class="subtitle"> 
                    Dry Ice Shipping & Storage System
                </h2>
                <img src="/img/product_shippers.png" title="Cryo Saver">
            </div>
            <div class="column is-4">
                <div class="box mb-1">
                    <Form @submit="login(false)" :validation-schema="schema">
                        <div class="field">
                            <label class="label">Email</label>
                            <div class="control">
                                <Field class="input" type="text" name="email" autocomplete="username" placeholder="Email" v-model="email" />
                                <ErrorMessage class="errors" name="email" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Password</label>
                            <div class="control">
                                <Field class="input" type="password" name="password" autocomplete="current-password" placeholder="Password" v-model="password" />
                                <ErrorMessage class="errors" name="password" />
                            </div>
                        </div>
                        <div class="field">
                            <p class="control">
                                <button class="button is-success">
                                    Log In
                                </button>
                            </p>
                        </div>

                        <router-link :to="{name: 'ForgotPassword'}">Forgot your password?</router-link>
                    </Form>

                    <div class="columns mt-2 is-marginless">
                        <div class="column"><div class="navbar-divider"></div></div>
                        <div class="column is-narrow"><span class="has-text-grey is-size-6">OR</span></div>
                        <div class="column"><div class="navbar-divider"></div></div>
                    </div>

                    <p>For Linde employees, log in using your domain credentials</p>


                    <button class="button is-info is-white mt-5" v-on:click="login(true)">
                        Single Sign-On &rarr;
                    </button>
                </div>

                <div class="box">
                    Don't have an account? <router-link :to="{name: 'SignUp'}" class="has-text-weight-bold">Sign Up</router-link>
                </div>
            </div>
        </div>
    </div>
</template>